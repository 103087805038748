export default [
	{
		path: 'cjobs',
		name: 'commonJobs',
		redirect: { name: 'commonBrowseJobs' },
		meta: {
			title: 'common.jobs',
			description: '',
		},
		// component: () => import('@views/app/common/FirstCard')
		component: {
            template: '<router-view></router-view>'
		},
		children: [
			{
				path: 'browse-jobs',
				name: 'commonBrowseJobs',
				meta: [],
				component: () => import('@views/app/common/jobs/browsejobs/index')
			},
			{
				path: 'browse-myjobs',
				name: 'commonBrowseMyJobs',
				meta: [],
				component: () => import('@views/app/common/jobs/myjobs/index')
			},
			{
				path: 'browse-job/:id',
				name: 'commonBrowseSingleJobs',
				meta: [],
				component: () => import('@views/app/common/jobs/browsejobs/jobDetails')
			},
			{
				path: 'edit-job/:id',
				name: 'commonEditSingleJobs',
				meta: [],
				component: () => import('@views/app/common/jobs/createjobs/index')
			},
			{
				path: 'create-job',
				name: 'commonCreateJob',
				meta: [],
				component: () => import('@views/app/common/jobs/createjobs/index')
			},
			{
				path: 'job-categories',
				name: 'commonJobCategories',
				meta: [],
				component: () => import('@views/app/common/jobs/createjobs/category/index')
			},
			{
				path: 'browse-contracts',
				name: 'commonBrowseContracts',
				meta: [],
				component: () => import('@views/app/common/jobs/browsecontacts/index')
			},
			{
				path: 'browse-skills',
				name: 'commonBrowseSkills',
				meta: [],
				component: () => import('@views/app/common/jobs/browseskills/index')
			},
			{
				path: 'freelance-jobs',
				name: 'commonFreelanceJobs',
				meta: [],
				component: () => import('@views/app/common/jobs/frelancejobs/index')
			},
			{
				path: 'skilled-jobs',
				name: 'commonSkilledJobs',
				meta: [],
				component: () => import('@views/app/common/jobs/skilledjobs/index')
			},
			{
				path: 'instant-requirements',
				name: 'commonInstantRequirements',
				meta: [],
				component: () => import('@views/app/common/jobs/instantrequirements/index')
			},
			{
				path: 'service-requests',
				name: 'commonServiceRequests',
				meta: [],
				component: () => import('@views/app/common/jobs/servicerequests/index')
			},
			{
				path: 'resumes',
				name: 'commonResumes',
				meta: [],
				component: () => import('@views/app/common/jobs/resumes/index')
			},
			{
				path: 'offer-letters',
				name: 'commonOfferLetters',
				meta: [],
				component: () => import('@views/app/common/jobs/offerletters/index')
			},
			{
				path: 'applied-jobs',
				name: 'commonAppliedJobs',
				meta: [],
				component: () => import('@views/app/common/jobs/appliedjobs/index')
			},
			{
				path: 'job-preparation',
				name: 'commonJobPreparation',
				meta: [],
				component: () => import('@views/app/common/jobs/jobpreparation/index')
			},
		]

	},
]