import axios from "axios";
import Swal from "sweetalert2";
import { Toast } from "../../mixins/swal";
import { getToken } from "@core/utils/auth";
const token = getToken();
import Eventbus from "../../event-bus";

const webcard = {
  namespaced: true,
  state: {
    mytotal: null,
    microsite: {},
    my_all_webcard: [],
    my_active_webcards: [],
    my_pending_webcards: [],
    my_blocked_webcards: [],
    all_webcards_micro: [],
    all_public_webcards: [],
    all_microsite_webcards: [],
    webcard_promotion_importent: [],
    webcard_promotion_exclusive: [],
    webcard_promotion_head: [],
    webcard_promotion_top: [],
    webcard_promotion_middle: [],
    webcard_packages: [],
    single_webcard_packages: {},
    membership_webcard: [],
    membershiporg_webcard: [],
    webcard_orders: [],
    webcard_orders_current_page: 1
  },
  getters: {
    getmytotal: (state) => state.mytotal,
    getprofile: (state) => state.profile,
    getmyallwebcards: (state) => state.my_all_webcard,
    getmyactivewebcards: (state) => state.my_active_webcards,
    getmypendingwebcards: (state) => state.my_pending_webcards,
    getmyblockedgwebcards: (state) => state.my_blocked_webcards,
    getmymicrositewebcards: (state) => state.all_microsite_webcards,
    promotion_exclusive: (state) => state.webcard_promotion_exclusive,
    promotion_importent: (state) => state.webcard_promotion_importent,
    promotion_head: (state) => state.webcard_promotion_head,
    promotion_top: (state) => state.webcard_promotion_top,
    promotion_middle: (state) => state.webcard_promotion_middle,
    get_webcard_packages: (state) =>
      state.webcard_packages ? state.webcard_packages : [],
    get_single_webcard_package: (state) => state.single_webcard_packages,
    get_membership_webcard: state => state.membership_webcard,
    get_membershiporg__webcard: state => state.membershiporg_webcard,
    getwebcardorders: state => state.webcard_orders,
    getwebcardorderscurrentpage: state => state.webcard_orders_current_page,
  },
  mutations: {
    SET_MY_NUMBER_OF_WEBCARDS: (state, data = {}) => {
      state.mytotal = data;
    },
    SET_MY_WEBCARD_LIBRARY: (state, data = {}) => {
      state.my_all_webcard = data;
    },
    SET_MY_ACTIVE_WEBCARD_LIBRARY: (state, data = {}) => {
      state.my_active_webcards = data;
    },
    SET_MY_PENDING_WEBCARD_LIBRARY: (state, data = {}) => {
      state.my_pending_webcards = data;
    },
    SET_MY_BLOCKED_WEBCARD_LIBRARY: (state, data = {}) => {
      state.my_blocked_webcards = data;
    },
    SET_PUBLIC_WEBCARD_MIC: (state, data = {}) => {
      state.all_webcards_micro = data;
    },
    SET_ALL_PUBLIC_WEBCARD: (state, data = {}) => {
      state.all_public_webcards = data;
    },
    SET_MY_MICROSITE_WEBCARD_LIBRARY: (state, data = {}) => {
      state.all_microsite_webcards = data;
    },
    SET_WEBCARD_PROMOTION_IMPORTENT: (state, data = {}) => {
      state.webcard_promotion_importent = data;
    },
    SET_WEBCARD_PROMOTION_EXCLUSIVE: (state, data = {}) => {
      state.webcard_promotion_exclusive = data;
    },

    SET_WEBCARD_PROMOTION_HEAD: (state, data = {}) => {
      state.webcard_promotion_head = data;
    },
    SET_WEBCARD_PROMOTION_TOP: (state, data = {}) => {
      state.webcard_promotion_top = data;
    },
    SET_WEBCARD_PROMOTION_MIDDLE: (state, data = {}) => {
      state.webcard_promotion_middle = data;
    },
    // webcard packages
    SET_WEBCARD_PACKAGES: (state, data = {}) => {
      state.webcard_packages = data;
    },
    SET_SINGLE_PACKAGE: (state, data = {}) => {
      state.single_webcard_packages = data;
    },
    SET_MEMBERSHIP_WEBCARD: (state, data = {}) => {
      state.membership_webcard = data;
    },
    SET_MEMBERSHIP_ORIG_WEBCARD: (state, data = {}) => {
      state.membershiporg_webcard = data;
    },
    // webcard packages end
    SET_WEBCARD_ORDERS: (state, data = {}) => {
      state.webcard_orders.push(...data)
    },
    SET_WEBCARD_ORDERS_PAGE: (state, data = {}) => {
      state.webcard_orders_current_page = data
    },
  },
  actions: {
    async getwebcardorders({commit}, {country_code, type, keyword, current_page}) {
      let reqOptions = {
        url: "/api/admin/webcardorders?country_code="+country_code+"&type="+type+"&current_page="+current_page+"&keyword="+keyword,
        method: "GET",
       
      }
      try {
        let res = await axios.request(reqOptions);
        commit("SET_WEBCARD_ORDERS", res.data.data)
        commit("SET_WEBCARD_ORDERS_PAGE", res.data.current_page)
        Eventbus.$emit("order-arrieved")
      } catch (error) {
       Eventbus.$emit("order-arrieved")
      }
   },
    async get_microsite_webcard({commit}) {
      var config = {
        method: "get",
        // url: "/api/mywebcardonmicrosites",
        url: "/api/mywebcardonmicrosites?user_type=employee&status=1",
      };
      try {
        const res = await axios(config);
        // dispatch("get_webcard_packages", { country_code, type, keyword });
        commit("SET_MEMBERSHIP_WEBCARD", res.data)
        Eventbus.$emit("membership-membership-arrieved");
      } catch (error) {
        console.log(error)
        Eventbus.$emit("membership-membership-arrieved");
      }
    },
    async get_microsite_membership_webcard({commit},{keyword = ''}) {
      Eventbus.$emit("loadin")
      var config = {
        method: "get",
        // url: "/api/mywebcardonmicrosites",
        url: "/api/mywebcardonmicrosites?user_type=memberships&status=1&keyword="+keyword,
      };
      try {
        
        const res = await axios(config);
        // dispatch("get_webcard_packages", { country_code, type, keyword });
        commit("SET_MEMBERSHIP_ORIG_WEBCARD", res.data)
        Eventbus.$emit("membership-webcardorg-arrieved");
      } catch (error) {
        console.log(error)
        Eventbus.$emit("membership-webcardorg-arrieved");
      }
    },
    async update_webcard(
      { commit },
      { data, uuid, country_code, type, keyword }
    ) {
      var config = {
        method: "post",
        url: "/api/admin/webcardtype/" + uuid,
       
        data,
      };
      try {
        await axios(config);
        // console.log("success");

        dispatch("get_webcard_packages", { country_code, type, keyword });
        Eventbus.$emit("updated-package");
      } catch (error) {
        Eventbus.$emit("updated-package");
      }
    },
    async get_single_package({ commit }, uuid) {
      var config = {
        method: "get",
        url: "/api/admin/webcardtype/" + uuid,
      
      };
      try {
        const res = await axios(config);
        commit("SET_SINGLE_PACKAGE", res.data);
        Eventbus.$emit("package-single");
      } catch (error) {
        Eventbus.$emit("package-single");
      }
    },
    async delete_webcard_package(
      { commit, dispatch },
      { uuid, country_code, type, keyword }
    ) {
      var config = {
        method: "delete",
        url: "/api/admin/webcardtype/" + uuid,
       
      };
      try {
        await axios(config);
        dispatch("get_webcard_packages", { country_code, type, keyword });
        Eventbus.$emit("deleted-package");
      } catch (error) {
        Eventbus.$emit("deleted-package");
      }
    },
    async create_webcard(
      { commit, dispatch },
      { data, country_code, type, keyword }
    ) {
      var config = {
        method: "post",
        url: "/api/admin/webcardtype",
      
        data,
      };
      try {
        await axios(config);
        Eventbus.$emit("packages-created");
        dispatch("get_webcard_packages", { country_code, type, keyword });
      } catch (error) {
        Eventbus.$emit("packages-created");
      }
    },

    async get_webcard_packages({ commit }, { country_code, type, keyword }) {
      var config = {
        method: "get",
        url:
          "/api/admin/webcardtype?country_code" +
          country_code +
          "=&type" +
          type +
          "=&keyword=" +
          keyword,
       
      };
      try {
        const res = await axios(config);
        // console.log(res.data.data);
        commit("SET_WEBCARD_PACKAGES", res.data.data);
        Eventbus.$emit("packages-arraieved");
      } catch (error) {
        Eventbus.$emit("packages-arraieved");
      }
    },

    webcard_peomotion_exclusive({ commit }, payload) {
      var config = {
        method: "get",
        url:
          "/api/getallwebcard?promotion_position=" + payload.promotion_position,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      axios(config)
        .then(function (response) {
          // console.log(response.data);
          Eventbus.$emit("iLoading");
        })
        .catch(function (error) {
          // console.log(error);
          Eventbus.$emit("iLoading");
        });
    },
    webcard_peomotion_importent({ commit }, payload) {
      var config = {
        method: "get",
        url:
          "/api/getallwebcard?promotion_position=" + payload.promotion_position,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      axios(config)
        .then(function (response) {
          // console.log(response.data);
          Eventbus.$emit("iLoading");
        })
        .catch(function (error) {
          // console.log(error);
          Eventbus.$emit("iLoading");
        });
    },
    webcard_peomotion_head({ commit }, payload) {
      var config = {
        method: "get",
        url: "/api/getallwebcard?promotion_position=" + payload.query,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      axios(config)
        .then(function (response) {
          // console.log(response.data);
          Eventbus.$emit("iLoading");
        })
        .catch(function (error) {
          // console.log(error);
          Eventbus.$emit("iLoading");
        });
    },
    webcard_peomotion_top({ commit }, payload) {
      var config = {
        method: "get",
        url: "/api/getallwebcard?promotion_position=" + payload.query,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      axios(config)
        .then(function (response) {
          // console.log(response.data);
          Eventbus.$emit("iLoading");
        })
        .catch(function (error) {
          // console.log(error);
          Eventbus.$emit("iLoading");
        });
    },
    webcard_peomotion_niddle({ commit }, payload) {
      var config = {
        method: "get",
        url: "/api/getallwebcard?promotion_position=" + payload.query,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      axios(config)
        .then(function (response) {
          // console.log(response.data);
          Eventbus.$emit("iLoading");
        })
        .catch(function (error) {
          // console.log(error);
          Eventbus.$emit("iLoading");
        });
    },
    regerateWebcard({ commit }, data) {
      var config = {
        method: "post",
        url: "/api/auth/updatemywebcard",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          Toast.fire({
            icon: "success",
            title: "Webcard generated",
          });
          Eventbus.$emit("webcard-generated");
        })
        .catch(function (error) {
          // Toast.fire({
          //   icon: "error",
          //   title: error,
          // });
          Eventbus.$emit("webcard-generated");
        });
    },
    addToMyNetwork({ commit, dispatch }, data) {
      var config = {
        method: "post",
        maxBodyLength: Infinity,
        url: "/api/webcardlibrary",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          dispatch("getMyActiveWebcardLibrary");
          dispatch("getMyPendingWebcardLibrary");
          dispatch("getMyBlockedWebcardLibrary");
          dispatch("getMyMycrositeWebcardLibrary");
          Toast.fire({
            icon: "success",
            title: "Request sent",
          });
        })
        .catch(function (error) {
          Toast.fire({
            icon: "error",
            title: "Request failed, please try again later",
          });
        });
    },
    approveWebcard({ commit, dispatch }, { data, uuid }) {
      var config = {
        method: "post",
        maxBodyLength: Infinity,
        url: "/api/webcardlibrary/" + uuid,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          dispatch("getMyActiveWebcardLibrary");
          dispatch("getMyPendingWebcardLibrary");
          dispatch("getMyBlockedWebcardLibrary");
          dispatch("getMyMycrositeWebcardLibrary");
          Toast.fire({
            icon: "success",
            title: "Request approved",
          });
        })
        .catch(function (error) {
          Toast.fire({
            icon: "error",
            title: "Request failed, please try again later",
          });
        });
    },
    blockToNetwork({ commit, dispatch }, data) {
      var config = {
        method: "post",
        maxBodyLength: Infinity,
        url: "/api/webcardlibrary",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          dispatch("getMyActiveWebcardLibrary");
          dispatch("getMyPendingWebcardLibrary");
          dispatch("getMyBlockedWebcardLibrary");
          dispatch("getMyMycrositeWebcardLibrary");
          Toast.fire({
            icon: "success",
            title: "Request sent",
          });
        })
        .catch(function (error) {
          Toast.fire({
            icon: "error",
            title: "Request failed, please try again later",
          });
        });
    },
    unblockfromNetwork({ commit, dispatch }, { data, uuid }) {
      var config = {
        method: "post",
        maxBodyLength: Infinity,
        url: "/api/webcardlibrary/" + uuid,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          dispatch("getMyActiveWebcardLibrary");
          dispatch("getMyPendingWebcardLibrary");
          dispatch("getMyBlockedWebcardLibrary");
          dispatch("getMyMycrositeWebcardLibrary");
          Toast.fire({
            icon: "success",
            title: "Request sent",
          });
        })
        .catch(function (error) {
          Toast.fire({
            icon: "error",
            title: "Request failed, please try again later",
          });
        });
    },
    removeFromNetwork({ commit, dispatch }, data) {
      var config = {
        method: "post",
        maxBodyLength: Infinity,
        url: "/api/webcardlibrary",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          dispatch("getMyActiveWebcardLibrary");
          dispatch("getMyPendingWebcardLibrary");
          dispatch("getMyBlockedWebcardLibrary");
          dispatch("getMyMycrositeWebcardLibrary");
          Toast.fire({
            icon: "success",
            title: "Webcard Removed",
          });
        })
        .catch(function (error) {
          Toast.fire({
            icon: "error",
            title: "Request failed, please try again later",
          });
        });
    },

    getMyWebcardLibrary({ commit }) {
      var config = {
        method: "get",
        maxBodyLength: Infinity,
        url: "/api/webcardlibrary",
        headers: {
          "Content-Type": "application/json",
        },
      };
      axios(config)
        .then(function (response) {
          //   commit('SET_MY_NUMBER_OF_WEBCARDS', response.data.total)
          commit("SET_MY_WEBCARD_LIBRARY", response.data);
        })
        .catch(function (error) {
          // console.log(error);
        });
    },
    getMyActiveWebcardLibrary({ commit }, query = "") {
      var config = {
        method: "get",
        maxBodyLength: Infinity,
        url: "/api/webcardlibrary?status=active&type=webcard&keyword=" + query,
        headers: {
          "Content-Type": "application/json",
        },
      };
      axios(config)
        .then(function (response) {
          // console.log(response.data.length);
          commit("SET_MY_NUMBER_OF_WEBCARDS", response.data.length);
          commit("SET_MY_ACTIVE_WEBCARD_LIBRARY", response.data);
        })
        .catch(function (error) {
          // console.log(error);
        });
    },
    getMyPendingWebcardLibrary({ commit }, query = "") {
      var config = {
        method: "get",
        maxBodyLength: Infinity,
        url: "/api/webcardlibrary?status=pending&type=webcard&keyword=" + query,
        headers: {
          "Content-Type": "application/json",
        },
      };
      axios(config)
        .then(function (response) {
          commit("SET_MY_PENDING_WEBCARD_LIBRARY", response.data);
        })
        .catch(function (error) {
          // console.log(error);
        });
    },
    getMyBlockedWebcardLibrary({ commit }, query = "") {
      var config = {
        method: "get",
        maxBodyLength: Infinity,
        url: "/api/webcardlibrary?status=blocked&type=webcard&keyword=" + query,
        headers: {
          "Content-Type": "application/json",
        },
      };
      axios(config)
        .then(function (response) {
          commit("SET_MY_BLOCKED_WEBCARD_LIBRARY", response.data);
        })
        .catch(function (error) {
          // console.log(error);
        });
    },
    getMyMycrositeWebcardLibrary({ commit }, query = "") {
      var config = {
        method: "get",
        maxBodyLength: Infinity,
        url: "/api/webcardlibrary?microsites=yes&keyword=" + query,
        headers: {
          "Content-Type": "application/json",
        },
      };
      axios(config)
        .then(function (response) {
          commit("SET_MY_MICROSITE_WEBCARD_LIBRARY", response.data.data);
        })
        .catch(function (error) {
          // console.log(error);
        });
    },

    getAllPublicWebcards({ commit }, microsites) {
      if (microsites == "yes") {
        var data = new FormData();
        data.append("microsites_id", "yes");
        var config = {
          method: "get",
          maxBodyLength: Infinity,
          url: "/api/getallwebcard", //filed param microsites_id, to get all microsites
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        };

        axios(config)
          .then(function (response) {
            commit("SET_ALL_PUBLIC_WEBCARD", response.data.data);
          })
          .catch(function (error) {
            // console.log(error);
          });
      } else {
        var config = {
          method: "get",
          maxBodyLength: Infinity,
          url: "/api/getallwebcard", //filed param microsites_id, to get all microsites
          headers: {
            "Content-Type": "application/json",
          },
        };

        axios(config)
          .then(function (response) {
            commit("SET_ALL_PUBLIC_WEBCARD", response.data.data);
          })
          .catch(function (error) {
            // console.log(error);
          });
      }
    },

    send_email_webcard({ commit }, { data, id }) {
      var config = {
        method: "post",
        maxBodyLength: Infinity,
        url: "/api/auth/sendmail/" + id,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          Toast.fire({
            icon: "success",
            title: "Email sent",
          });
          Eventbus.$emit("send-email");
        })
        .catch(function (error) {
          Toast.fire({
            icon: "error",
            title: "Email sending failed",
          });
          Eventbus.$emit("send-email");
        });
    },
    send_webcard({ commit }, data) {
      var config = {
        method: "post",
        maxBodyLength: Infinity,
        url: "/api/auth/sendwebcard",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          Toast.fire({
            icon: "success",
            title: "Webcard sent",
          });
          Eventbus.$emit("webcard-sent");
        })
        .catch(function (error) {
          console.log(error);
          Eventbus.$emit("webcard-sent");
        });
    },

    get_webcard_exclusive_promotion({ commit }) {
      var config = {
        method: "get",
        maxBodyLength: Infinity,
        url: "/api/getallwebcard?microsites=yes&promotion_position=exclusive&keyword=",
        headers: {
          "Content-Type": "application/json",
        },
      };

      axios(config)
        .then(function (response) {
          // console.log(response.data);
          commit("SET_WEBCARD_PROMOTION_EXCLUSIVE", response.data.data);
        })
        .catch(function (error) {
          // console.log(error);
        });
    },
    get_webcard_importent_promotion({ commit }) {
      var config = {
        method: "get",
        maxBodyLength: Infinity,
        url: "/api/getallwebcard?microsites=yes&promotion_position=important&keyword=",
        headers: {
          "Content-Type": "application/json",
        },
      };

      axios(config)
        .then(function (response) {
          commit("SET_WEBCARD_PROMOTION_IMPORTENT", response.data.data);
        })
        .catch(function (error) {
          // console.log(error);
        });
    },
    get_webcard_head_promotion({ commit }) {
      var config = {
        method: "get",
        maxBodyLength: Infinity,
        url: "/api/getallwebcard?microsites=yes&promotion_position=head&keyword=",
        headers: {
          "Content-Type": "application/json",
        },
      };

      axios(config)
        .then(function (response) {
          commit("SET_WEBCARD_PROMOTION_HEAD", response.data.data);
        })
        .catch(function (error) {
          // console.log(error);
        });
    },
    get_webcard_top_promotion({ commit }, payload) {
      var config = {
        method: "get",
        maxBodyLength: Infinity,
        url: "/api/getallwebcard?microsites=yes&promotion_position=top&keyword=",
        headers: {
          "Content-Type": "application/json",
        },
      };

      axios(config)
        .then(function (response) {
          commit("SET_WEBCARD_PROMOTION_TOP", response.data.data);
        })
        .catch(function (error) {
          // console.log(error);
        });
    },
    get_webcard_middle_promotion({ commit }, payload) {
      var config = {
        method: "get",
        maxBodyLength: Infinity,
        url: "/api/getallwebcard?microsites=yes&promotion_position=middle&keyword=",
        headers: {
          "Content-Type": "application/json",
        },
      };

      axios(config)
        .then(function (response) {
          commit("SET_WEBCARD_PROMOTION_MIDDLE", response.data.data);
        })
        .catch(function (error) {
          // console.log(error);
        });
    },
  },
};
export default webcard;
