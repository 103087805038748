var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: ["switch-wrapper"] },
    [
      _c("label", { staticClass: "input-group-material-label" }, [
        _vm._v(_vm._s(_vm.label)),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "filler" }),
      _vm._v(" "),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }