export default [
	{
		path: 'cmyvillagecommunity',
		name: 'commonMyVillageCommunityy',
		redirect: { name: 'commonVillagesCommunitiesss' },
		meta: {
			title: 'common.tools',
			description: '',
		},
		// component: () => import('@views/app/common/FirstCard')
		component: {
			template: '<router-view></router-view>'
		},
		children: [
			{
				path: 'villages-communities',
				name: 'commonVillagesCommunitiesss',
				meta: [],
				component: () => import('@views/app/common/mvc/villagescommunities/index')
			},
			{
				path: 'lounge',
				name: 'commonMVCLounge',
				meta: [],
				component: () => import('@views/app/common/mvc/lounge/index')
			},
			{
				path: 'villagers-members',
				name: 'commonVillagersMembers',
				meta: [],
				component: () => import('@views/app/common/mvc/villagersmembers/index')
			},
			{
				path: 'promotions',
				name: 'commonMVCPromotions',
				meta: [],
				component: () => import('@views/app/common/mvc/promotions/index')
			},
			{
				path: 'marketplace',
				name: 'commonMarketplace',
				meta: [],
				component: () => import('@views/app/common/mvc/marketplace/index')
			},
			{
				path: 'jobs',
				name: 'commonMVCJobs',
				meta: [],
				component: () => import('@views/app/common/mvc/jobs/index')
			},
			{
				path: 'service',
				name: 'commonService',
				meta: [],
				component: () => import('@views/app/common/mvc/service/index')
			},
			{
				path: 'classifieds',
				name: 'commonClassifieds',
				meta: [],
				component: () => import('@views/app/common/mvc/classifieds/index')
			},
			{
				path: 'helps',
				name: 'commonHelps',
				meta: [],
				component: () => import('@views/app/common/mvc/helps/index')
			},
			{
				path: 'leaders',
				name: 'commonLeaders',
				meta: [],
				component: () => import('@views/app/common/mvc/leaders/index')
			},
			{
				path: 'elections',
				name: 'commonElections',
				meta: [],
				component: () => import('@views/app/common/mvc/elections/index')
			},
			{
				path: 'events',
				name: 'commonMVCEvents',
				meta: [],
				component: () => import('@views/app/common/mvc/events/index')
			},
		]
	},
]