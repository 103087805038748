var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "card",
      class: [
        { "card-lift--hover": _vm.lift },
        `shadow${_vm.shadowSize ? "-" + _vm.shadowSize : ""}`,
        {
          [`shadow${_vm.shadowSize ? "-" + _vm.shadowSize : ""}--hover`]:
            _vm.raise,
        },
        { [`bg-gradient-${_vm.gradient}`]: _vm.gradient },
        { [`bg-${_vm.type}`]: _vm.type },
        { [`border-color-${_vm.border}`]: _vm.border },
      ],
    },
    [
      _vm._t("topImage", function () {
        return [
          _vm.image && _vm.imageOnTop
            ? _c("img", {
                directives: [
                  {
                    name: "lazy",
                    rawName: "v-lazy",
                    value: _vm.image,
                    expression: "image",
                  },
                ],
                staticClass: "card-img-top",
                attrs: { alt: _vm.imageAlt },
              })
            : _vm._e(),
        ]
      }),
      _vm._v(" "),
      !(_vm.$slots.topImage || (_vm.image && _vm.imageOnTop)) &&
      (_vm.$slots.header || _vm.$slots.title || _vm.$slots.actions || _vm.title)
        ? _c(
            "div",
            { staticClass: "card-header", class: _vm.headerClasses },
            [
              _vm._t("header", function () {
                return [
                  _c(
                    "div",
                    { class: ["card-title", _vm.cardTitleClasses] },
                    [
                      _vm._t("title", function () {
                        return [_c("h5", [_vm._v(_vm._s(_vm.title))])]
                      }),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "card-actions" },
                    [
                      _vm._t("actions", function () {
                        return [
                          _vm.canRefresh
                            ? _c(
                                "button",
                                {
                                  staticClass: "btn action",
                                  attrs: { type: "button" },
                                  on: { click: _vm.actionRefresh },
                                },
                                [
                                  _c("i", {
                                    class: [
                                      "fas fa-sync-alt",
                                      { "fa-spin": _vm.refreshing },
                                    ],
                                  }),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.canMaximize
                            ? _c(
                                "button",
                                {
                                  staticClass: "btn action",
                                  attrs: { type: "button" },
                                  on: { click: _vm.actionMaximize },
                                },
                                [_c("i", { staticClass: "fas fa-expand" })]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.canMinimize
                            ? _c(
                                "button",
                                {
                                  staticClass: "btn action",
                                  attrs: { type: "button" },
                                  on: { click: _vm.actionMinimize },
                                },
                                [
                                  _c("i", {
                                    staticClass: "fas fa-window-minimize",
                                  }),
                                ]
                              )
                            : _vm._e(),
                        ]
                      }),
                    ],
                    2
                  ),
                ]
              }),
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.noBody
        ? _c(
            "div",
            { staticClass: "card-body", class: _vm.bodyClasses },
            [_vm._t("default")],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.noBody ? _vm._t("default") : _vm._e(),
      _vm._v(" "),
      _vm._t("bottomImage", function () {
        return [
          _vm.image && !_vm.imageOnTop
            ? _c("img", {
                directives: [
                  {
                    name: "lazy",
                    rawName: "v-lazy",
                    value: _vm.image,
                    expression: "image",
                  },
                ],
                staticClass: "card-img-bottom",
                attrs: { alt: _vm.imageAlt },
              })
            : _vm._e(),
        ]
      }),
      _vm._v(" "),
      (!(_vm.$slots.bottomImage || (_vm.image && !_vm.imageOnTop)) &&
        _vm.$slots.footer) ||
      _vm.showFooter
        ? _c(
            "div",
            { staticClass: "card-footer", class: _vm.footerClasses },
            [_vm._t("footer")],
            2
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }