import request from '@js/core/utils/request';
const apiUrl = 'https://icircles.app/mymicrosites';

export function getMicrosite() {
	return request({
		url: apiUrl + '/microsite',
		method: 'get',

	})
}
export function getMicrositeAll() {
	return request({
		url: apiUrl + '/microsite/all',
		method: 'get',
	})
}


export function createMicrosite(payload, config) {
	return request({
		url: apiUrl + '/microsite',
		method: 'post',
		payload,
		config
	})
}


