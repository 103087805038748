export default [
	{
		path: 'cwallet',
		name: 'commonWallet',
		redirect: { name: 'commonWalletDashboard' },
		meta: {
			title: 'common.wallet',
			description: '',
		},
		component: {
			template: '<router-view></router-view>'
		},
		children: [
			{
				path: 'wallet-dashboard',
				name: 'commonWalletDashboard',
				meta: [],
				component: () => import('@views/app/common/wallet/dashboard/index')
			},
			{
				path: 'send-money',
				name: 'commonSendMoney',
				meta: [],
				component: () => import('@views/app/common/wallet/sendmoney/index')
			},
			{
				path: 'receive-money',
				name: 'commonReceiveMoney',
				meta: [],
				component: () => import('@views/app/common/wallet/receivemoney/index')
			},
			{
				path: 'transaction-history',
				name: 'commonTransactionHistory',
				meta: [],
				component: () => import('@views/app/common/wallet/history/index')
			},
			{
				path: 'add-card',
				name: 'commonAddcard',
				meta: [],
				component: () => import('@views/app/common/wallet/addcard/index')
			},
			{
				path: 'settings',
				name: 'commonSettings',
				meta: [],
				component: () => import('@views/app/common/wallet/settings/index')
			},
			
		]

	},
]