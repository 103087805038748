export default [
	{
		path: 'cmarketplace',
		name: 'commonShoppingmall',
		redirect: { name: 'commonshoppingPromotions' },
		meta: {
			title: 'common.shopping',
			description: '',
		},
		component: {
                   template: '<router-view></router-view>'
		},
		children: [
			{
				path: '',
				name: 'commonshoppingMarketplace',
				meta: [],
				component: () => import('@views/app/common/shoppingmall/market/index')
			},
			{
				path: 'my-purchase',
				name: 'commonMyPurchase',
				meta: [],
				component: () => import('@views/app/common/shoppingmall/mypurchase/index')
			},
			{
				path: 'categories',
				name: 'commonMarletplacecategories',
				meta: [],
				component: () => import('@views/app/common/shoppingmall/categories/index')
			},
			{
				path: 'category/:id',
				name: 'commonMarletplacecategoryProduct',
				meta: [],
				component: () => import('@views/app/common/shoppingmall/categories/Product')
			},
			{
				path: 'stores',
				name: 'commonMarketPlaceStore',
				meta: [],
				component: () => import('@views/app/common/shoppingmall/stores/index')
			},
			{
				path: 'stores/:id',
				name: 'commonMarketPlaceStoreProduct',
				meta: [],
				component: () => import('@views/app/common/shoppingmall/stores/product')
			},
			{
				path: 'brands',
				name: 'commonMarketplaceBrands',
				meta: [],
				component: () => import('@views/app/common/shoppingmall/brands/index')
			},
			{
				path: 'brand/:id',
				name: 'commonMarketplaceBrandsProduct',
				meta: [],
				component: () => import('@views/app/common/shoppingmall/brands/Product')
			},
			{
				path: 'reel/:index/:uuid',
				name: 'commonMarketplaceRell',
				meta: [],
				component: () => import('@views/app/common/shoppingmall/reel/index'),
				props: true
			},
			{
				path: 'deals',
				name: 'commonMarketplaceDeals',
				meta: [],
				component: () => import('@views/app/common/shoppingmall/deals/index')
			},
			{
				path: 'discounts',
				name: 'commonMarketplaceDiscount',
				meta: [],
				component: () => import('@views/app/common/shoppingmall/discounts/index')
			},
			{
				path: 'coupons',
				name: 'commonMarketPlaceCoupons',
				meta: [],
				component: () => import('@views/app/common/shoppingmall/coupons/index')
			},
			{
				path: 'orders',
				name: 'commonMarketPlaceMyorders',
				meta: [],
				component: () => import('@views/app/common/shoppingmall/order/index')
			},
			{
				path: 'order/:id',
				name: 'commonMarketPlaceMyordersSingle',
				meta: [],
				component: () => import('@views/app/common/shoppingmall/order/order')
			},
			{
				path: 'checkout',
				name: 'commonMarketPlaceCheckout',
				meta: [],
				component: () => import('@views/app/common/shoppingmall/checkout/index')
			},
			{
				path: 'checkout-success',
				name: 'commonMarketPlaceCheckoutSuccess',
				meta: [],
				component: () => import('@views/app/common/shoppingmall/checkout/success')
			},
			{
				path: 'tickets',
				name: 'commonMarketplaceTickets',
				meta: [],
				component: () => import('@views/app/common/shoppingmall/tickets/index')
			},
			{
				path: 'iCircle-store',
				name: 'commonIcirclesStore',
				meta: [],
				component: () => import('@views/app/common/shoppingmall/icirclesstore/index')
			},
		
			
		]

	},
]
