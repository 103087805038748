export default [
	{
		path: 'matrimony',
		name: 'commonMatromony',
		redirect: { name: 'commonMatromonyDashboard'},
		meta: {
			title: 'common.matromony',
			description: '',
		},
		component: {
			template: '<router-view></router-view>'
		},
		children: [
			{
				path: '',
				name: 'commonMatromonyDashboard',
				meta: [],
				component: () => import('@views/app/common/matromony/home/index')
			},			
			{
				path: 'add-profile',
				name: 'commonMatromonyAdd',
				meta: [],
				component: () => import('@views/app/common/matromony/home/Add')
			},			
			{
				path: 'edit-profile/:uuid',
				name: 'commonMatromonyEdit',
				meta: [],
				component: () => import('@views/app/common/matromony/home/Edit')
			},			
			{
				path: 'my-profile',
				name: 'commonMatromonyMy',
				meta: [],
				component: () => import('@views/app/common/matromony/home/Myprofile')
			},			
			{
				path: 'profile/:id/:uuid',
				name: 'commonMatromonyDetails',
				meta: [],
				component: () => import('@views/app/common/matromony/home/Details')
			},			
		]

	},
]