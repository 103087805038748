import classifiedRoutes from "../common/classified";
import frameRoutes from "../common/frames";
import inboxRoutes from "../common/inbox";
import jobsRoutes from "../common/jobs";
import journalRoutes from "../common/journal";
import mediaRoutes from "../common/media";
import micrositesRoutes from "../common/microsites";
import mypageRoutes from "../common/mypage";
import professionalloungeRoutes from "../common/professionallounge";
import profileRoutes from "../common/profile";
import socialloungeRoutes from "../common/sociallounge";
import tmeRoutes from "../common/tme";
import toolsRoutes from "../common/tools";
import videoconferenceRoutes from "../common/videoconference";
import webcardRoutes from "../common/wbcard";
import walletRoute from "../common/wallet";
import loungeRoute from "../common/lounge";
import shoppingmallRoute from "../common/shoppingmall";
import storiesRoute from "../common/stories";
import transportRoute from "../common/transport";
import mvcRoute from "../common/mvc";
import matromonyRoute from "../common/matromony";

export default [
  {
    path: "lounge",
    name: "commonDashboard",
    meta: {
      title: "dashboard.lounge",
      description: "",
      icon: "fas fa-user",
    },
    components: {
      // header: () => import('@views/app/dashboard/header' /* webpackChunkName: "app/dashboard/header" */),
      special: () =>
        import(
          "@views/app/common/dashboard/index" /* webpackChunkName: "app/dashboard/index" */
        ),
    },
  },
  {
    path: "",
    meta: {
      isHiddenNav: true,
    },
    components: {
      // header: () => import('@views/layouts/partials/app-common-page-header' /* webpackChunkName: "layouts/partials/app-common-page-header" */),
      default: { template: "<router-view></router-view>" },
    },
    children: [
      ...webcardRoutes,
      ...frameRoutes,
      ...profileRoutes,
      ...micrositesRoutes,
      ...mypageRoutes,
      ...socialloungeRoutes,
      ...professionalloungeRoutes,
      ...mediaRoutes,
      ...classifiedRoutes,
      ...jobsRoutes,
      ...journalRoutes,
      ...inboxRoutes,
      ...videoconferenceRoutes,
      ...toolsRoutes,
      ...walletRoute,
      ...loungeRoute,
      ...shoppingmallRoute,
      ...storiesRoute,
      ...tmeRoutes,
      ...transportRoute,
      ...mvcRoute,
      ...matromonyRoute
    ],
  },
];
