import axios from 'axios';
import router from '../../router'
import Swal from 'sweetalert2'
import EventBus from '../../event-bus';
import { Toast } from '../../mixins/swal'

const market = {
	namespaced: true,
	state: {
		stores: [],
		products: [],
		categories: [],
		brands: [],
		product_by_category: [],
		product_by_brand: [],
		stores_products: [],
		current_page: 1,
		order_response: {},
		order_cartlist: [],
		order: [],
		order_details: {},
		coupons: [],
		frames: [],
		storeframes: [],
		categoryframes: [],
		brandframes: [],
		storepage: 1,
		categoryepage: 1,
		brandpage: 1,
 

	},
	getters: {
		stores: state => state.stores,
		products: state => state.products,
		categories: state => state.categories,
		brands: state => state.brands,
		productbycate: state => state.product_by_category,
		productbybrand: state => state.product_by_brand,
		productbystore: state => state.stores_products,
		orderresponse: state => state.order_response,
		ordercartlist: state => state.order_cartlist,
		orders: state => state.order,
		orderdetails: state => state.order_details,
		getcoupons: state => state.coupons,
		getframes: state => state.frames,
		getstoreframes: state => state.storeframes,
		getcategoryframes: state => state.categoryframes,
		getbrandframes: state => state.brandframes,
		
		currentPage: state => state.current_page,
		storePage: state => state.storepage,
		categoryPage: state => state.categoryepage,
		brandPage: state => state.brandpage,
	},



	mutations: {

		SET_PRODUCTS: (state, data = {}) => {
			state.products = data
		},
		SET_PRODUCTS_PAGINATED: (state, data = {}) => {
			state.products.push(...data)
		},
		SET_PRODUCT_PAGE: (state, data = {}) => {
			state.current_page = data
		},
		SET_CATEGORIES: (state, data = {}) => {
			state.categories = data
		},
		SET_BRANDS: (state, data = {}) => {
			state.brands = data
		},
		SET_PRODUCTS_BY_CATEGORY: (state, data = {}) => {
			state.product_by_category = data
		},
		SET_PRODUCTS_BY_BRAND: (state, data = {}) => {
			state.product_by_brand = data
		},
		SET_STORES: (state, data = {}) => {
			state.stores = data
		},
		SET_PRODUCTS_BY_STORE: (state, data = {}) => {
			state.stores_products.push(...data)
		},
		SET_ORDER_RESPONSE: (state, data = {}) => {
			state.order_response = data
		},
		SET_ORDER_CARTLIST: (state, data = {}) => {
			state.order_cartlist = data
		},
		SET_MY_ORDER: (state, data = {}) => {
			state.order = data
		},
		SET_SINGLE_ORDER: (state, data = {}) => {
			state.order_details = data
		},
		SET_COUPONS: (state, data = {}) => {
			state.coupons = data
		},
		SET_MARKET_FRAMES: (state, data = {}) => {
			state.frames = data
		},
		SET_MARKET_FRAMES_STORE: (state, data = {}) => {
			state.storeframes = data
		},
		SET_MARKET_CATEGORY_FRAMES: (state, data = {}) => {
			state.categoryframes = data
		},
		SET_MARKET_BRAND_FRAMES: (state, data = {}) => {
			state.brandframes = data
		},
		SET_STORE_PRODUCT_PAGE: (state, data = {}) => {
			state.storepage = data
		},
		SET_CATEGORY_PRODUCT_PAGE: (state, data = {}) => {
			state.categoryepage = data
		},
		SET_BRAND_PRODUCT_PAGE: (state, data = {}) => {
			state.brandpage = data
		},
		SET_CATEGORY_PRODUCTS_PAGINATED: (state, data = {}) => {
			state.product_by_category.push(...data)
		},
		SET_BRAND_PRODUCTS_PAGINATED: (state, data = {}) => {
			state.product_by_brand.push(...data)
		},
		SET_STORE_PRODUCTS_PAGINATED: (state, data = {}) => {
			state.stores_products.push(...data)
		},
	},
	actions: {
		async get_frames({commit}) {
			
			let reqOptions = {
			  url: "/api/ecommerce/frames",
			  method: "GET",
			}
			try {
				let response = await axios.request(reqOptions);
				commit('SET_MARKET_FRAMES', response.data.data)
				// commit('SET_MARKET_PAGE', response.data.data)
				console.log(response.data)
			} catch (error) {
				
			}
		},
		async get_brand_frames({commit}, {brand_id}) {
			
			let reqOptions = {
			  url: "/api/ecommerce/frames?brand_id="+brand_id,
			  method: "GET",
			}
			try {
				let response = await axios.request(reqOptions);
				commit('SET_MARKET_BRAND_FRAMES', response.data.data)
				// commit('SET_MARKET_PAGE', response.data.data)
				console.log(response.data)
			} catch (error) {
				
			}
		},
		async get_category_frames({commit}, {category_id}) {
			
			let reqOptions = {
			  url: "/api/ecommerce/frames?=category_id"+category_id,
			  method: "GET",
			}
			try {
				let response = await axios.request(reqOptions);
				commit('SET_MARKET_CATEGORY_FRAMES', response.data.data)
				// commit('SET_MARKET_PAGE', response.data.data)
				console.log(response.data)
			} catch (error) {
				
			}
		},
		async get_store_frames({commit}, {microsite_id}) {
			
			let reqOptions = {
			  url: "/api/ecommerce/frames?microsite_id="+microsite_id,
			  method: "GET",
			}
			try {
				let response = await axios.request(reqOptions);
				commit('SET_MARKET_FRAMES_STORE', response.data.data)
				// commit('SET_MARKET_PAGE', response.data.data)
				console.log(response.data)
			} catch (error) {
				
			}
		},
		async get_coupons({commit}) { 
			   let reqOptions = {
				 url: "/api/ecommerce/coupon?microsite_id=&code=",
				 method: "GET",
			   }
			   try {
				   let response = await axios.request(reqOptions);
				   commit('SET_COUPONS', response.data.data)
			   } catch (error) {
				
			   }
		},
	    async get_single_order({commit}, uuid) {
                           let headersList = {
				"Content-Type": "application/json",
			   }
			   
			   let reqOptions = {
				 url: "/api/ecommerce/order/"+uuid,
				 method: "GET",
				 headers: headersList,
			   }
			   try {
				   let response = await axios.request(reqOptions);
				   commit('SET_SINGLE_ORDER', response.data)
				   EventBus.$emit("got-single-order")
			   } catch (error) {
				   EventBus.$emit("got-single-order")
			   }
		},
		async get_my_orders({commit}, {myorder}){
			let headersList = {
				"Content-Type": "application/json",
			   }
			   
			   let reqOptions = {
				 url: "/api/ecommerce/order?myorder="+myorder,
				 method: "GET",
				 headers: headersList,
			   }
			   try {
				   let response = await axios.request(reqOptions);
				   commit("SET_MY_ORDER", response.data.data)
				   EventBus.$emit("order-reached")
			   } catch (error) {
				   EventBus.$emit("order-reached")
				   
			   }
		},
		async create_order({ commit }, {data, cartList}) {
			const config = {
			  method: 'POST',
			  url: '/api/ecommerce/order',	
			  
			  data
			};
			try {
				const res = await axios(config)
				commit('SET_ORDER_RESPONSE', res.data)
				commit('SET_ORDER_CARTLIST', cartList)
				router.push({name: 'commonMarketPlaceCheckoutSuccess'})
				EventBus.$emit("order-created")
			} catch (error) {
				EventBus.$emit("order-failed")
			}
		},
		async get_stores({ commit }, {subtype_id, keyword}) {
			const config = {
			  method: 'GET',
			  url: '/api/microsite/microsites?subtype_id='+subtype_id+'&keyword='+keyword,	
			};
			try {
				const res = await axios(config)
				if (res.status == 200) {
                                  commit('SET_STORES', res.data.data)
				}
				EventBus.$emit("stores-arrived")
			} catch (error) {
				EventBus.$emit("stores-arrived")
			}
		},
		async get_brands({commit}, query = '') {
			let headersList = {
				"Content-Type": "application/json",
			   }
			   
			   let reqOptions = {
				 url: "/api/ecommerce/brand?query="+query,
				 method: "GET",
				 headers: headersList,
			   }
			   try {
				   let response = await axios.request(reqOptions);
				   if(response.status == 200 || response.status == 201) {
					 commit("SET_BRANDS", response.data.data)
				   }
				   EventBus.$emit("brand-arrieved")
			   } catch (error) {
				  EventBus.$emit("brand-arrieved")
			   }
		},
		async get_product({ commit }, {country_code}) {
			let config = {
			url: "/api/ecommerce/product?inmarketplace=1&sort_by=discount_percent&order=desc&marketplace_approved=1",
			method: "GET",
			headers: {
				"Content-Type": "application/json",
			  },
			}
			try {
				let response = await axios(config);
				if (response.status == 200 || response.status == 201) {
				commit("SET_PRODUCTS", response.data.data)
				commit('SET_PRODUCT_PAGE', response.data.current_page)
				EventBus.$emit("product-got-market")
				}
				
			} catch (error) {
				
			}
		},
		async get_product_per_page({ commit }, {current_page, country_code}) {
			let config = {
			url: "/api/ecommerce/product?inmarketplace=1&sort_by=discount_percent&order=desc&marketplace_approved=1&current_page="+current_page+"&country_code"+country_code,
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				},
			}
			try {
				let response = await axios(config);
				if (response.status == 200 || response.status == 201) {
					commit("SET_PRODUCTS_PAGINATED", response.data.data)
					commit('SET_PRODUCT_PAGE', response.data.current_page)
					EventBus.$emit("product-got-market-loading")
				}
				
			} catch (error) {
				EventBus.$emit("product-got-market-loading")
			}
		},
		async get_categoryproduct_per_page({ commit }, {category_id,current_page, country_code}) {
			let config = {
			url: "/api/ecommerce/product?inmarketplace=1&category_id="+category_id+"&sort_by=discount_percent&order=desc&marketplace_approved=1&current_page="+current_page+"country_code="+country_code,
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				},
			}
			try {
				let response = await axios(config);
				if (response.status == 200 || response.status == 201) {
					commit("SET_CATEGORY_PRODUCTS_PAGINATED", response.data.data)
					commit('SET_PRODUCT_PAGE', response.data.current_page)
					EventBus.$emit("product-got-market-loading")
				}
				
			} catch (error) {
				EventBus.$emit("product-got-market-loading")
			}
		},
		async get_brandproduct_per_page({ commit }, {brand_id ,current_page, country_code}) {
			let config = {
			url: "/api/ecommerce/product?inmarketplace=1&brand_id="+brand_id+"&sort_by=discount_percent&order=desc&marketplace_approved=1&current_page="+current_page+"country_code="+country_code,
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				},
			}
			try {
				let response = await axios(config);
				if (response.status == 200 || response.status == 201) {
					commit("SET_BRAND_PRODUCTS_PAGINATED", response.data.data)
					commit('SET_PRODUCT_PAGE', response.data.current_page)
					EventBus.$emit("product-got-market-loading")
				}
				
			} catch (error) {
				EventBus.$emit("product-got-market-loading")
			}
		},
		async get_storeproduct_per_page({ commit }, {microsite_id, current_page, country_code}) {
			let config = {
			url: "/api/ecommerce/product?inmarketplace=1&sort_by=discount_percent&order=desc&marketplace_approved=1&current_page="+current_page+"country_code="+country_code+"microsite_id"+microsite_id,
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				},
			}
			try {
				let response = await axios(config);
				if (response.status == 200 || response.status == 201) {
					commit("SET_STORE_PRODUCTS_PAGINATED", response.data.data)
					commit('SET_PRODUCT_PAGE', response.data.current_page)
					EventBus.$emit("product-got-market-loading")
				}
				
			} catch (error) {
				EventBus.$emit("product-got-market-loading")
			}
		},
		async get_product_by_store({ commit }, {microsite_id, query}) {
			let config = {
			url: "/api/ecommerce/product?inmarketplace=1&sort_by=discount_percent&order=desc&marketplace_approved=1&microsite_id="+microsite_id+"&keyword="+query,
			method: "GET",
			headers: {
				"Content-Type": "application/json",
			  },
			}
			try {
				let response = await axios(config);
				if (response.status == 200 || response.status == 201) {
				commit("SET_PRODUCTS_BY_STORE", response.data.data)
				commit('SET_STORE_PRODUCT_PAGE', response.data.current_page)
				EventBus.$emit("got-product-by-store")
				}
				
			} catch (error) {
				
			}
		},
		async get_product_by_category({ commit }, {category_id, query}) {
			let config = {
			url: "/api/ecommerce/product?inmarketplace=1&sort_by=discount_percent&order=desc&marketplace_approved=1&category_id="+category_id+"&keyword="+query,
			method: "GET",
			headers: {
				"Content-Type": "application/json",
			  },
			}
			try {
				let response = await axios(config);
				if (response.status == 200 || response.status == 201) {
				commit("SET_PRODUCTS_BY_CATEGORY", response.data.data)
				commit('SET_CATEGORY_PRODUCT_PAGE', response.data.current_page)
				EventBus.$emit("got-product-by-category")
				}
				
			} catch (error) {
				
			}
		},
		async get_product_by_brand({ commit }, {brand_id, query}) {
			let config = {
			url: "/api/ecommerce/product?inmarketplace=1&sort_by=discount_percent&order=desc&marketplace_approved=1&brand_id="+brand_id+"&keyword="+query,
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				},
			}
			try {
				let response = await axios(config);
				if (response.status == 200 || response.status == 201) {
				commit("SET_PRODUCTS_BY_BRAND", response.data.data)
				commit('SET_BRAND_PRODUCT_PAGE', response.data.current_page)
				EventBus.$emit("got-product-by-brand")
				}
				
			} catch (error) {
				
			}
		},
		async search_product({ commit }, {query}) {
			let config = {
			url: "/api/ecommerce/product?inmarketplace=1&sort_by=discount_percent&order=desc&marketplace_approved=1&keyword="+query,
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				},
			}
			try {
				let response = await axios(config);
				if (response.status == 200 || response.status == 201) {
				commit("SET_PRODUCTS", response.data.data)
				EventBus.$emit("product-got-market")
				}
				
			} catch (error) {
				
			}
		},

		async get_categories({commit}, query = '') {
			let headersList = {
				"Content-Type": "application/json",
			   }
			   
			   let reqOptions = {
				 url: "/api/ecommerce/category?query="+query,
				 method: "GET",
				 headers: headersList,
			   }

			   try {
				   let response = await axios.request(reqOptions);
				   if (response.status == 200 || response.status == 201) {
					 commit("SET_CATEGORIES", response.data.data)
				   }
				   EventBus.$emit("category-arrieved")
			   } catch (error) { 
				EventBus.$emit("category-arrieved")
			   }   
		}
			
   }
}
export default market
