export default [
	{
		path: 'cinbox',
		name: 'commonInbox',
		redirect: { name: 'commonIndexList' },
		meta: {
			title: 'common.inbox',
			description: '',
			// icon: 'fas fa-user',
		},
		component: {
			template: '<router-view></router-view>'
		},
		children: [
			{
				path: '',
				name: 'commonIndexList',
				meta: {
					// trans: 'common.indexlist',
					title: 'common.indexlist',
				},
				component: () => import('@views/app/common/inbox/index' /* webpackChunkName: "app/activity/index" */),
			},
		]

	},
]