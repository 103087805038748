export default [
	{
		path: 'csociallounge',
		name: 'commonSociallounge',
		redirect: { name: 'commonSocialloungeDashboard' },
		meta: {
			title: 'common.sociallounge',
			description: '',
		},
		// component: () => import('@views/app/common/FirstCard')
		component: {
              template: '<router-view></router-view>'
		},
		children: [
		
			{
				path: 'sociallounge-dashboard',
				name: 'commonSocialloungeDashboard',
				meta: [],
				component: () => import('@views/app/common/socialLounge/dashboard/index')
			},
		]

	},
]