import Vue from "vue";
import Vuex from "vuex";
import CreatePersistedState from "vuex-persistedstate";

import { clearStore } from "@core/utils/auth";
import { updateVars, vars } from "@js/vars";

// loading store for all the modules
import chat from "./modules/chat";
import common from "./modules/common";
import config from "./modules/config";
import errorLog from "./modules/error-log";
import microsite from "./modules/microsite";
import profile from "./modules/profile";
import navigation from "./modules/navigation";
import product from "./modules/product";
import search from "./modules/search";
import todo from "./modules/todo";
import user from "./modules/user";
import webcard from "./modules/webcard";
import socialmicrosite from "./modules/socialmedia";
import classified from "./modules/classified";
import jobs from "./modules/jobs";
import lounge from "./modules/lounge";
import volt from "./modules/volt";
import media from "./modules/media";
import market from "./modules/market";
import matromony from "./modules/matromony";
import dp from "./modules/dp"

// loading other getters, actions and mutations
import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

Vue.use(Vuex);

if (window.localStorage) {
  updateVars();

  const existingVersion = window.localStorage.getItem(
    vars.localStorageVersionKey
  );
  console.log("Existing Store Version: ", existingVersion);

  if (vars.appVersion !== existingVersion) {
    console.log("New Store Version: ", vars.appVersion);
    clearStore();
    console.log("Old Version Removed: ", existingVersion);
  }
  window.localStorage.setItem(vars.localStorageVersionKey, vars.appVersion);
}

const store = new Vuex.Store({
  state: {},
  modules: {
    config,
    errorLog,
    navigation,
    user,
    search,
    todo,
    common,
    chat,
    product,
    microsite,
    profile,
    webcard,
    socialmicrosite,
    classified,
    jobs,
    lounge,
    volt,
    media,
    market,
    matromony,
    dp
  },
  getters,
  actions,
  mutations,
  plugins: [
    CreatePersistedState({
      key: vars.localStorageKey,
    }),
  ],
});

export default store;
