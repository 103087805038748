var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "btn-group",
      class: [{ show: _vm.isOpen }, { dropup: _vm.direction === "up" }],
    },
    [
      _vm._t("button", function () {
        return [
          _c(
            "base-button",
            {
              attrs: {
                type: "button",
                size: _vm.size,
                design: _vm.design,
                "text-color": _vm.textColor,
                icon: _vm.icon,
                text: _vm.text,
                outline: _vm.outline,
                rounded: _vm.rounded,
                iconOnly: _vm.iconOnly,
                block: _vm.block,
              },
              on: { click: _vm.handleClick },
            },
            [_vm._t("text")],
            2
          ),
        ]
      }),
      _vm._v(" "),
      _c(
        "base-button",
        {
          directives: [
            {
              name: "click-outside",
              rawName: "v-click-outside",
              value: _vm.closeDropDown,
              expression: "closeDropDown",
            },
          ],
          staticClass: "dropdown-toggle dropdown-toggle-split",
          attrs: {
            type: "button",
            size: _vm.size,
            design: _vm.design,
            "text-color": _vm.textColor,
            icon: _vm.icon,
            text: _vm.text,
            outline: _vm.outline,
            rounded: _vm.rounded,
            iconOnly: _vm.iconOnly,
            block: _vm.block,
            "aria-haspopup": "true",
            "aria-expanded": _vm.isOpen,
          },
          on: { click: _vm.toggleDropDown },
        },
        [_c("span", { staticClass: "sr-only" }, [_vm._v("Toggle Dropdown")])]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "dropdown-menu",
          class: [
            { "dropdown-menu-right": _vm.position === "right" },
            { show: _vm.isOpen },
            _vm.menuClasses,
          ],
        },
        [_vm._t("default")],
        2
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }