export default [
  {
    path: "cmicrosites",
    name: "commonMicrosites",
    redirect: { name: "commonMicrositesBrowse" },
    meta: {
      title: "common.microsites",
      description: "",
    },
    // component: () => import('@views/app/common/FirstCard')
    component: {
      template: "<router-view></router-view>",
    },
    children: [
      // {
      // 	path: 'microsite-dashboard',
      // 	name: 'commonMicrositesDashboard',
      // 	meta: [],
      // 	component: () => import('@views/app/common/microsite/dashboard/index')
      // },
      {
        path: "browse-microsite",
        name: "commonMicrositesBrowse",
        meta: [],
        component: () =>
          import("@views/app/common/microsite/browsemicrosite/index"),
      },
      {
        path: "my-microsites",
        name: "commonMicrositesMy",
        meta: [],
        component: () =>
          import("@views/app/common/microsite/mymicrosite/index"),
      },
      {
        path: "mycrosite-holder",
        name: "commonMicrositesHolder",
        meta: [],
        component: () =>
          import("@views/app/common/microsite/mycrositeholder/index"),
      },
      {
        path: "sales-board",
        name: "commonSalesBoard",
        meta: [],
        component: () => import("@views/app/common/microsite/salesboard/index"),
      },
      {
        path: "notice-board",
        name: "commonNoticeBoard",
        meta: [],
        component: () =>
          import("@views/app/common/microsite/noticeboard/index"),
      },
      {
        path: "lucky-day",
        name: "commonLuckyDay",
        meta: [],
        component: () => import("@views/app/common/microsite/luckyday/index"),
      },
      {
        path: "doctors-chambers",
        name: "commonDoctorsChambers",
        meta: [],
        component: () =>
          import("@views/app/common/microsite/doctorschambers/index"),
      },
      {
        path: "law-chambers",
        name: "commonLawChambers",
        meta: [],
        component: () =>
          import("@views/app/common/microsite/lawchambers/index"),
      },
      {
        path: "tour-and-travels",
        name: "commonTourAndTravels",
        meta: [],
        component: () =>
          import("@views/app/common/microsite/tourandtravels/index"),
      },
      {
        path: "buyers-directory",
        name: "commonBuyersDirectory",
        meta: [],
        component: () =>
          import("@views/app/common/microsite/buyersdirectory/index"),
      },
      {
        path: "suppliers-directory",
        name: "commonSuppliersDirectory",
        meta: [],
        component: () =>
          import("@views/app/common/microsite/suppliersdirectory/index"),
      },
      {
        path: "microsite-fair",
        name: "commonMicrositeFair ",
        meta: [],
        component: () =>
          import("@views/app/common/microsite/micrositefair/index"),
      },

      {
        path: "firm-fresh",
        name: "commonFirmFresh",
        meta: [],
        component: () => import("@views/app/common/microsite/firmfresh/index"),
      },

      {
        path: "my-microsite-categories",
        name: "commonMicrositesCategory",
        meta: [],
        component: () =>
          import("@views/app/common/microsite/mymicrosite/category/Category"),
      },
      {
        path: "host-microsite/:uuid",
        name: "commonHostMicrosite",
        meta: [],
        component: () =>
          import("@views/app/common/microsite/mymicrosite/host/index"),
      },
      {
        path: "my-microsite-subcategories/:id",
        name: "commonMicrositesSubCategory",
        meta: [],
        component: () =>
          import(
            "@views/app/common/microsite/mymicrosite/category/SubCategory"
          ),
      },
      {
        path: "my-microsite-add/:id",
        name: "commonMicrositesAdd",
        meta: [],
        component: () =>
          import("@views/app/common/microsite/mymicrosite/AddMicrosite"),
      },
      {
        path: "my-microsite-edit/:id",
        name: "commonMicrositesedit",
        // props: (route) => ({ id: route.params.id, data: route.query.data }),
        component: () =>
          import("@views/app/common/microsite/mymicrosite/EditMicrosite"),
      },
      {
        path: "my-microsite-details/:id",
        name: "commonMicrositeDetails",

        component: () =>
          import("@views/app/common/microsite/mymicrosite/DetailedMicrosite"),
      },
      {
        path: "my-microsite-type-add",
        name: "commonMicrositeAddTypeee",

        component: () => import("@views/app/common/microsite/type/Add.vue"),
      },
      {
        path: "my-themes/:microsite_id/:subtype_id/:uuid",
        name: "commonMicrositeMyTheme",

        component: () => import("@views/app/common/microsite/mythemes/Index.vue"),
      },
      {
        path: "my-microsite-type-edit/:id",
        name: "commonMicrositeAddTypeedit",

        component: () => import("@views/app/common/microsite/type/Edit.vue"),
      },
      {
        path: "my-microsite-type",
        name: "commonMicrositeAddType",

        component: () => import("@views/app/common/microsite/type/index.vue"),
      },
      {
        path: "my-microsite-subtype-add/:id",
        name: "commonMicrositeAddSubType",

        component: () =>
          import("@views/app/common/microsite/subtype/AddSub.vue"),
      },
      {
        path: "my-microsite-subtype-edit/:id",
        name: "commonMicrositeEditSubType",

        component: () =>
          import("@views/app/common/microsite/subtype/EditSub.vue"),
      },
      {
        path: "my-microsite-subtype-index/:id",
        name: "commonMicrositeIndexSubType",

        component: () =>
          import("@views/app/common/microsite/subtype/index.vue"),
      },
      {
        path: "my-microsite-themes-index/:id",
        name: "commonMicrositeIndexTheme",
        component: () => import("@views/app/common/microsite/theme/Index.vue"),
      },
      {
        path: "my-microsite-theme-add/:id",
        name: "commonMicrositeAddTheme",
        component: () => import("@views/app/common/microsite/theme/Add.vue"),
      },
      {
        path: "my-microsite-theme-edit/:uuid",
        name: "commonMicrositeEditTheme",
        component: () => import("@views/app/common/microsite/theme/Edit.vue"),
      },
      {
        path: "microsite-change-theme/:id",
        name: "commonMicrositeChangeMicrositeTheme",
        component: () =>
          import("@views/app/common/microsite/mymicrosite/ChangeTheme.vue"),
      },
    ],
  },
];
