export default [
	{
		path: 'cjournal',
		name: 'commonJournal',
		redirect: { name: 'commonJournalList' },
		meta: {
			title: 'common.journal',
			description: '',
		},
		// component: () => import('@views/app/common/FirstCard')
		component: {
			template: '<router-view></router-view>'
		},
		children: [
			{
				path: '',
				name: 'commonJournalList',
				meta: [

				],
				component: () => import('@views/app/common/journal/index')
			}
		]

	},
]