export default [
	{
		path: 'ctimemoneyemotion',
		name: 'commonTimeMoneyEmotion',
		redirect: { name: 'commonMyScore' },
		meta: {
			title: 'common.tools',
			description: '',
		},
		// component: () => import('@views/app/common/FirstCard')
		component: {
			template: '<router-view></router-view>'
		},
		children: [
			{
				path: 'my-score',
				name: 'commonMyScore',
				meta: [],
				component: () => import('@views/app/common/tme/myscore/index')
			},
			{
				path: 'give-scores',
				name: 'commonGiveScores',
				meta: [],
				component: () => import('@views/app/common/tme/givescore/index')
			},
			{
				path: 'improvements',
				name: 'commonImprovements',
				meta: [],
				component: () => import('@views/app/common/tme/improvements/index')
			},
		]
	},
]