var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: [
        "base-container page-container",
        {
          boxed: _vm.boxed,
          "boxed-right": _vm.boxed === "right",
          "boxed-left": _vm.boxed === "left",
          "min-height": _vm.minHeight,
          "min-height-sm": _vm.minHeight === "sm",
          "min-height-md": _vm.minHeight === "md",
          "min-height-lg": _vm.minHeight === "lg",
          "h-100": _vm.minHeight === "full",
          "has-form": _vm.hasForm,
          "has-footer": _vm.hasFooter,
        },
      ],
    },
    [
      _vm.withLoader
        ? _c("animated-loader", {
            attrs: {
              "is-loading": _vm.isLoading,
              "loader-color": _vm.loaderColor,
              "overlay-color": _vm.overlayColor,
              size: _vm.loaderSize,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }