var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "custom-control custom-checkbox",
      class: [_vm.classes, { disabled: _vm.disabled }],
    },
    [
      _c("input", {
        staticClass: "custom-control-input",
        attrs: { id: _vm.cbId, type: "checkbox", disabled: _vm.disabled },
        domProps: { checked: _vm.shouldBeChecked, value: _vm.value },
        on: { change: _vm.updateInput },
      }),
      _vm._v(" "),
      _vm.clickStop
        ? [
            _c(
              "label",
              {
                staticClass: "custom-control-label",
                attrs: { for: _vm.cbId },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    $event.preventDefault()
                    return _vm.toggleInput.apply(null, arguments)
                  },
                },
              },
              [
                _vm._t("default", function () {
                  return [_vm.inline ? _c("span", [_vm._v(" ")]) : _vm._e()]
                }),
              ],
              2
            ),
          ]
        : [
            _c(
              "label",
              { staticClass: "custom-control-label", attrs: { for: _vm.cbId } },
              [
                _vm._t("default", function () {
                  return [_vm.inline ? _c("span", [_vm._v(" ")]) : _vm._e()]
                }),
              ],
              2
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }