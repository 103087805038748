export default [
	{
		path: 'cmypage',
		name: 'commonMyPage',
		redirect: { name: 'commonMypageDashboard' },
		meta: {
			title: 'common.mypage',
			description: '',
		
		},
		// component: () => import('@views/app/common/FirstCard')
		component: {
			temptale: '<router-view></router-view>'
		},
		children: [
			
		   {
			path: 'mypage-dashboard',
			name: 'commonMypageDashboard',
			meta: [],
			component: () => import('@views/app/common/mypage/dashboard/index')
		},
	]

	},
]