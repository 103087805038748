
import axios from 'axios';
import Swal from 'sweetalert2'
import EventBus from '../../event-bus';
import { Toast } from '../../mixins/swal'

const volt = {
	namespaced: true,
	state: {
		my_volts: [],
		my_orders: [],


	},
	getters: {
		getmyvolt: state => state.my_volts,
		getmyorder: state => state.my_orders,

	},



	mutations: {

		SET_MY_VOLT: (state, data = {}) => {
			state.my_volts = data
		},
		SET_MY_ORDER: (state, data = {}) => {
			state.my_orders = data
		},


	},
	actions: {
		async create_nominee({commit}, {data}) {
            var config = {
				method: "post",
				url: "/api/qrdrive/nominee",
				data,
			}
			try {
				const res = await axios(config)
				if(res.status == 201) {
					EventBus.$emit("nominee-created")
				}
			} catch (error) {
				EventBus.$emit("nominee-created")
			}
		},
		async update_nominee({commit}, {uuid, data}) {
            var config = {
				method: "post",
				url: "/api/qrdrive/nominee/"+uuid,
				data,
			}
			try {
				await axios(config)
				EventBus.$emit("nominee-updated")	
			} catch (error) {
				EventBus.$emit("nominee-updated")
			}
		},
		update_status({ commit , dispatch}, payload) {
			// console.log(payload.uuid)
			var config = {
				method: "post",
				maxBodyLength: Infinity,
				url: "/api/qrdrive/vaultupdate/" + payload.uuid,
				headers: {
					"Content-Type": "application/json",
				},
				data: payload.data,
			}
			axios(config)
				.then(function (response) {
					// console.log(JSON.stringify(response.data));
					Toast.fire({
						icon: 'success',
						title: 'Volt status updated'
					})
					dispatch("get_my_volt")
					EventBus.$emit("status-updated")
				})
				.catch(function (error) {
					EventBus.$emit("status-updated")
				});
		},
		update_volt_passcode({ commit }, payload) {
			
			var config = {
				method: "post",
				maxBodyLength: Infinity,
				url: "/api/qrdrive/vaultupdate/" + payload.uuid,
				headers: {
					"Content-Type": "application/json",
				},
				data: payload.data,
			}
			axios(config)
				.then(function (response) {
					// console.log(JSON.stringify(response.data));
					Toast.fire({
						icon: 'success',
						title: 'Vault passcode updated'
					})
					EventBus.$emit("passupdated")
				})
				.catch(function (error) {
					Toast.fire({
						icon: 'error',
						title: "Old passcode is incorrect"
					})
					EventBus.$emit("passupdated")
				});
		},
		get_my_volt({ commit }) {

			var myHeaders = new Headers();
			myHeaders.append("Content-Type", "multipart/form-data");



			var requestOptions = {
				method: 'GET',
				headers: myHeaders,
				redirect: 'follow'
			};

			fetch("/api/qrdrive/all", requestOptions)
				.then(response => response.text())
				.then(result => {
					commit('SET_MY_VOLT', JSON.parse(result))
				})
				.catch(error => {});
		},
		get_my_orders({ commit }) {

			var myHeaders = new Headers();
			myHeaders.append("Content-Type", "multipart/form-data");

			var requestOptions = {
				method: 'GET',
				headers: myHeaders,
				redirect: 'follow'
			};

			fetch("/api/qrdrive/ordervault", requestOptions)
				.then(response => response.text())
				.then(result => {
					// console.log(JSON.parse(result))
					commit('SET_MY_ORDER', JSON.parse(result).order)
				})
				.catch(error => {});
		},
		order_for_volt({ commit }, data) {
			var config = {
				method: 'post',
				maxBodyLength: Infinity,
				url: '/api/qrdrive/ordervault',
				headers: {
					'Content-Type': 'application/json',

				},
				data: data
			};

			axios(config)
				.then(function (response) {
					Toast.fire({
						icon: 'success',
						title: 'Purchase order created'
					})
					EventBus.$emit("ordered")
				})
				.catch(function (error) {
					Toast.fire({
						icon: 'error',
						title: error
					})
					EventBus.$emit("ordered")
				});


		},
		update_volt_details({ commit }, payload) {
			var config = {
				method: "post",
				maxBodyLength: Infinity,
				url: "/api/qrdrive/vaultupdate/" + payload.uuid,
				headers: {
					"Content-Type": "application/json",

				},
				data: payload.data,
			};

			axios(config)
				.then(function (response) {
					Toast.fire({
						icon: 'success',
						title: 'Volt info updated'
					})
					EventBus.$emit("details-updated")
				})
				.catch(function (error) {
					Toast.fire({
						icon: 'success',
						title: error
					})
					EventBus.$emit("details-updated")
				});
		}
	}
}
export default volt

