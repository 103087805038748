var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "icon icon-shape",
      class: [
        _vm.size && `icon-${_vm.size}`,
        _vm.type && `icon-shape-${_vm.type}`,
        _vm.gradient && `bg-gradient-${_vm.gradient}`,
        _vm.shadow && "shadow",
        _vm.rounded && "rounded-circle",
        _vm.color && `text-${_vm.color}`,
      ],
    },
    [
      _vm._t("default", function () {
        return [_c("i", { class: _vm.name })]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }