var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "transition",
    {
      attrs: {
        appear: "",
        name: _vm.transitionName,
        mode: _vm.transitionMode,
        "enter-active-class": _vm.transitionEnterActiveClass,
      },
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }