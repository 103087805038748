import "./bootstrap";
import "./event-bus";

import store from "./store";
import router from "./router";
import VueAnalytics from "vue-analytics";
import VueLoaders from "vue-loaders";

import VueCookies from "vue-cookies";

Vue.use(VueCookies);
import VueHead from "vue-head";
Vue.use(VueHead);
Vue.use(VueLoaders);
import VueSocialSharing from "vue-social-sharing";

Vue.use(VueSocialSharing);

Vue.use(VueAnalytics, {
  id() {
    return window.kmenv.gaid || "";
  },
  router,
  debug: {
    enabled: false, // default value
    trace: false, // default value
    sendHitTask: true, // default value
  },
});

const app = new Vue({
  el: "#root",
  store,
  router,
});
