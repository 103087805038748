import { v4 as uuid } from 'uuid'
import Vue from 'vue'

// Importing Core
import Core from '@core';
import './assets/customC.css';
import './assets/responsiveC.css';


import './service-worker/register'


// Binding few libraries for their global presence
window.Vue = Vue
window.uuid = uuid

Vue.use(Core)

// Setting up Vue Router
import './router/setup'

Vue.config.productionTip = false
Vue.config.silent = true
Vue.config.devtools = true
