
export default [
	{
		path: 'my-pages',
		name: 'commonlounge',
		redirect: { name: 'commonSocialLounge' },
		meta: {
			title: 'common.professionallounge',
			description: '',
		},
		// alias: '/pages',
		// component: () => import('@views/app/common/FirstCard')
		component: {
			template: '<router-view></router-view>'
		},
		children: [
			{
				path: 'my-page',
				name: 'commonSocialLounge',
				meta: [],
				component: () => import('@views/app/common/lounge/social/index')
			},
			{
				path: 'professional-lounge',
				name: 'commonProfessional',
				meta: [],
				component: () => import('@views/app/common/lounge/professional/index')
			},
			{
				path: 'news-feed',
				name: 'commonNewsFeed',
				meta: [],
				component: () => import('@views/app/common/lounge/newsfeed/index')
			},
			{
				path: 'promotions',
				name: 'commonLoungePromotions',
				meta: [],
				component: () => import('@views/app/common/lounge/promotions/index')
			},
			{
				path: 'circulars',
				name: 'commonCirculers',
				meta: [],
				component: () => import('@views/app/common/lounge/circulars/index')
			},
			{
				path: 'inbox',
				name: 'commonLoungeInbox',
				meta: [],
				component: () => import('@views/app/common/lounge/inbox/index')
			},
			{
				path: 'callender',
				name: 'commonCallender',
				meta: [],
				component: () => import('@views/app/common/lounge/callender/index')
			},
			{
				path: 'media',
				name: 'commonLoungeMedia',
				meta: [],
				component: () => import('@views/app/common/lounge/media/index')
			},
			{
				path: 'events',
				name: 'commonLoungeEvents',
				meta: [],
				component: () => import('@views/app/common/lounge/events/index')
			},
	// 		{
    //     path: "professional-page",
    //     name: "commonProfessionalPage1",
    //     meta: [],
    //     component: () =>
    //       import("@views/app/common/profile/professionalpage/index"),
    //   },
      {
        path: "social-page/:posttype/:id/:uuid",
        name: "commonSocialPageUser1",
        meta: [],
        component: () => import("@views/app/common/profile/socialpage/user"),
      },
      {
        path: "professional-page/:posttype/:id/:uuid",
        name: "commonProfessionalPage1",
        meta: [],
        component: () =>
          import("@views/app/common/profile/professionalpage/user"),
      },
      {
        path: "professional-friends",
        name: "commonProfessionalFriends1",
        meta: [],
        component: () =>
          import("@views/app/common/profile/professionalfriends/index"),
      },
      {
        path: "social-friends",
        name: "commonsocialFriends1",
        meta: [],
        component: () =>
          import("@views/app/common/profile/socialfriends/index"),
      },
		]

	},
]