export default [
	{
		path: 'cvideoconference',
		name: 'commonVideoconference',
		redirect: { name: 'commonVideoconferenceList' },
		meta: {
			title: 'common.videoconference',
			description: '',
		},
		// component: () => import('@views/app/common/FirstCard')
		component: {
			template: '<router-view></router-view>'
		},
		children: [{
			path: '',
			name: 'commonVideoconferenceList',
			meta: [],
			component: () => import('@views/app/common/videoConference/index')
		}]

	},
]