
import axios from 'axios';
import Swal from 'sweetalert2'
import EventBus from '../../event-bus';
import { Toast } from '../../mixins/swal'

const dp = {
	namespaced: true,
	state: {
        discount_partners: [],
        current_page: 1,
        partners_details: {},
        business_category: []

	},
	getters: {
		discount_partners: state => state.discount_partners,
		current_page: state => state.current_page,
		partners_details: state => state.partners_details,
		business_category: state => state.business_category,
	},

	mutations: {
		SET_DISCOUNT_PARTNER: (state, data = {}) => {
			state.discount_partners = data
		},
		SET_DISCOUNT_PARTNER_PAGINATED: (state, data = {}) => {
			state.discount_partners.push(...data)
		},
		SET_DISCOUNT_CURRENT_PAGE: (state, data = {}) => {
			state.current_page = data
		},
		SET_PARTNERS_DETAILS: (state, data = {}) => {
			state.partners_details = data
		},
		SET_BUSINESS_CATEGORY: (state, data = {}) => {
			state.business_category = data
		},
	},
	actions: {
	  async get_discount_partnets({commit}, {keyword = '', location = '', business_category='', create_by = '',  current_page = 1}) {
       
           let reqOptions = {
             url: "/api/discountpartner?create_by="+create_by+"&business_category="+business_category+"&location="+location+"&keyword="+keyword,
             method: "GET",
            
           }
           try {
               let response = await axios.request(reqOptions);
               if(response.status == 200) {
                   commit("SET_DISCOUNT_PARTNER", response.data.data)
                   commit("SET_DISCOUNT_CURRENT_PAGE", response.data.current_page)
                   EventBus.$emit("discount-partners-arrived")
               }
           } catch (error) {
              EventBus.$emit("discount-partners-arrived")
           }     
      },
	  async get_paginated_discount_partnets({commit}, {keyword = '', location = '', business_category='', current_page = 1}) {
      
           let reqOptions = {
             url: "/api/discountpartner?current_page="+current_page+"&business_category="+business_category+"&location="+location+"&keyword="+keyword,
             method: "GET",
             
           }
           try {
               let response = await axios.request(reqOptions);
               if(response.status == 200) {
                   commit("SET_DISCOUNT_PARTNER_PAGINATED", response.data.data)
                   commit("SET_DISCOUNT_CURRENT_PAGE", response.data.current_page)
                   EventBus.$emit("discount-partners-arrived")
               }
           } catch (error) {
              EventBus.$emit("discount-partners-arrived")
           }     
      },
	  async update_discount_partner({commit, dispatch}, {data, uuid}) {
       
            let reqOptions = {
                url: "/api/discountpartner/"+uuid,
                method: "POST",
               
                data,
            }
            try {
                let response = await axios.request(reqOptions);
                if(response.status == 200) {
                    dispatch("get_discount_partnets", {keyword:""})
                    EventBus.$emit("discount-partner-created")
                }
            } catch (error) {
                EventBus.$emit("discount-partner-created")
            }

      },
	  async create_discount_partner({commit, dispatch}, {data}) {
       
            let reqOptions = {
                url: "/api/discountpartner",
                method: "POST",
               
                data,
            }
            try {
                let response = await axios.request(reqOptions);
                if(response.status == 200) {
                    dispatch("get_discount_partnets", {keyword:""})
                    EventBus.$emit("discount-partner-created")
                }
            } catch (error) {
                EventBus.$emit("discount-partner-created")
            }

      },
      async get_partners_details({commit}, {uuid}) {
       
           
           let reqOptions = {
             url: "/api/discountpartner/"+uuid,
             method: "GET",
             
           }
           try {
               let response = await axios.request(reqOptions);
               if(response.status == 200) {
                   commit('SET_PARTNERS_DETAILS', response.data)
                   EventBus.$emit("partners-arrieved")

               }
           } catch (error) {
            EventBus.$emit("partners-arrieved")
           }
           
      },
      async delete_partners({commit, dispatch}, {uuid}) {
       
           
           let reqOptions = {
             url: "/api/discountpartner/"+uuid,
             method: "DELETE",
            
           }
           try {
               let response = await axios.request(reqOptions);
               if(response.status == 200) {
                dispatch("get_discount_partnets", {keyword:""})
                EventBus.$emit("pertners-deleted")
               }
           } catch (error) {
            EventBus.$emit("pertners-deleted")
           }
           
      },
      async get_business_category({commit}, keyword) {
       
        
        let reqOptions = {
          url: "/api/discountpartners/categories?keyword="+keyword,
          method: "GET",
         
        }
        try {
            let response = await axios.request(reqOptions);
            if(response.status == 200) {
                commit("SET_BUSINESS_CATEGORY", response.data.data)
                EventBus.$emit("searched-category")
            }
        } catch (error) {
            EventBus.$emit("searched-category")
        }
        
      }
	}
}
export default dp

