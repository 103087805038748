import axios from "axios";
import Swal from "sweetalert2";
import Eventbus from "../../event-bus";
import { Toast } from "../../mixins/swal";


const profile = {
  namespaced: true,
  state: {
    current_page: null,
    profile: {},
    webcards: [],
    promotions: [],
    webcard_microsites: [],
    sections: [],
    other_profile: [],
    pendinglist: [],
    contact_messages: [],
    contact_messages_current_page: 1,
    pendinglist: [],
    myaffiliate: [],
  },
  getters: {
    wallet_balance: state =>  state.profile.meta
                                ? state.profile.meta.hasOwnProperty("wallet_balance")
                                  ? state.profile.meta.wallet_balance
                                  : 0
                                : 0,
   affiliate_id: state =>  state.profile.meta
                                ? state.profile.meta.hasOwnProperty("affiliate_id")
                                  ? state.profile.meta.affiliate_id
                                  : 0
                                : 0,
    myaffiliate: state => state.myaffiliate,
    purchased_webcard: (state) => state.profile?.meta?.purchased_webcard,
    purchased_tabletop: (state) => state.profile?.meta?.purchased_tabletop,
    phone_number_status: (state) => state.profile?.meta?.phone_number_status,
    student_id_status: (state) => state.profile?.meta?.student_id_status,

    affiliate: (state) => state.profile?.meta?.affiliate,
    affiliate_type: (state) => state.profile?.meta?.affiliate_type,
    affiliate_id: (state) => state.profile?.meta?.affiliate_id,
    getpendinglist: (state) => state.pendinglist,
    // hasWEBCARD: state => state.profile ? state.profile.meta.hasOwnProperty('bcard_name') ? true : false : false,
    reg_affiliate_id: state => state.profile?.meta?.registration_affiliate_id,
    getsections: (state) => state.sections,
    contact_current_m_page: state => state.contact_current_m_page,
    get_contactmessage: state => state.contact_messages,
    getpendinglist: (state) => state.pendinglist,
    getcurrentpage: (state) => state.current_page,
    username: (state) => state.profile.username,
    user_id: (state) => state.profile.id,
    total: (state) => state.total,
    getprofile: (state) => state.profile,
    otherprofile: (state) => state.other_profile,
    uuid: (state) => state.profile.uuid,
    name: (state) => state.profile.name,
    email: (state) => state.profile.email,
    gender: (state) => state.profile.gender,
    nick_name: (state) =>
      state.profile.meta
        ? state.profile.meta.hasOwnProperty("nick_name")
          ? state.profile.meta.nick_name
          : ""
        : "",
   
    degrees: (state) =>
      state.profile.meta
        ? state.profile.meta.hasOwnProperty("degrees")
          ? state.profile.meta.degrees
          : ""
        : "",
    blood_group: (state) =>
      state.profile.meta
        ? state.profile.meta.hasOwnProperty("blood_group")
          ? state.profile.meta.blood_group
          : ""
        : "",
    bcard_name: (state) =>
      state.profile.meta
        ? state.profile.meta.hasOwnProperty("bcard_name")
          ? state.profile.meta.bcard_name
          : ""
        : "",
    additional_info: (state) =>
      state.profile.meta
        ? state.profile.meta.hasOwnProperty("additional_info")
          ? state.profile.meta.additional_info
          : ""
        : "",
    website_link: (state) =>
      state.profile.meta
        ? state.profile.meta.hasOwnProperty("website_link")
          ? state.profile.meta.website_link
          : ""
        : "",
    bcard_email: (state) =>
      state.profile.meta
        ? state.profile.meta.hasOwnProperty("bcard_email")
          ? state.profile.meta.bcard_email
          : ""
        : "",
    city: (state) =>
      state.profile.meta
        ? state.profile.meta.hasOwnProperty("city")
          ? state.profile.meta.city
          : ""
        : "",
    state: (state) =>
      state.profile.meta
        ? state.profile.meta.hasOwnProperty("state")
          ? state.profile.meta.city
          : ""
        : "",
    zip: (state) =>
      state.profile.meta
        ? state.profile.meta.hasOwnProperty("zip")
          ? state.profile.meta.zip
          : ""
        : "",
    resume: (state) =>
      state.profile.meta
        ? state.profile.meta.hasOwnProperty("resume")
          ? state.profile.meta.resume
          : ""
        : "",
    promotion_position: (state) =>
      state.profile.meta
        ? state.profile.meta.hasOwnProperty("promotion_position")
          ? state.profile.meta.promotion_position
          : ""
        : "",
    about_me: (state) =>
      state.profile.meta
        ? state.profile.meta.hasOwnProperty("about_me")
          ? state.profile.meta.about_me
          : ""
        : "",
    birth_date: (state) => state.profile.birth_date,
    // profession: (state) => state.profile?.meta?.profession,
      
    profession: (state) =>
      state.profile.meta
        ? state.profile.meta.hasOwnProperty("profession")
          ? state.profile.meta.profession
          : ""
        : "",
    bcard_photo: (state) =>
      state.profile.meta
        ? state.profile.meta.hasOwnProperty("bcard_photo")
          ? state.profile.meta.bcard_photo
          : ""
        : "",
    bg_image: (state) =>
      state.profile.meta
        ? state.profile.meta.hasOwnProperty("bg_image")
          ? state.profile.meta.bg_image
          : ""
        : "",
    bg_image_h: (state) =>
      state.profile.meta
        ? state.profile.meta.hasOwnProperty("bg_image_h")
          ? state.profile.meta.bg_image_h
          : ""
        : "",
    company_name: (state) =>
      state.profile.meta
        ? state.profile.meta.hasOwnProperty("company_name")
          ? state.profile.meta.company_name
          : ""
        : "",
    vw_companyname_color: (state) =>
      state.profile.meta
        ? state.profile.meta.hasOwnProperty("vw_companyname_color")
          ? state.profile.meta.vw_companyname_color
          : ""
        : "",
    hw_companyname_color: (state) =>
      state.profile.meta
        ? state.profile.meta.hasOwnProperty("hw_companyname_color")
          ? state.profile.meta.hw_companyname_color
          : ""
        : "",
    vw_show_additional_info: (state) =>
      state.profile.meta
        ? state.profile.meta.hasOwnProperty("vw_show_additional_info")
          ? state.profile.meta.vw_show_additional_info
          : ""
        : "",
    hw_show_additional_info: (state) =>
      state.profile.meta
        ? state.profile.meta.hasOwnProperty("hw_show_additional_info")
          ? state.profile.meta.hw_show_additional_info
          : ""
        : "",
    vw_show_website_link: (state) =>
      state.profile.meta
        ? state.profile.meta.hasOwnProperty("vw_show_website_link")
          ? state.profile.meta.vw_show_website_link
          : ""
        : "",
    hw_show_website_link: (state) =>
      state.profile.meta
        ? state.profile.meta.hasOwnProperty("hw_show_website_link")
          ? state.profile.meta.hw_show_website_link
          : ""
        : "",
    vw_additional_info_color: (state) =>
      state.profile.meta
        ? state.profile.meta.hasOwnProperty("vw_additional_info_color")
          ? state.profile.meta.vw_additional_info_color
          : ""
        : "",
    hw_additional_info_color: (state) =>
      state.profile.meta
        ? state.profile.meta.hasOwnProperty("hw_additional_info_color")
          ? state.profile.meta.hw_additional_info_color
          : ""
        : "",
    vw_website_link_color: (state) =>
      state.profile.meta
        ? state.profile.meta.hasOwnProperty("vw_website_link_color")
          ? state.profile.meta.vw_website_link_color
          : ""
        : "",
    hw_website_link_color: (state) =>
      state.profile.meta
        ? state.profile.meta.hasOwnProperty("hw_website_link_color")
          ? state.profile.meta.hw_website_link_color
          : ""
        : "",
    cover_image: (state) =>
      state.profile.meta
        ? state.profile.meta.hasOwnProperty("cover_image")
          ? state.profile.meta.cover_image
          : ""
        : "",
    current_location: (state) =>
      state.profile.meta
        ? state.profile.meta.hasOwnProperty("current_location")
          ? state.profile.meta.current_location
          : ""
        : "",
    facebook: (state) =>
      state.profile.meta
        ? state.profile.meta.hasOwnProperty("facebook")
          ? state.profile.meta.facebook
          : ""
        : "",
    instagram: (state) =>
      state.profile.meta
        ? state.profile.meta.hasOwnProperty("instagram")
          ? state.profile.meta.instagram
          : ""
        : "",
    linkedin: (state) =>
      state.profile.meta
        ? state.profile.meta.hasOwnProperty("linkedin")
          ? state.profile.meta.linkedin
          : ""
        : "",
    location_from: (state) =>
      state.profile.meta
        ? state.profile.meta.hasOwnProperty("location_from")
          ? state.profile.meta.location_from
          : ""
        : "",
    location_live: (state) =>
      state.profile.meta
        ? state.profile.meta.hasOwnProperty("location_live")
          ? state.profile.meta.location_live
          : ""
        : "",
    logo: (state) =>
      state.profile.meta
        ? state.profile.meta.hasOwnProperty("logo")
          ? state.profile.meta.logo
          : ""
        : "",
    mobile: (state) =>
      state.profile.meta
        ? state.profile.meta.hasOwnProperty("mobile")
          ? state.profile.meta.mobile
          : ""
        : "",
    phone: (state) =>
      state.profile.meta
        ? state.profile.meta.hasOwnProperty("phone")
          ? state.profile.meta.phone
          : ""
        : "",

    office_address: (state) =>
      state.profile.meta
        ? state.profile.meta.hasOwnProperty("office_address")
          ? state.profile.meta.office_address
          : ""
        : "",
    permanent_address: (state) =>
      state.profile.meta
        ? state.profile.meta.hasOwnProperty("permanent_address")
          ? state.profile.meta.permanent_address
          : ""
        : "",

    designation: (state) =>
      state.profile.meta
        ? state.profile.meta.hasOwnProperty("designation")
          ? state.profile.meta.designation
          : ""
        : "",
    bcard_designation: (state) =>
      state.profile.meta
        ? state.profile.meta.hasOwnProperty("bcard_designation")
          ? state.profile.meta.bcard_designation
          : ""
        : "",

    vw_blood_group_color: (state) =>
      state.profile.meta
        ? state.profile.meta.hasOwnProperty("vw_blood_group_color")
          ? state.profile.meta.vw_blood_group_color
          : ""
        : "",
    hw_blood_group_color: (state) =>
      state.profile.meta
        ? state.profile.meta.hasOwnProperty("hw_blood_group_color")
          ? state.profile.meta.hw_blood_group_color
          : ""
        : "",
    vw_degrees_color: (state) =>
      state.profile.meta
        ? state.profile.meta.hasOwnProperty("vw_degrees_color")
          ? state.profile.meta.vw_degrees_color
          : ""
        : "",
    hw_degrees_color: (state) =>
      state.profile.meta
        ? state.profile.meta.hasOwnProperty("hw_degrees_color")
          ? state.profile.meta.hw_degrees_color
          : ""
        : "",
    vw_designation_color: (state) =>
      state.profile.meta
        ? state.profile.meta.hasOwnProperty("vw_designation_color")
          ? state.profile.meta.vw_designation_color
          : ""
        : "",
    hw_designation_color: (state) =>
      state.profile.meta
        ? state.profile.meta.hasOwnProperty("hw_designation_color")
          ? state.profile.meta.hw_designation_color
          : ""
        : "",

    text_color: (state) =>
      state.profile.meta
        ? state.profile.meta.hasOwnProperty("text_color")
          ? state.profile.meta.text_color
          : ""
        : "",
    vw_email_color: (state) =>
      state.profile.meta
        ? state.profile.meta.hasOwnProperty("vw_email_color")
          ? state.profile.meta.vw_email_color
          : ""
        : "",
    hw_email_color: (state) =>
      state.profile.meta
        ? state.profile.meta.hasOwnProperty("hw_email_color")
          ? state.profile.meta.hw_email_color
          : ""
        : "",
    vw_phone_color: (state) =>
      state.profile.meta
        ? state.profile.meta.hasOwnProperty("vw_phone_color")
          ? state.profile.meta.vw_phone_color
          : ""
        : "",
    hw_phone_color: (state) =>
      state.profile.meta
        ? state.profile.meta.hasOwnProperty("hw_phone_color")
          ? state.profile.meta.hw_phone_color
          : ""
        : "",
    vw_address_color: (state) =>
      state.profile.meta
        ? state.profile.meta.hasOwnProperty("vw_address_color")
          ? state.profile.meta.vw_address_color
          : ""
        : "",
    hw_address_color: (state) =>
      state.profile.meta
        ? state.profile.meta.hasOwnProperty("hw_address_color")
          ? state.profile.meta.hw_address_color
          : ""
        : "",
    vw_name_color: (state) =>
      state.profile.meta
        ? state.profile.meta.hasOwnProperty("vw_name_color")
          ? state.profile.meta.vw_name_color
          : ""
        : "",
    hw_name_color: (state) =>
      state.profile.meta
        ? state.profile.meta.hasOwnProperty("hw_name_color")
          ? state.profile.meta.hw_name_color
          : ""
        : "",
    vw_bg_color: (state) =>
      state.profile.meta
        ? state.profile.meta.hasOwnProperty("vw_bg_color")
          ? state.profile.meta.vw_bg_color
          : ""
        : "",
    hw_bg_color: (state) =>
      state.profile.meta
        ? state.profile.meta.hasOwnProperty("hw_bg_color")
          ? state.profile.meta.hw_bg_color
          : ""
        : "",

    present_address: (state) =>
      state.profile.meta
        ? state.profile.meta.hasOwnProperty("present_address")
          ? state.profile.meta.present_address
          : ""
        : "",
    vw_show_address: (state) =>
      state.profile.meta
        ? state.profile.meta.hasOwnProperty("vw_show_address")
          ? state.profile.meta.vw_show_address
          : ""
        : "",
    hw_show_address: (state) =>
      state.profile.meta
        ? state.profile.meta.hasOwnProperty("hw_show_address")
          ? state.profile.meta.hw_show_address
          : ""
        : "",
    vw_show_blood_group: (state) =>
      state.profile.meta
        ? state.profile.meta.hasOwnProperty("vw_show_blood_group")
          ? state.profile.meta.vw_show_blood_group
          : ""
        : "",
    hw_show_blood_group: (state) =>
      state.profile.meta
        ? state.profile.meta.hasOwnProperty("hw_show_blood_group")
          ? state.profile.meta.hw_show_blood_group
          : ""
        : "",
    vw_show_degrees: (state) =>
      state.profile.meta
        ? state.profile.meta.hasOwnProperty("vw_show_degrees")
          ? state.profile.meta.vw_show_degrees
          : ""
        : "",
    hw_show_degrees: (state) =>
      state.profile.meta
        ? state.profile.meta.hasOwnProperty("hw_show_degrees")
          ? state.profile.meta.hw_show_degrees
          : ""
        : "",
    vw_show_avater: (state) =>
      state.profile.meta
        ? state.profile.meta.hasOwnProperty("vw_show_avater")
          ? state.profile.meta.vw_show_avater
          : ""
        : "",
    hw_show_avater: (state) =>
      state.profile.meta
        ? state.profile.meta.hasOwnProperty("hw_show_avater")
          ? state.profile.meta.hw_show_avater
          : ""
        : "",
    vw_show_name: (state) =>
      state.profile.meta
        ? state.profile.meta.hasOwnProperty("vw_show_name")
          ? state.profile.meta.vw_show_name
          : ""
        : "",
    hw_show_name: (state) =>
      state.profile.meta
        ? state.profile.meta.hasOwnProperty("hw_show_name")
          ? state.profile.meta.hw_show_name
          : ""
        : "",
    hw_show_phone: (state) =>
      state.profile.meta
        ? state.profile.meta.hasOwnProperty("hw_show_phone")
          ? state.profile.meta.hw_show_phone
          : ""
        : "",
    vw_show_phone: (state) =>
      state.profile.meta
        ? state.profile.meta.hasOwnProperty("vw_show_phone")
          ? state.profile.meta.vw_show_phone
          : ""
        : "",
    vw_show_logo: (state) =>
      state.profile.meta
        ? state.profile.meta.hasOwnProperty("vw_show_logo")
          ? state.profile.meta.vw_show_logo
          : ""
        : "",
    hw_show_logo: (state) =>
      state.profile.meta
        ? state.profile.meta.hasOwnProperty("hw_show_logo")
          ? state.profile.meta.hw_show_logo
          : ""
        : "",
    hw_show_companyname: (state) =>
      state.profile.meta
        ? state.profile.meta.hasOwnProperty("hw_show_companyname")
          ? state.profile.meta.hw_show_companyname
          : ""
        : "",
    vw_show_companyname: (state) =>
      state.profile.meta
        ? state.profile.meta.hasOwnProperty("vw_show_companyname")
          ? state.profile.meta.vw_show_companyname
          : "no"
        : "no",
    vw_show_designation: (state) =>
      state.profile.meta
        ? state.profile.meta.hasOwnProperty("vw_show_designation")
          ? state.profile.meta.vw_show_designation
          : "no"
        : "no",
    hw_show_designation: (state) =>
      state.profile.meta
        ? state.profile.meta.hasOwnProperty("hw_show_designation")
          ? state.profile.meta.hw_show_designation
          : "no"
        : "no",
    vw_show_email: (state) =>
      state.profile.meta
        ? state.profile.meta.hasOwnProperty("vw_show_email")
          ? state.profile.meta.vw_show_email
          : ""
        : "",
    hw_show_email: (state) =>
      state.profile.meta
        ? state.profile.meta.hasOwnProperty("hw_show_email")
          ? state.profile.meta.hw_show_email
          : ""
        : "",

    show_microsites: (state) =>
      state.profile.meta
        ? state.profile.meta.hasOwnProperty("show_microsites")
          ? state.profile.meta.show_microsites
          : ""
        : "",
    show_photo_gallery: (state) =>
      state.profile.meta
        ? state.profile.meta.hasOwnProperty("show_photo_gallery")
          ? state.profile.meta.show_photo_gallery
          : ""
        : "",
    show_video_gallery: (state) =>
      state.profile.meta
        ? state.profile.meta.hasOwnProperty("show_video_gallery")
          ? state.profile.meta.show_video_gallery
          : ""
        : "",
    show_email_profile: (state) =>
      state.profile.meta
        ? state.profile.meta.hasOwnProperty("show_email_profile")
          ? state.profile.meta.show_email_profile
          : ""
        : "",
    show_address_profile: (state) =>
      state.profile.meta
        ? state.profile.meta.hasOwnProperty("show_address_profile")
          ? state.profile.meta.show_address_profile
          : ""
        : "",
    show_designation_profile: (state) =>
      state.profile.meta
        ? state.profile.meta.hasOwnProperty("show_designation_profile")
          ? state.profile.meta.show_designation_profile
          : ""
        : "",
    show_about_me: (state) =>
      state.profile.meta
        ? state.profile.meta.hasOwnProperty("show_about_me")
          ? state.profile.meta.show_about_me
          : ""
        : "",
    show_website: (state) =>
      state.profile.meta
        ? state.profile.meta.hasOwnProperty("show_website")
          ? state.profile.meta.show_website
          : ""
        : "",
    show_resume: (state) =>
      state.profile.meta
        ? state.profile.meta.hasOwnProperty("show_resume")
          ? state.profile.meta.show_resume
          : ""
        : "",
    show_phone_profile: (state) =>
      state.profile.meta
        ? state.profile.meta.hasOwnProperty("show_phone_profile")
          ? state.profile.meta.show_phone_profile
          : ""
        : "",
    darkmode: (state) =>
      state.profile.meta
        ? state.profile.meta.hasOwnProperty("darkmode")
          ? state.profile.meta.darkmode
          : "no"
        : "no",

    resume: (state) =>
      state.profile.meta
        ? state.profile.meta.hasOwnProperty("resume")
          ? state.profile.meta.resume
          : ""
        : "",
    skype: (state) =>
      state.profile.meta
        ? state.profile.meta.hasOwnProperty("skype")
          ? state.profile.meta.skype
          : ""
        : "",
    theme_id: (state) =>
      state.profile.meta
        ? state.profile.meta.hasOwnProperty("theme_id")
          ? state.profile.meta.theme_id
          : ""
        : "",
    twitter: (state) =>
      state.profile.meta
        ? state.profile.meta.hasOwnProperty("twitter")
          ? state.profile.meta.twitter
          : ""
        : "",
    web_site: (state) =>
      state.profile.meta
        ? state.profile.meta.hasOwnProperty("web_site")
          ? state.profile.meta.web_site
          : ""
        : "",
    whatsapp: (state) =>
      state.profile.meta
        ? state.profile.meta.hasOwnProperty("whatsapp")
          ? state.profile.meta.whatsapp
          : ""
        : "",
    media: (state) => (state.profile.media ? state.profile.media : []),

    nid: (state) =>
      state.profile.meta
        ? state.profile.meta.hasOwnProperty("nid")
          ? state.profile.meta.nid
          : ""
        : "",
    nid_file: (state) =>
      state.profile.meta
        ? state.profile.meta.hasOwnProperty("nid_file")
          ? state.profile.meta.nid_file
          : ""
        : "",
    nid_file_status: (state) =>
      state.profile.meta
        ? state.profile.meta.hasOwnProperty("nid_file_status")
          ? state.profile.meta.nid_file_status
          : ""
        : "",

    passport: (state) =>
      state.profile.meta
        ? state.profile.meta.hasOwnProperty("passport")
          ? state.profile.meta.passport
          : ""
        : "",
    passport_file: (state) =>
      state.profile.meta
        ? state.profile.meta.hasOwnProperty("passport_file")
          ? state.profile.meta.passport_file
          : ""
        : "",
    passport_file_status: (state) =>
      state.profile.meta
        ? state.profile.meta.hasOwnProperty("passport_file_status")
          ? state.profile.meta.passport_file_status
          : ""
        : "",
    passport_validity: (state) =>
      state.profile.meta
        ? state.profile.meta.hasOwnProperty("passport_validity")
          ? state.profile.meta.passport_validity
          : ""
        : "",

    driver_license: (state) =>
      state.profile.meta
        ? state.profile.meta.hasOwnProperty("driver_license")
          ? state.profile.meta.driver_license
          : ""
        : "",
    driver_file: (state) =>
      state.profile.meta
        ? state.profile.meta.hasOwnProperty("driver_file")
          ? state.profile.meta.driver_file
          : ""
        : "",
    driver_file_status: (state) =>
      state.profile.meta
        ? state.profile.meta.hasOwnProperty("driver_file_status")
          ? state.profile.meta.driver_file_status
          : ""
        : "",
    driver_license_validity: (state) =>
      state.profile.meta
        ? state.profile.meta.hasOwnProperty("driver_license_validity")
          ? state.profile.meta.driver_license_validity
          : ""
        : "",

    other_id: (state) =>
      state.profile.meta
        ? state.profile.meta.hasOwnProperty("other_id")
          ? state.profile.meta.other_id
          : ""
        : "",
    other_file: (state) =>
      state.profile.meta
        ? state.profile.meta.hasOwnProperty("other_file")
          ? state.profile.meta.other_file
          : ""
        : "",
    other_file_status: (state) =>
      state.profile.meta
        ? state.profile.meta.hasOwnProperty("other_file_status")
          ? state.profile.meta.other_file_status
          : ""
        : "",
    other_id_validity: (state) =>
      state.profile.meta
        ? state.profile.meta.hasOwnProperty("other_id_validity")
          ? state.profile.meta.other_id_validity
          : ""
        : "",

    tax_identity: (state) =>
      state.profile.meta
        ? state.profile.meta.hasOwnProperty("tax_identity")
          ? state.profile.meta.tax_identity
          : ""
        : "",
    tax_file: (state) =>
      state.profile.meta
        ? state.profile.meta.hasOwnProperty("tax_file")
          ? state.profile.meta.tax_file
          : ""
        : "",
    tax_file_status: (state) =>
      state.profile.meta
        ? state.profile.meta.hasOwnProperty("tax_file_status")
          ? state.profile.meta.tax_file_status
          : ""
        : "",
    tax_identity_validity: (state) =>
      state.profile.meta
        ? state.profile.meta.hasOwnProperty("tax_identity_validity")
          ? state.profile.meta.tax_identity_validity
          : ""
        : "",

    banking_details: (state) =>
      state.profile.meta ? state.profile.meta.banking_details : null,
    bank_account_proof: (state) =>
      state.profile.meta ? state.profile.meta.bank_account_proof : null,
    bank_account_proof_status: (state) =>
      state.profile.meta ? state.profile.meta.bank_account_proof_status : null,
    signature: (state) =>
      state.profile.meta ? state.profile.meta.signature : null,

    address_proof: (state) =>
      state.profile.meta ? state.profile.meta.address_proof : null,
    address_proof_status: (state) =>
      state.profile.meta ? state.profile.meta.address_proof_status : null,

    promotion_position: (state) =>
      state.profile.meta ? state.profile.meta.promotion_position : "",

    webcards: (state) => state.webcards,

    getwebcardmicrosites: (state) => state.webcard_microsites,

    enable_call: (state) =>
      state.profile.meta
        ? state.profile.meta.hasOwnProperty("enable_call")
          ? state.profile.meta.enable_call
          : "yes"
        : "yes",
    enable_email: (state) =>
      state.profile.meta
        ? state.profile.meta.hasOwnProperty("enable_email")
          ? state.profile.meta.enable_email
          : "yes"
        : "yes",
    enable_payment: (state) =>
      state.profile.meta
        ? state.profile.meta.hasOwnProperty("enable_payment")
          ? state.profile.meta.enable_payment
          : "no"
        : "no",
    enable_contact: (state) =>
      state.profile.meta
        ? state.profile.meta.hasOwnProperty("enable_contact")
          ? state.profile.meta.enable_contact
          : "yes"
        : "yes",
    enable_appoinment: (state) =>
      state.profile.meta
        ? state.profile.meta.hasOwnProperty("enable_appoinment")
          ? state.profile.meta.enable_appoinment
          : "yes"
        : "yes",
    enable_save_phone: (state) =>
      state.profile.meta
        ? state.profile.meta.hasOwnProperty("enable_save_phone")
          ? state.profile.meta.enable_save_phone
          : "yes"
        : "yes",
  },
  mutations: {
    SET_CURRENT_PAGE: (state, data = {}) => {
      state.current_page = data;
    },
    SET_NUMBER_OF_WEBCARDS: (state, data = {}) => {
      state.total = data;
    },
    SET_PUBLIC_PROFILE: (state, data = {}) => {
      state.profile = data;
    },
    SET_PROFILE_WEBCARD: (state, data = {}) => {
      state.webcards = data;
    },
    SET_PROMOTION_WEBCARD: (state, data = {}) => {
      state.promotions = data;
    },
    SET_WEBCARD_MICROSITE: (state, data = {}) => {
      state.webcard_microsites = data;
    },
    SET_PROFILE_WEBCARD_PAGINATED: (state, data = {}) => {
      state.webcards.push(...data);
    },
    SET_PROFILE_SECTIONS: (state, data = {}) => {
      state.sections = data;
    },
    SET_PUBLIC_PROFILE_USER: (state, data = {}) => {
      state.other_profile = data;
    },
    SET_MICROSITE_CONTACT_MESSAGE: (state, data = {}) => {
      state.contact_messages = data;
    },
    SET_MICROSITE_CONTACT_CURRENT_PAGE: (state, data = {}) => {
      state.contact_messages_current_page = data;
    },
    SET_PENDING_LIST: (state, data = {}) => {
      state.pendinglist = data;
    },
    SET_PENDING_LIST: (state, data = {}) => {
      state.pendinglist = data;
    },
    SET_MY_AFFILIATE: (state, data = {}) => {
      state.myaffiliate = data;
    },
  },
  actions: {
    async my_affiliate({commit}, {affiliate_id, per_page = 5}) {
       let reqOptions = {
         url: "/api/auth/reffered_users?per_page="+per_page+"&affiliate_id="+affiliate_id,
         method: "GET",
       }
       try {
         let response = await axios.request(reqOptions);
         commit('SET_MY_AFFILIATE', response.data.data)
         Eventbus.$emit("arrieve-affiliated-user")
        } catch (error) {
         Eventbus.$emit("arrieve-affiliated-user")
         console.log(error)
       }
    },
    async approve_verify({commit, dispatch}, {data, uuid}) {
      let reqOptions = {
        url: "/api/auth/verify/"+uuid,
        method: "POST",
      
        data,
      }
      try {
        let response = await axios.request(reqOptions);
        if (response.status == 200 || response.status == 201) {
          dispatch("getPendingList")
          Toast.fire({
            icon: "success",
            title: "Updated",
          });
          Eventbus.$emit("approve-success")
        }
      } catch (error) {
        
      }
    },
    async create_contact_message({commit}, {data}) {
      let reqOptions = {
        url: "/api/common/micrositecontact",
        method: "POST",
        data
      }
      try {
        let response = await axios.request(reqOptions);
        Eventbus.$emit("message-sent")
      } catch (error) {
        Eventbus.$emit("message-sent")
      }
    },
    async delete_contact_message({commit, dispatch}, {uuid}) {      
      let reqOptions = {
        url: "/api/common/micrositecontact/"+uuid,
        method: "DELETE",
      }
      try {
        await axios.request(reqOptions);
        Toast.fire({
          icon: "success",
          title: "Updated",
        });
        dispatch("get_microsite_contact_message")
        Eventbus.$emit("contact-message-deleted")
      } catch (error) {
        Eventbus.$emit("contact-message-deleted")
      }
    },
    async get_microsite_contact_message({commit}, {microsite_id = '', user_id = '', contacted_by = '', keyword = ''}) {
       let reqOptions = {
         url: "https://icircles.app/api/common/micrositecontact?microsite_id="+microsite_id+"&user_id="+user_id+"&contacted_by="+contacted_by+"&keyword="+keyword,
         method: "GET",
       }
       try {
         let response = await axios.request(reqOptions);
         commit('SET_MICROSITE_CONTACT_MESSAGE', response.data.data)
         commit('SET_MICROSITE_CONTACT_CURRENT_PAGE', response.data.current_page)
         Eventbus.$emit("message-arrived")
       } catch (error) {
        Eventbus.$emit("message-arrived")
       }
    },
    async approve_verify({ commit, dispatch }, { data, uuid }) {
      let reqOptions = {
        url: "/api/auth/verify/" + uuid,
        method: "POST",
       
        data,
      };
      try {
        let response = await axios.request(reqOptions);
        if (response.status == 200 || response.status == 201) {
          dispatch("getPendingList");
          Toast.fire({
            icon: "success",
            title: "Updated",
          });
          Eventbus.$emit("approve-success");
        }
      } catch (error) {}
    },
    async getPendingList({ commit }) {
    

      let reqOptions = {
        url: "/api/auth/verification",
        method: "GET",
        headers: headersList,
      };
      try {
        let response = await axios.request(reqOptions);
        if (response.status == 200) {
          Eventbus.$emit("pending-list-got");
          commit("SET_PENDING_LIST", response.data.data);
        }
      } catch (error) {
        // console.log(error);
      }
    },
    async updateProfile({ commit, dispatch, getters, rootGetters }, { data, horizontal }) {
      const user_uuid = rootGetters['user/uuid'];
      var config = {
        method: "post",
        url: "/api/profile",
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data,
      };
      try {
        const res = await axios(config);
        if (res.status == 200) {
          dispatch("getPublicProfile", user_uuid);
          // dispatch("getUserProfile", user_uuid);
          Toast.fire({
            icon: "success",
            title: "Info updated",
          });
          Eventbus.$emit("updated");
          if (!horizontal) {
            Eventbus.$emit("update-verical-webcard");
          } else {
            Eventbus.$emit("update-horizontal-webcard");
          }
        }
      } catch (error) {
        Eventbus.$emit("updated");
        // console.log(error);
      }
    },
    async updateProfilePhone({ commit, dispatch, getters, rootGetters }, { data }) {
      const user_uuid = rootGetters['user/uuid'];
      var config = {
        method: "post",
        url: "/api/profile",
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data,
      };
      try {
        const res = await axios(config);
        if (res.status == 200) {
          dispatch("getPublicProfile", user_uuid);
          Eventbus.$emit("updated-phone");
        }
      } catch (error) {
        Eventbus.$emit("updated-phone");
      }
    },
    async updateProfilePhoneVarified({ commit, dispatch, getters, rootGetters }, { data }) {
      const user_uuid = rootGetters['user/uuid'];
      var config = {
        method: "post",
        url: "/api/profile",
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data,
      };
      try {
        const res = await axios(config);
        if (res.status == 200) {
          dispatch("getPublicProfile", user_uuid);
          Eventbus.$emit("updated-phone-varified");
        }
      } catch (error) {
        Eventbus.$emit("updated-phone-varified");
      }
    },

    
    async update_Profile({ commit, dispatch, getters }, { data }) {
      var config = {
        method: "post",
        url: "/api/profile",
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: data,
      };
      try {
        const res = await axios(config);
        if (res.status == 200) {
          dispatch("getPublicProfile", getters.uuid);
          Toast.fire({
            icon: "success",
            title: "Info updated",
          });
          Eventbus.$emit("updated");
        }
      } catch (error) {
        Eventbus.$emit("updated");
        // console.log(error);
      }
    },

    async getPublicProfile({ commit, dispatch }, uuid) {
      var config = {
        method: "get",
        url: "/api/myprofile/" + uuid,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      try {
        const res = await axios(config);
        if (res.status == 200) {
          dispatch("get_public_profile_for_sections", uuid);
          commit("SET_PUBLIC_PROFILE", res.data);
          Eventbus.$emit("got-public-profile")
        }
      } catch (error) {
        // console.log(error);
        Eventbus.$emit("got-public-profile")
      }
    },
    async getUserProfile({ commit, dispatch }, uuid) {
      var config = {
        method: "get",
        url: "/api/myprofile/" + uuid,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      try {
        const res = await axios(config);
        if (res.status == 200) {
          dispatch("get_public_profile_for_sections", uuid);
          commit("SET_PUBLIC_PROFILE_USER", res.data);
          Eventbus.$emit("got-profile")
        }
      } catch (error) {
        // console.log(error);
      }
    },

    async updateAvater({ commit, dispatch, getters }, data) {
        var config = {
        method: "post",
        url: "/api/profile/avatar",
        data,
      };
      try {
        const res = await axios(config);
        if (res.status == 200) {
          dispatch("getPublicProfile", getters.uuid);
          dispatch("user/GetUser", "", { root: true });
          // Toast.fire({
          //   icon: "success",
          //   title: "Profile avater updated",
          // });
          Eventbus.$emit("updatedbanneravater");
        }
      } catch (error) {
        // Toast.fire({
        //   icon: "error",
        //   title: error,
        // });
        Eventbus.$emit("updatedbanneravater");
      }
    },
    async updateBanner({ commit, dispatch, getters }, data) {
      var config = {
        method: "post",
        url: "/api/profile/avatar",
        data,
      };
      try {
        const res = await axios(config);
        if (res.status == 200) {
          dispatch("getPublicProfile", getters.uuid);
          dispatch("user/GetUser", "", { root: true });
          // Toast.fire({
          //   icon: "success",
          //   title: "Banner updated",
          // });
          Eventbus.$emit("updatedbanneravater");
        }
      } catch (error) {
        // Toast.fire({
        //   icon: "error",
        //   title: error,
        // });
        Eventbus.$emit("updatedbanneravater");
      }
    },

    async updateWebcardBackground({ commit, dispatch, getters }, data) {
      var config = {
        method: "post",
        url: "/api/auth/webcardbackground",
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: data,
      };
      try {
        const res = await axios(config);
        if (res.status == 200) {
          dispatch("getPublicProfile", getters.uuid);
          // Toast.fire({
          //   icon: "success",
          //   title: "Webcard background updated",
          // });
          // Eventbus.$emit("updated");
          Eventbus.$emit("updatedbanneravater");
        }
      } catch (error) {
        Eventbus.$emit("updated");
        Eventbus.$emit("updatedbanneravater");
        // console.log(error);
      }
    },

    async uoloadWebcardCompanyLogo({ commit, dispatch, getters }, data) {
      var config = {
        method: "post",
        url: "/api/auth/webcardcompanylogo",
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: data,
      };
      try {
        const res = await axios(config);
        if (res.status == 200) {
          dispatch("getPublicProfile", getters.uuid);
          // Toast.fire({
          //   icon: "success",
          //   title: "Webcard company logo updated",
          // });

          Eventbus.$emit("updatedbanneravater");
        }
      } catch (error) {
        Eventbus.$emit("updatedbanneravater");
      }
    },

    async removeProfileAvater({ commit, dispatch }) {
      var config = {
        method: "delete",
        url: "/api/profile/avatar",
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      try {
        const res = await axios(config);
        if (res.status == 200) {
          Toast.fire({
            icon: "error",
            title: "Profile avater deleted",
          });
          Eventbus.$emit("updated");
        }
      } catch (error) {
        // console.log(error);
      }
    },
    async uploadResume({ commit }, data) {
      var config = {
        method: "post",
        maxBodyLength: Infinity,
        url: "/api/profile/resume",
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: data,
      };
      try {
        const res = await axios(config);
        if (res.status == 200) {
          Toast.fire({
            icon: "success",
            title: "Resume updated",
          });
          Eventbus.$emit("updated");
        }
      } catch (error) {
        Toast.fire({
          icon: "error",
          title: error,
        });
      }
    },

    async removeResume({ commit }, data) {
      const config = {
        method: "delete",
        maxBodyLength: Infinity,
        url: "/api/profile/resume",
        data: data,
      };
      try {
        const res = await axios(config);
        if (res.status == 200) {
          Toast.fire({
            icon: "success",
            title: "Resume removed",
          });
          Eventbus.$emit("updated");
        }
      } catch (error) {
        Toast.fire({
          icon: "error",
          title: "Resume delete unsuccessful",
        });
      }
    },

    async get_public_profile_for_sections({ commit }, uuid) {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: "/api/publicprofile/" + uuid,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      try {
        const res = await axios(config);
        if (res.status == 200) {
          commit("SET_PROFILE_SECTIONS", res.data.profile_sections);
        }
      } catch (error) {
        // console.log(error);
      }
    },

    async addMediaToProfile({ commit, dispatch }, { uuid, data }) {
      var config = {
        method: "post",
        url: "/api/auth/profile/media/" + uuid,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: data,
      };
      try {
        const res = await axios(config);
        if (res.status == 200) {
          Toast.fire({
            icon: "success",
            title: "Media updates",
          });
          Eventbus.$emit("media-updated");
          dispatch("getPublicProfile", uuid);
        }
      } catch (error) {
        Toast.fire({
          icon: "error",
          title: error,
        });
        Eventbus.$emit("media-updated");
      }
    },
    async removeMediaFromProfile({ commit, dispatch }, { uuid, userUUID }) {
      const config = {
        method: "delete",
        maxBodyLength: Infinity,
        url: "/api/auth/profile/media/" + uuid,
      };
      try {
        const res = await axios(config);
        if (res.status == 200) {
          dispatch("getPublicProfile", userUUID);
          Toast.fire({
            icon: "error",
            title: "Media deleted from profile",
          });
          Eventbus.$emit("media-removed");
        }
      } catch (error) {
        Toast.fire({
          icon: "error",
          title: "There are some issue on the server",
        });
        Eventbus.$emit("media-removed");
      }
    },
    getPublicWebcards({ commit }, payload) {
      if (payload.microsites == "yes") {
        var config = {
          method: "get",
          url:
            "/api/getallwebcard?country_code=" +
            payload.country_code +
            "&microsites=yes&keyword=" +
            payload.query,
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };

        axios(config)
          .then(function (response) {
            commit("SET_CURRENT_PAGE", response.data.current_page);
            commit("SET_NUMBER_OF_WEBCARDS", response.data.total);
            commit("SET_WEBCARD_MICROSITE", response.data.data);
            Eventbus.$emit("iLoading");
          })
          .catch(function (error) {
            Eventbus.$emit("iLoading");
          });
      } else {
        var config = {
          method: "get",
          url:
            "/api/getallwebcard?country_code=" +
            payload.country_code +
            "&keyword=" +
            payload.query,
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };

        axios(config)
          .then(function (response) {
            commit("SET_CURRENT_PAGE", response.data.current_page);
            commit("SET_NUMBER_OF_WEBCARDS", response.data.total);
            commit("SET_PROFILE_WEBCARD", response.data.data);
            Eventbus.$emit("iLoading-complete");
          })
          .catch(function (error) {
            // console.log(error);
            Eventbus.$emit("iLoading-complete");
          });
      }
    },
    async getPublicWebcardsPromotion({ commit }, data) {
      var config = {
        method: "get",
        url: "/api/getallwebcard?microsite_id=yes&keyword=" + data,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      try {
        const res = await axios(config);
        if (res.sttaus == 200) {
          commit("SET_PROMOTION_WEBCARD", res.data.data);
        }
      } catch (error) {
        // console.log(error);
      }
    },
    async getPublicWebcardsPageinated({ commit }, data) {
      var config = {
        method: "get",
        url: "/api/getallwebcard?current_page=" + data,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      try {
        const res = await axios(config);
        if (res.status == 200) {
          commit("SET_CURRENT_PAGE", res.data.current_page);
          commit("SET_PROFILE_WEBCARD_PAGINATED", res.data.data);
          Eventbus.$emit("page-loader");
        }
      } catch (error) {
        // console.log(error);
      }
    },
    async create_section({ commit, dispatch, getters }, data) {
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: "/api/auth/profile/sections",
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: data,
      };
      try {
        const res = await axios(config);
        if (res.status == 200) {
          // dispatch("get_public_profile_for_sections", getters.uuid);
          Toast.fire({
            icon: "success",
            title: "Section created",
          });
          Eventbus.$emit("section-added");
        }
      } catch (error) {
        Toast.fire({
          icon: "error",
          title: "There are some issue on the server",
        });
        Eventbus.$emit("section-added");
      }
    },
    async deleteSection({ commit, dispatch, getters }, id) {
      let config = {
        method: "delete",
        maxBodyLength: Infinity,
        url: "/api/auth/profile/sections/" + id,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      try {
        const res = await axios(config);
        if (res.status == 200) {
          dispatch("get_public_profile_for_sections", getters.uuid);
          Toast.fire({
            icon: "error",
            title: "Section Deleted",
          });
          Eventbus.$emit("section-deleted");
        }
      } catch (error) {
        Toast.fire({
          icon: "error",
          title: "Server error",
        });
        Eventbus.$emit("section-deleted");
      }
    },
    async deletesectionContent({ commit, dispatch, getters }, id) {
      let config = {
        method: "delete",
        maxBodyLength: Infinity,
        url: "/api/auth/profile/sectionscontent/" + id,
        headers: {},
      };
      try {
        const res = await axios.request(config);
        if (res.status == 200) {
          dispatch("get_public_profile_for_sections", getters.uuid);
          Toast.fire({
            icon: "error",
            title: "Section content deleted",
          });
          Eventbus.$emit("section_content_removed");
        }
      } catch (error) {
        Toast.fire({
          icon: "error",
          title: error,
        });
        Eventbus.$emit("section_content_removed");
      }
    },
    async update_section({ commit, dispatch, getters }, { data, id }) {
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: "/api/auth/profile/sections/" + id,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: data,
      };
      try {
        const res = await axios(config);
        if (res.status == 200) {
          dispatch("get_public_profile_for_sections", getters.uuid);
          Toast.fire({
            icon: "Success",
            title: "Section updated",
          });
          Eventbus.$emit("section-created");
        }
      } catch (error) {
        Toast.fire({
          icon: "error",
          title: "Server error",
        });
        Eventbus.$emit("section-created");
      }
    },
    async create_section_content({ commit, dispatch, getters }, data) {
      try {
        let config = {
          method: "post",
          maxBodyLength: Infinity,
          url: "https://icircles.app/api/auth/profile/sectionscontent",
         
          data: data,
        };

        const res = await axios.request(config);
        if (res.status == 200) {
          dispatch("get_public_profile_for_sections", getters.uuid);
          Toast.fire({
            icon: "success",
            title: "Section content created",
          });
          Eventbus.$emit("section-content-added");
        }
      } catch (error) {
        Toast.fire({
          icon: "error",
          title: error,
        });
        Eventbus.$emit("section-content-added");
      }
    },
    async update_section_content({ commit, dispatch, getters }, { data, id }) {
      try {
        let config = {
          method: "post",
          maxBodyLength: Infinity,
          url: "/api/auth/profile/sectionscontent/" + id,
          headers: {
            "Content-Type": "multipart/form-data",
          },
          data: data,
        };
        const res = await axios(config);
        if (res.status == 200) {
          dispatch("get_public_profile_for_sections", getters.uuid);
          Toast.fire({
            icon: "success",
            title: "Section content updated",
          });
          Eventbus.$emit("sectioncontent-updated");
        }
      } catch (error) {
        Toast.fire({
          icon: "error",
          title: error,
        });
        Eventbus.$emit("sectioncontent-updated");
      }
    },
  },
};
export default profile;
