import request from '@js/core/utils/request';
const apiUrl = '/madmin';


export function getMicrositeCategory() {
	return request({
		url: apiUrl + '/micrositecategory',
		method: 'get'
	})
}
export function getMicrositeSubCategory() {
	return request({
		url: apiUrl + '/micrositesubcategory',
		method: 'get'
	})
}




