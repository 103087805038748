var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "input-slider-container" }, [
    _c("div", {
      ref: "slider",
      staticClass: "input-slider",
      class: [`slider-${_vm.type}`],
      attrs: { disabled: _vm.disabled },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }