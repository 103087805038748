export default [
  {
    path: "cprofile",
    name: "commonProfile",
    redirect: { name: "commonSocialPage" },
    meta: {
      title: "common.profile",
      description: "",
    },
    component: {
      template: "<router-view></router-view>",
    },
    children: [
      {
        path: "browse-strangers",
        name: "commonBrowseStrangers",
        meta: [],
        component: () =>
          import("@views/app/common/profile/browsestrangers/index"),
      },
      {
        path: "users",
        name: "commonUsers",
        meta: [],
        component: () =>
          import("@views/app/common/profile/users/index"),
      },
      {
        path: "inbox",
        name: "commonPersonalInbox",
        meta: [],
        component: () => import("@views/app/common/profile/inbox/index"),
      },
      {
        path: "inbox/:uuid",
        name: "commonSingleInbox",
        meta: [],
        component: () => import("@views/app/common/profile/inbox/Message"),
      },
      // {
      //   path: "affiliate",
      //   name: "commonWebcardAfficiate",
      //   meta: [],
      //   component: () => import("@views/app/common/affiliate/Index"),
      // },
      {
        path: "profile-settings",
        name: "commonProfileSettings",
        meta: [],
        component: () =>
          import("@views/app/common/profile/profilesettings/profile/viewV2"),
      },
      {
        path: "personal-profile",
        name: "commonPersonal",
        meta: [],
        component: () => import("@views/app/common/profile/personal/index"),
      },
      {
        path: "microsites-profile",
        name: "commonMicrositesProfile",
        meta: [],
        component: () =>
          import("@views/app/common/profile/micrositesprofile/index"),
      },
      {
        path: "medical-profile",
        name: "commonMeedicalProfile",
        meta: [],
        component: () =>
          import("@views/app/common/profile/medicalprofile/index"),
      },
      {
        path: "media-library",
        name: "commonMediaLibrary",
        meta: [],
        component: () => import("@views/app/common/profile/medialibrary/index"),
      },
      {
        path: "vault",
        name: "commonVault",
        meta: [],
        component: () => import("@views/app/common/profile/vault/index"),
      },
      {
        path: "vault/:uuid",
        name: "commonVaultDetails",
        meta: [],
        component: () => import("@views/app/common/profile/vault/details"),
      },
      {
        path: "nominee/:uuid",
        name: "commonVaultNomineeDetails",
        meta: [],
        component: () => import("@views/app/common/profile/vault/NomineeDetails"),
      },
      {
        path: "vault-myorders",
        name: "commonVaultMyOrders",
        meta: [],
        component: () => import("@views/app/common/profile/vault/myorders"),
      },
      {
        path: "vault-order",
        name: "commonVaultOrder",
        meta: [],
        component: () => import("@views/app/common/profile/vault/order"),
      },
      {
        path: "edit-vault/:id",
        name: "commonVaultEditInfo",
        meta: [],
        component: () => import("@views/app/common/profile/vault/edit"),
      },
      {
        path: "edit-vault-passcode/:id",
        name: "commonVaultEditPasscode",
        meta: [],
        component: () => import("@views/app/common/profile/vault/editpass"),
      },
      {
        path: "vault-validity-update/:id",
        name: "commonVaultEditValidity",
        meta: [],
        component: () =>
          import("@views/app/common/profile/vault/validityUpdate"),
      },

      {
        path: "social-page",
        name: "commonSocialPage",
        meta: [],
        component: () => import("@views/app/common/profile/socialpage/index"),
      },
     
      {
        path: "affiliate",
        name: "commonAffiliate",
        meta: [],
        component: () =>
          import("@views/app/common/affiliate/index"),
      },
      {
        path: "become-affiliate",
        name: "commonAffiliateBecome",
        meta: [],
        component: () =>
          import("@views/app/common/affiliate/BecomeAffiliate"),
      },
      {
        path: "affiliate-general",
        name: "commonAffiliateGeneral",
        meta: [],
        component: () =>
          import("@views/app/common/affiliate/general/index"),
      },
      {
        path: "affiliate-student",
        name: "commonAffiliateStudent",
        meta: [],
        component: () =>
          import("@views/app/common/affiliate/student/index"),
      },
      {
        path: "affiliate/:affiliate_id",
        name: "commonMyAffiliate",
        meta: [],
        component: () =>
          import("@views/app/common/affiliate/myaffiliate/index"),
      },
      // {
      //   path: "professional-page",
      //   name: "commonProfessionalPage",
      //   meta: [],
      //   component: () =>
      //     import("@views/app/common/profile/professionalpage/index"),
      // },
      // {
      //   path: "social-page/:posttype/:id/:uuid",
      //   name: "commonSocialPageUser",
      //   meta: [],
      //   component: () => import("@views/app/common/profile/socialpage/user"),
      // },
      // {
      //   path: "professional-page/:posttype/:id/:uuid",
      //   name: "commonProfessionalPage",
      //   meta: [],
      //   component: () =>
      //     import("@views/app/common/profile/professionalpage/user"),
      // },
      // {
      //   path: "professional-friends",
      //   name: "commonProfessionalFriends",
      //   meta: [],
      //   component: () =>
      //     import("@views/app/common/profile/professionalfriends/index"),
      // },
      // {
      //   path: "social-friends",
      //   name: "commonsocialFriends",
      //   meta: [],
      //   component: () =>
      //     import("@views/app/common/profile/socialfriends/index"),
      // },
      {
        path: "groups",
        name: "commonGroups",
        meta: [],
        component: () =>
          import("@views/app/common/profile/profilegroups/index"),
      },
      {
        path: "mental-health",
        name: "commonMentalHealts",
        meta: [],
        component: () => import("@views/app/common/profile/mentalhealth/index"),
      },
      {
        path: "games",
        name: "commonGames",
        meta: [],
        component: () => import("@views/app/common/profile/games/index"),
      },
      {
        path: "events",
        name: "commonEvents",
        meta: [],
        component: () => import("@views/app/common/profile/events/index"),
      },
      {
        path: "life-line",
        name: "commonLifeLine",
        meta: [],
        component: () => import("@views/app/common/profile/lifeline/index"),
      },
      {
        path: "family-tree",
        name: "commonFamilyTree",
        meta: [],
        component: () => import("@views/app/common/profile/familytree/index"),
      },
      {
        path: "self-education",
        name: "commonSelfEducation",
        meta: [],
        component: () =>
          import("@views/app/common/profile/selfeducation/index"),
      },
      {
        path: "education-glove",
        name: "commonEducationGlove",
        meta: [],
        component: () =>
          import("@views/app/common/profile/educationglove/index"),
      },
      {
        path: "profile-verifications",
        name: "commonProfileVerifications",
        meta: [],
        component: () => import("@views/app/common/profile/verify/index"),
      },
      {
        path: "profile-initial-setup",
        name: "commonProfileInitialSetup",
        meta: [],
        component: () => import("@views/app/common/profile/initial/index"),
      },
    ],
  },
];
