export default [
	{
		path: 'cprofessionallounge',
		name: 'commonProffessionallounge',
		redirect: { name: 'commonProfessionalDashboard' },
		meta: {
			title: 'common.professionallounge',
			description: '',
		},
		// component: () => import('@views/app/common/FirstCard')
		component: {
			template: '<router-view></router-view>'
		},
		children: [
	
			{
				path: 'professional-dashboard',
				name: 'commonProfessionalDashboard',
				meta: [],
				component: () => import('@views/app/common/professionalLounge/dashboard/index')
			},
		]

	},
]