var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-input",
    {
      attrs: {
        label: _vm.label,
        "addon-right-icon": "far fa-calendar-alt",
        type: "text",
        name: _vm.name,
        "is-wrapper": true,
        required: _vm.required,
      },
      model: {
        value: _vm.bdbValue,
        callback: function ($$v) {
          _vm.bdbValue = $$v
        },
        expression: "bdbValue",
      },
    },
    [
      _c("date-picker", {
        class: ["form-control datepicker", _vm.inputClasses],
        attrs: {
          config: _vm.vars.datepickerRangeConfig,
          disabled: _vm.disabled,
        },
        model: {
          value: _vm.bdbValue,
          callback: function ($$v) {
            _vm.bdbValue = $$v
          },
          expression: "bdbValue",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }