export default [
  {
    path: "cclassified",
    name: "commonClassified",
    redirect: { name: "commonBrowseAdds" },
    meta: {
      title: "common.classified",
      description: "",
      icon: "fas fa-user",
    },
    // component: () => import('@views/app/common/FirstCard')
    component: {
      template: "<router-view></router-view>",
    },
    children: [
      {
        path: "browse-adds",
        name: "commonBrowseAdds",
        component: () =>
          import(
            "@views/app/common/classified/browseadds/index" /* webpackChunkName: "app/activity/index" */
          ),
      },
      {
        path: "classified-post-add",
        name: "commonClassifiedPostAdd",
        component: () =>
          import(
            "@views/app/common/classified/browseadds/AddPost" /* webpackChunkName: "app/activity/index" */
          ),
      },
      {
        path: "classified-post-edit/:id",
        name: "commonClassifiedPostEdit",
        component: () =>
          import(
            "@views/app/common/classified/browseadds/EditPost" /* webpackChunkName: "app/activity/index" */
          ),
      },
      {
        path: "my-adds",
        name: "commonMyAdds",
        component: () =>
          import(
            "@views/app/common/classified/myadds/index" /* webpackChunkName: "app/activity/index" */
          ),
      },
      {
        path: "my-adds-details/:id",
        name: "commonMyAddsDetails",
        component: () =>
          import(
            "@views/app/common/classified/browseadds/DetailsPost" /* webpackChunkName: "app/activity/index" */
          ),
      },
      {
        path: "promotions",
        name: "commonPromotions",
        component: () =>
          import(
            "@views/app/common/classified/promotions/index" /* webpackChunkName: "app/activity/index" */
          ),
      },
      {
        path: "sales-report",
        name: "commonSalesReport",
        component: () =>
          import(
            "@views/app/common/classified/salesreport/index" /* webpackChunkName: "app/activity/index" */
          ),
      },
      {
        path: "classified-category",
        name: "commonClassifiedCategory",
        component: () =>
          import(
            "@views/app/common/classified/category/index" /* webpackChunkName: "app/activity/index" */
          ),
      },
      {
        path: "classified-category-add",
        name: "commonClassifiedCategoryAdd",
        component: () =>
          import(
            "@views/app/common/classified/category/addCategory" /* webpackChunkName: "app/activity/index" */
          ),
      },
      {
        path: "classified-orders",
        name: "commonClassifiedOrders",
        component: () =>
          import(
            "@views/app/common/classified/order/index" /* webpackChunkName: "app/activity/index" */
          ),
      },
      {
        path: "classified-order-details",
        name: "commonClassifiedDetailsAfter",
        component: () =>
          import(
            "@views/app/common/classified/order/OrderDetailsAfterCreate" /* webpackChunkName: "app/activity/index" */
          ),
      },
      {
        path: "create-classified-purchase/:id",
        name: "commonClassifiedOrdersCreate",
        component: () =>
          import(
            "@views/app/common/classified/order/CreateOrder" /* webpackChunkName: "app/activity/index" */
          ),
      },
      {
        path: "classified-orders-single/:id",
        name: "commonClassifiedOrdersSingle",
        component: () =>
          import(
            "@views/app/common/classified/order/Orderdetails" /* webpackChunkName: "app/activity/index" */
          ),
      },
      {
        path: "classified-orders-single-product/:id",
        name: "commonClassifiedOrdersSingleProduct",
        component: () =>
          import(
            "@views/app/common/classified/order/SingleOrder" /* webpackChunkName: "app/activity/index" */
          ),
      },
      {
        path: "classified-category-edit/:id",
        name: "commonClassifiedCategoryEdit",
        component: () =>
          import(
            "@views/app/common/classified/category/categoryEdit" /* webpackChunkName: "app/activity/index" */
          ),
      },
      {
        path: "classified-category-products/:id",
        name: "commonClassifiedSingleProduct",
        component: () =>
          import(
            "@views/app/common/classified/browseadds/categoryProducts" /* webpackChunkName: "app/activity/index" */
          ),
      },
    ],
  },
];
