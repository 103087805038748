import axios from "axios";
import Swal from "sweetalert2";
import Eventbus from "../../event-bus";
// import { Toast } from "../../mixins/swal";
// import WorkerPP from "worker-loader!../../web-worker/profile/get-public-profile.js"


const matromony = {
  namespaced: true,
  state: {
    matromony_current_page: 1,
    matromony_profile: [],
    my_matrimony_profile: {},
    my_profile: [],
    profile_details: [],
   
  },
  getters: {
    g_matromony_current_page: (state) => state.matromony_current_page,
    g_matromony_profiles: (state) => state.matromony_profile,
    g_my_matrimony_profile: (state) => state.my_matrimony_profile,
    g_my_profile: (state) => state.my_profile,
    g_profile_details: (state) => state.profile_details,



  },
  mutations: {
    SET_MATRIMONY_PROFILE: (state, data = {}) => {
      state.matromony_profile = data;
    },
    SET_MATRIMONY_PROFILE_PAGINATED: (state, data = {}) => {
      state.matromony_profile.push(...data)
    },
    SET_MATRIMONY_CURRENT_PAGE: (state, data = {}) => {
      state.matromony_current_page = data;
    },
    SET_MY_MATRIMONY_PROFILE: (state, data = {}) => {
      state.my_matrimony_profile = data;
    },
    SET_MY_PROFILE: (state, data = {}) => {
      state.my_profile = data;
    },
    SET_PROFILE_DETAILS: (state, data = {}) => {
      state.profile_details = data;
    },
   
  },
  actions: {
    async delete_profile({commit, dispatch, rootGetters}, {uuid}) {
      const user_id = rootGetters['profile/user_id']
      let config = {
        url: "/api/matrimony/"+uuid,
        method: "DELETE",
       
      }
      try {
         const res = await axios(config)
         if(res.status == 200) {
          Eventbus.$emit("profile-deleted")
            dispatch("check_my_profile", {user_id})
         }
      } catch (error) {
        Eventbus.$emit("profile-not-deleted")
      }
    },
    async profile_details({commit}, {user_id}) {
        let config = {
            url: `/api/matrimony?user_id=${user_id}`,
            method: "GET",
        }
        try {
            const res = await axios(config)
            if(res.status == 200) {
               commit("SET_PROFILE_DETAILS", res.data.data)
               Eventbus.$emit("details-arrieved")
            }
        } catch (error) {
            Eventbus.$emit("details-arrieved")
        }
    },
    async check_my_profile({commit}, {user_id}) {
        let config = {
            url: `/api/matrimony?user_id=${user_id}`,
            method: "GET",
          }
          try {
            const res = await axios(config)
            if(res.status == 200) {
              console.log("check my profile"+res.data)
               commit("SET_MY_PROFILE", res.data.data)
            }
          } catch (error) {
            
          }
    },
    async my_matrimony_profile({commit}, {uuid}) {
      let config = {
        url: `/api/matrimony/${uuid}`,
        method: "GET",
      }
      try {
        const res = await axios(config)
        console.log("my matrimony profile:-", res)
        commit('SET_MY_MATRIMONY_PROFILE', res.data)
      } catch (error) {
        
      }
    },
    async update_profile({commit, dispatch, rootGetters}, {data, uuid}) {
      const user_id = rootGetters["profile/user_id"]
      let reqOptions = {
        url: "/api/matrimony/"+uuid,
        method: "POST",
        data
      }
      try {
        const res = await axios(reqOptions)
        if (res.status == 201 || res.status == 200) {
          dispatch("get_profiles", {gender:'', keyword: ''})
          dispatch("check_my_profile", {user_id:user_id})
          Eventbus.$emit("marprofile-created")
        }
      } catch (error) {
       
        Eventbus.$emit("marprofile-created")
      }
    },
    async create_profile({commit, dispatch, rootGetters}, {data}) {
      const user_id = rootGetters["profile/user_id"]
      let reqOptions = {
        url: "/api/matrimony",
        method: "POST",
        data
      }
      try {
        const res = await axios(reqOptions)
        if (res.status == 201 || res.status == 200) {
          dispatch("get_profiles", {gender:'', keyword: ''})
          dispatch("check_my_profile", {user_id:user_id})
          Eventbus.$emit("marprofile-created")
        }
      } catch (error) {
       
        Eventbus.$emit("marprofile-created")
      }
    },
    async get_profiles({commit}, {gender,current_page, keyword}) {
      let reqOptions = {
        url: `/api/matrimony?current_page=${current_page}&per_page=30&keyword=${keyword}`,
        // url: `https://icircles.app/api/matrimony?current_page=1&per_page=30&gender=${gender}&keyword=${keyword}`,
        method: "GET",
       
      }
      try {
        let response = await axios.request(reqOptions);
        if (response.status == 200 || response.status == 201) {
          console
          commit("SET_MATRIMONY_PROFILE", response.data.data)
          commit("SET_MATRIMONY_CURRENT_PAGE", response.data.current_page)
          Eventbus.$emit("mat-profile-arrieved")
        }
      } catch (error) {
        
      }
    },
    async paginated_profiles({commit}, {gender,current_page, keyword = ''}) {
      let reqOptions = {
        url: `/api/matrimony?current_page=${current_page}&per_page=30&keyword=${keyword}`,
        // url: `https://icircles.app/api/matrimony?current_page=1&per_page=30&gender=${gender}&keyword=${keyword}`,
        method: "GET",
       
      }
      try {
        let response = await axios.request(reqOptions);
        if (response.status == 200 || response.status == 201) {
          console
          commit("SET_MATRIMONY_PROFILE_PAGINATED", response.data.data)
          commit("SET_MATRIMONY_CURRENT_PAGE", response.data.current_page)
          Eventbus.$emit("mat-profile-arrieved")
        }
      } catch (error) {
        
      }
    },
    async search_profiles({commit}, {gender, keyword}) {
      let reqOptions = {
        url: `/api/matrimony?keyword=${keyword}`,
        method: "GET",
      }
      try {
        let response = await axios.request(reqOptions);
        if (response.status == 200 || response.status == 201) {
          console
          commit("SET_MATRIMONY_PROFILE", response.data.data)
          commit("SET_MATRIMONY_CURRENT_PAGE", response.data.current_page)
          Eventbus.$emit("mat-profile-arrieved")
        }
      } catch (error) {
        
      }
    },
 
    
  },
};
export default matromony;
