export default [
	{
		path: 'ctools',
		name: 'commonTools',
		redirect: { name: 'commonWebcardGenerator' },
		meta: {
			title: 'common.tools',
			description: '',
		},
		// component: () => import('@views/app/common/FirstCard')
		component: {
			template: '<router-view></router-view>'
		},
		children: [
			{
				path: 'webcard-generator',
				name: 'commonWebcardGenerator',
				meta: [],
				component: () => import('@views/app/common/tools/webcardgenerator/index')
			},
			{
				path: 'vault-generator',
				name: 'commonVaultGenerator',
				meta: [],
				component: () => import('@views/app/common/tools/vaultgenerator/index')
			},
			{
				path: 'promotional-matrials',
				name: 'commonPromotionalMatrials',
				meta: [],
				component: () => import('@views/app/common/tools/promotionalmatrials/index')
			},
		]
	},
]