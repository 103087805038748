export default [
	{
		path: 'ctransport',
		name: 'commonTimeMoneyEmotions',
		redirect: { name: 'commonGetTransports' },
		meta: {
			title: 'common.Transport',
			description: '',
		},
		// component: () => import('@views/app/common/FirstCard')
		component: {
			template: '<router-view></router-view>'
		},
		children: [
			{
				path: 'get-transports',
				name: 'commonGetTransports',
				meta: [],
				component: () => import('@views/app/common/transport/gettransport/index')
			},
			{
				path: 'get-passengers',
				name: 'commonPassengers',
				meta: [],
				component: () => import('@views/app/common/transport/getpassengers/index')
			},
			{
				path: 'get-career',
				name: 'commonGetCareer',
				meta: [],
				component: () => import('@views/app/common/transport/getcareer/index')
			},
			{
				path: 'transfer-goods',
				name: 'commonTransferGoods',
				meta: [],
				component: () => import('@views/app/common/transport/transfergoods/index')
			},
			{
				path: 'my-driver-profile',
				name: 'commonMyDriverProfile',
				meta: [],
				component: () => import('@views/app/common/transport/mydriverprofile/index')
			},
			{
				path: 'my-transport-profile',
				name: 'commonMyTransportProfile',
				meta: [],
				component: () => import('@views/app/common/transport/mytransportprofile/index')
			},
			{
				path: 'reports',
				name: 'commonReports',
				meta: [],
				component: () => import('@views/app/common/transport/reports/index')
			},
		]
	},
]