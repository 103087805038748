export default [
	{
		path: 'cmedia',
		name: 'commonMedia',
		redirect: {name: 'commonMediaDashboard'},
		meta: {
			title: 'common.media',
			description: '',
		},
		// component: () => import('@views/app/common/FirstCard')
		component: {
			template: '<router-view></router-view>'
		},
		children: [
			
			{
				path: 'media-dashboard',
				name: 'commonMediaDashboard',
				meta: [],
				component: () => import('@views/app/common/media/dashboard/index')
			},
			{
				path: 'recent-media',
				name: 'commonMediaRecent',
				meta: [],
				component: () => import('@views/app/common/media/recent/index')
			},
			{
				path: 'media-photos',
				name: 'commonMediaPhotos',
				meta: [],
				component: () => import('@views/app/common/media/photos/index')
			},
			{
				path: 'media-videos',
				name: 'commonMediaVideos',
				meta: [],
				component: () => import('@views/app/common/media/videos/index')
			},
			{
				path: 'media-document',
				name: 'commonMediaRecycleBin',
				meta: [],
				component: () => import('@views/app/common/media/document/index')
			},
			{
				path: 'folder/:name/:index',
				name: 'commonMediaDiggingDeeper',
				meta: [],
				component: () => import('@views/app/common/media/folder/index')
			},
		]
	}	
]