import axios from "axios";
import Swal from "sweetalert2";
import { Toast } from "../../mixins/swal";
import EventBus from "../../event-bus";

const jobs = {
  namespaced: true,
  state: {
    job_categories: [],
    job: [],
    jobs_details: {},
  },
  getters: {
    getjobcategories: (state) => state.job_categories,
    getjobs: (state) => state.jobs,
    getjobdetails: (state) => state.jobs_details,
  },
  mutations: {
    SET_JOB_CATEGORY: (state, data = {}) => {
      state.job_categories = data;
    },
    SET_JOBS: (state, data = {}) => {
      state.jobs = data;
    },
    SET_JOB_DETAILS: (state, data = {}) => {
      state.jobs_details = data;
    },
  },
  actions: {
    async delete_my_jobs({ commit, dispatch }, uuid) {
      let config = {
        method: "delete",
        maxBodyLength: Infinity,
        url: "/api/jobboard/jobs/" + uuid,
      };
      try {
        const res = await axios(config);
        if (res.status == 200) {
          EventBus.$emit("job-deleted");
          dispatch("get_jobs");
        }
      } catch (error) {
        EventBus.$emit("job-deleted");
      }
    },
    async get_single_jobs({ commit }, uuid) {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: "/api/jobboard/jobs/" + uuid,
      };
      try {
        const res = await axios(config);
        if (res.status == 200) {
          commit("SET_JOB_DETAILS", res.data);
          EventBus.$emit("got-job-details");
        }
      } catch (error) {}
    },
    async search_jobs({ commit }, query) {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: "/api/jobboard/jobs?job_status=active&keyword=" + query,
      };
      try {
        const res = await axios(config);
        if (res.status == 200) {
          commit("SET_JOBS", res.data.data);
          EventBus.$emit("got-jobs");
        }
      } catch (error) {
        EventBus.$emit("got-jobs");
      }
    },
    async get_jobs({ commit }, {country_code} ) {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: "/api/jobboard/jobs?job_status=active&country_code="+country_code,
      };
      try {
        const res = await axios(config);
        if (res.status == 200) {
          commit("SET_JOBS", res.data.data);
          EventBus.$emit("got-jobs");
        }
      } catch (error) {
        EventBus.$emit("got-jobs");
      }
    },
    async update_job({ commit, dispatch }, { data, uuid }) {
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: "/api/jobboard/jobs/" + uuid,

        data: data,
      };
      try {
        const res = await axios(config);
        if (res.status == 200) {
          dispatch("get_jobs");
          Toast.fire({
            icon: "success",
            title: "Job Updated",
          });
          EventBus.$emit("job-created");
        }
      } catch (error) {
        EventBus.$emit("job-created");
      }
    },
    async create_job({ commit, dispatch }, data) {
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: "/api/jobboard/jobs",

        data: data,
      };
      try {
        const res = await axios(config);
        if (res.status == 200) {
          dispatch("get_jobs");
          Toast.fire({
            icon: "success",
            title: "Job Created",
          });
          EventBus.$emit("job-created");
        }
      } catch (error) {
        EventBus.$emit("job-created");
      }
    },
    async delete_job_category({ commit, dispatch }, uuid) {
      let config = {
        method: "delete",
        maxBodyLength: Infinity,
        url: "/api/jobboard/categories/" + uuid,
      };
      try {
        const res = await axios(config);
        if (res.status == 200) {
          dispatch("get_categories");
          EventBus.$emit("job-category-deleted");
          Toast.fire({
            icon: "error",
            title: "Category Deleted",
          });
        }
      } catch (error) {
        EventBus.$emit("job-category-deleted");
      }
    },
    async get_categories({ commit }) {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: "/api/jobboard/categories",
      };
      try {
        const res = await axios(config);
        if (res.status == 200) {
          EventBus.$emit("got-job-category");
          commit("SET_JOB_CATEGORY", res.data.data);
        }
      } catch (error) {
        EventBus.$emit("got-job-category");
      }
    },
    async create_category({ commit }, data) {
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: "/api/jobboard/categories",

        data: data,
      };
      try {
        const res = await axios(config);
        if (res.status == 200) {
          dispatch("get_categories");
          Toast.fire({
            icon: "success",
            title: "Category Created",
          });
          EventBus.$emit("job-category-created");
        }
      } catch (error) {
        EventBus.$emit("job-category-created");
      }
    },
    async update_category({ commit, dispatch }, { data, uuid }) {
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: "/api/jobboard/categories/" + uuid,

        data: data,
      };
      try {
        const res = await axios(config);
        if (res.status == 200) {
          dispatch("get_categories");
          Toast.fire({
            icon: "success",
            title: "Category Updated",
          });
          EventBus.$emit("job-category-created");
        }
      } catch (error) {
        EventBus.$emit("job-category-created");
      }
    },
  },
};
export default jobs;
