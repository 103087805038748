import * as micrositeAPI from "@api/microsite";
import * as micrositeCategoryAPI from "@api/micrositecategory";
import axios from "axios";
import Swal from "sweetalert2";
import { Toast } from "../../mixins/swal";
import EventBus from "../../event-bus";
// import { config } from "vue/types/umd";
// import * as micrositeCategoryAPI from '@api/microstecategory'
// import * as micrositeSubCategoryAPI from '@api/micrositeSubcategory'
// import moment from 'moment';

const microsite = {
  namespaced: true,
  state: {
    microsites: [],
    searched_microsites: [],
    microsite: {},
    my_microsites: [],
    microsites_category: [],
    microsites_subcategory: [],
    searched_subtype: [],
    themes: [],
    community_microsite: [],
    theme: {},
    all_subtype: [],
    microsite_themes: []
    // communities: []
  },
  getters: {
    gettheme: (state) => state.theme,
    getthemes: (state) => state.themes,
    getmicrositethemes: (state) => state.microsite_themes,
    getcommunities: (state) => state.communities,
    getmicrosites: (state) => state.microsites,
    getsearchedmicrosites: (state) => state.searched_microsites,
    getmicrosite: (state) => state.microsite,
    getmymicrosite: (state) => state.my_microsites,
    getmicrositescategory: (state) => state.microsites_category,
    getmicrositessubcategory: (state) => state.microsites_subcategory,
    getcommunity_microsite: (state) => state.community_microsite,
    get_all_subtype: (state) => state.all_subtype,
    get_searched_subtype: (state) => state.searched_subtype,
  },
  mutations: {
    SET_SEARCHED_MICROSITE: (state, data = {}) => {
      state.searched_microsites = data;
    },
    SET_MICROSITE: (state, data = {}) => {
      state.microsites = data;
    },
    SET_MY_MICROSITE: (state, data = {}) => {
      state.my_microsites = data;
    },
    SET_ONE_MICROSITE: (state, data = {}) => {
      state.microsite = data;
    },
    SET_MICROSITE_CATEGORY: (state, data = {}) => {
      state.microsites_category = data;
    },
    SET_MICROSITE_SUBCATEGORY: (state, data = {}) => {
      state.microsites_subcategory = data;
    },
    SET_THEMES: (state, data = {}) => {
      state.themes = data;
    }, 
    SET_ONE_THEME: (state, data = {}) => {
      state.theme = data;
    },
    SET_SEARCHED_THEMES: (state, data = {}) => {
      state.themes = data;
    },
    SET_COMMUNITY_MICROSITE: (state, data = {}) => {
      state.community_microsite = data;
    },
    SET_ALL_SUBTYPE: (state, data = {}) => {
      state.all_subtype = data;
    },
    SET_SEARCHED_SUBCATE: (state, data = {}) => {
      state.searched_subtype = data;
    },
    // SET_COMMUNITIES: (state, data = {}) => {
    //   state.communities = data;
    // },
  },
  actions: {
    async delete_microsite({ commit, dispatch }, uuid) {
      const config = {
        method: "delete",
        maxBodyLength: Infinity,
        url: "/api/mymicrosites/microsite/" + uuid,
        // mymicrosites/microsite
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      try {
        const res = await axios(config);
        if (res.status == 200) {
          dispatch("Get_My_Microsite_all", {current_page: 1, per_page: 20});
          Toast.fire({
            icon: "error",
            title: "Microsite deleted",
          });
          EventBus.$emit("deleted");
        }
      } catch (error) {
        EventBus.$emit("deleted");
      }
    },
    async findOneMicrosite({ commit }, uuid) {
      const config = {
        method: 'GET',
        url: '/api/microsite/details/'+uuid
      }
      try {
         const res = await axios(config)
         commit("SET_ONE_MICROSITE", res.data);
         EventBus.$emit("got-microsite-details-fortheme");
      } catch (error) {
         console.log(error)
      }
    },
    Create_Microsite({ commit }, payload) {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      axios
        .post("/api/mymicrosites/microsite", payload, config)
        .then((response) => {
          console.log(response);
          Toast.fire({
            icon: "success",
            title: "Microsite created  successfully",
          });
          EventBus.$emit("loading-done");
          EventBus.$emit(
            "route-to-details",
            response.data.micorsite_details.uuid
          );
        })
        .catch((err) => {
          EventBus.$emit("loading-done");
        });
    },

    get_community_microsites({ commit }, {user_id, current_page, per_page}) {
      const config = {
        headers: {},
      };
      axios
        .get("/api/mymicrosites/microsites?type_id=9"+"&current_page="+current_page+"&per_page="+per_page, config)
        .then((res) => {
          commit("SET_COMMUNITY_MICROSITE", res.data.data);
          EventBus.$emit("community-got");
        })
        .catch((err) => console.log(err));
    },
    Get_Microsite_all({ commit }, { country_code }) {
      const config = {
        headers: {},
      };
      axios
        .get(
          "/api/microsite/microsites?public=yes&country_code=" + country_code,
          config
        )
        .then((res) => {
          commit("SET_MICROSITE", res.data.data);
        })
        // .catch((err) => console.log(err));
    },
    // get_communities({ commit }) {
    //   const config = {
    //     headers: {},
    //   };
    //   axios
    //     .get(
    //       "/api/microsite/microsites?subtype_id=9",
    //       config
    //     )
    //     .then((res) => {
    //       commit("SET_COMMUNITIES", res.data.data);
    //     })
    //     // .catch((err) => console.log(err));
    // },

    Get_My_Microsite_all({ commit }, {current_page, per_page}) {
      const config = {
        headers: {},
      };
      axios
        .get("/api/mymicrosites/microsites?type_id=not_community&per_page="+per_page, config)
        .then((res) => {
          commit("SET_MY_MICROSITE", res.data.data);
        })
        // .catch((err) => console.log(err));
    },
    update_status({ commit, dispatch }, payload) {
      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: "/api/mymicrosites/microsite/" + payload.uuid,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: payload.data,
      };
      axios(config)
        .then((res) => {
          dispatch("Get_My_Microsite_all");
          Toast.fire({
            icon: "success",
            title: "Status updated",
          });
        })
        // .catch((err) => console.log(err));
    },
    update_microsite({ commit, dispatch }, { data, uuid }) {
      const config = {
        method: "post",
        url: "/api/mymicrosites/microsite/" + uuid,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: data,
      };
      axios(config)
        .then((res) => {
          dispatch("Get_My_Microsite_all");
            Toast.fire({
              icon: "success",
              title: "Microsite updated",
            });
          EventBus.$emit("microsite-updated");
        })
        .catch((err) => {
          EventBus.$emit("microsite-updated");
        });
    },

    Get_Microsite_Category({ commit }) {
      const config = {
        headers: {},
      };
      axios
        .get("/api/madmin/micrositecategory", config)
        .then((res) => {
          commit("SET_MICROSITE_CATEGORY", res.data.data);
        })
        // .catch((err) => console.log(err));
    },

    Get_Microsite_Subcategory({ commit }, type_id) {
      const config = {
        headers: {},
      };
      axios
        .get(`/api/madmin/micrositesubcategory?type_id=${type_id}`, config)
        .then((res) => {
          commit("SET_MICROSITE_SUBCATEGORY", res.data.data);
          EventBus.$emit("subtype_fetched");
        })
        // .catch((err) => console.log(err));
    },
    /**
     * Get Available Microsite Sub Categories
     * @get ("/api/madmin/micrositesubcategory")
     *  @param ({
     *      @Parameter("type_id", type="integer", required="optional", description="Microsite Category ID as parent id"),
     *      @Parameter("keyword", type="string", required="optional", description="keyword"),
     }) 
     * @return array
     */
    async search_subtype({ commit }, { query }) {
      const config = {
        method: "get",
        maxBodyLength: Infinity,
        url: "/api/madmin/micrositesubcategory?keyword=" + query,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      try {
        const res = await axios(config);
        if (res.status == 200) {
          commit("SET_SEARCHED_SUBCATE", res.data.data);
        }
      } catch (error) {}
    },
    create_category({ commit }, payload) {
      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: "/api/madmin/micrositecategory",
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: payload,
      };
      axios(config)
        .then((res) => {
          Toast.fire({
            icon: "success",
            title: "Category created",
          });
          EventBus.$emit("categorycreated");
        })
        .catch((err) => {
          Toast.fire({
            icon: "error",
            title: "Category created failed",
          });
          EventBus.$emit("categorycreated");
        });
    },
    async update_category({ commit }, { data, uuid }) {
      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: "/api/madmin/micrositecategory/" + uuid,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: data,
      };
      try {
        const res = await axios(config);
        if (res.status == 200) {
          Toast.fire({
            icon: "success",
            title: "Category update",
          });
          EventBus.$emit("categoryupdated");
        }
      } catch (error) {
        EventBus.$emit("categoryupdated");
      }
    },
    async delete_category({ commit, dispatch }, uuid) {
      const config = {
        method: "delete",
        maxBodyLength: Infinity,
        url: "/api/madmin/micrositecategory/" + uuid,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      try {
        const res = await axios(config);
        if (res.status == 200) {
          Toast.fire({
            icon: "error",
            title: "Category Deleted",
          });
          EventBus.$emit("categorydeleted");
          dispatch("Get_Microsite_Category");
        }
      } catch (error) {}
    },
    async delete_subcategory({ commit, dispatch }, { uuid, type_id }) {
      const config = {
        method: "delete",
        maxBodyLength: Infinity,
        url: "/api/madmin/micrositesubcategory/" + uuid,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      try {
        const res = await axios(config);
        if (res.status == 200) {
          Toast.fire({
            icon: "error",
            title: "Subcategory Deleted",
          });
          EventBus.$emit("categorydeleted");
          dispatch("Get_Microsite_Subcategory", type_id);
        }
      } catch (error) {}
    },
    create_subcategory({ commit }, payload) {
      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: "/api/madmin/micrositesubcategory",
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: payload,
      };
      axios(config)
        .then((res) => {
          Toast.fire({
            icon: "success",
            title: "Subcategory created",
          });
          EventBus.$emit("subtype-created");
        })
        .catch((err) => {
          Toast.fire({
            icon: "error",
            title: "Subcategory created failed",
          });
          EventBus.$emit("subtype-created");
        });
    },
    update_subcategory({ commit }, { data, uuid }) {
      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: "/api/madmin/micrositesubcategory/" + uuid,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: data,
      };
      axios(config)
        .then((res) => {
          Toast.fire({
            icon: "success",
            title: "Subcategory updated",
          });
          EventBus.$emit("subtype-updated");
        })
        .catch((err) => {
          EventBus.$emit("subtype-updated");
        });
    },
    Search_Microsite({ commit }, queryText) {
      const config = {
        headers: {},
      };
      axios
        .get(`/api/microsite/microsites?keyword=${queryText}`, config)
        .then((res) => {
          commit("SET_SEARCHED_MICROSITE", res.data.data);
          EventBus.$emit("searched-microsite")
        })
        // .catch((err) => console.log(err));
    },
    search_village_microsite({ commit }, queryText) {
      const config = {
        headers: {},
      };
      axios
        .get(`/api/microsite/microsites?type_id=9&keyword=${queryText}`, config)
        .then((res) => {
          EventBus.$emit("seatch-complete");
          commit("SET_COMMUNITY_MICROSITE", res.data.data);
        })
        .catch((err) => {});
    },
    theme_create({ commit, dispatch }, {data }) {
      var config = {
        method: "post",
        maxBodyLength: Infinity,
        url: "/api/madmin/themes",
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data,
      };

      axios(config)
        .then(function (response) {
          Toast.fire({
            icon: "success",
            title: "Operation succcessfull",
          });
         
          EventBus.$emit("theme-created");
        })
        .catch(function (error) {
          Toast.fire({
            icon: "error",
            title: error,
          });
          EventBus.$emit("theme-created");
        });
    },
    async add_theme({commit, dispatch}, {data, subtype_id, microsite_id} ) {
       let bodyContent =  data;
       
       let reqOptions = {
         url: "/api/madmin/addthemes",
         method: "POST",
         
         data: bodyContent,
       }
       try {
         await axios.request(reqOptions); 
         dispatch('get_theme', {subtype_id, microsite_id} )
       
         EventBus.$emit("theme-added");
       } catch (error) {
        EventBus.$emit("theme-added");
       }
    },
    update_theme({ commit, dispatch }, { data, uuid, subtype_id }) {
      var config = {
        method: "post",
        maxBodyLength: Infinity,
        url: "/api/madmin/themes/" + uuid,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          dispatch("get_theme", subtype_id);
          Toast.fire({
            icon: "success",
            title: "Theme updated",
          });
          EventBus.$emit("theme-updated");
        })
        .catch(function (error) {
          Toast.fire({
            icon: "error",
            title: error,
          });
          EventBus.$emit("theme-updated");
        });
    },
    get_theme({ commit }, {subtype_id, microsite_id}) {
      const config = {
        headers: {},
      };
      axios
        .get("/api/madmin/themes?microsite_id="+microsite_id+"&subtype_id=" + subtype_id, config)
        .then((res) => {
          commit("SET_THEMES", res.data.data);
          // console.log("my themes: "+res.data);
          EventBus.$emit("got-theme")
        })
        .catch((err) => {});
    }, 
    delete_theme({ commit, dispatch }, { uuid, subtype_id }) {
      const config = {
        headers: {},
      };
      axios
        .delete("/api/madmin/themes/" + uuid, config)
        .then((res) => {
          dispatch("get_theme", subtype_id);
          Toast.fire({
            icon: "error",
            title: "Theme deleted",
          });
          EventBus.$emit("theme-deleted");
        })
        .catch((err) => {
          Toast.fire({
            icon: "error",
            title: err,
          });
          EventBus.$emit("theme-deleted");
        });
    },
    find_one_theme({ commit }, uuid) {
      var config = {
        method: "get",
        maxBodyLength: Infinity,
        url: "/api/madmin/themes/" + uuid,
      };

      axios(config)
        .then(function (response) {
          commit("SET_ONE_THEME", response.data);
        })
        .catch(function (error) {
          // console.log(error);
        });
    },

    search_theme({ commit }, { query, subtype_id }) {
      const config = {};
      axios
        .get(
          "/api/madmin/themes?subtype_id=" + subtype_id + "&query=" + query,
          config
        )
        .then((res) => {
          commit("SET_THEMES", res.data.data);
          EventBus.$emit("search-complete");
        })
        .catch((err) => {});
    },
    async get_all_subtype({ commit }) {
      const config = {
        method: "get",
        maxBodyLength: Infinity,
        url: "/api/madmin/micrositesubcategory",
      };
      try {
        const res = await axios(config);
        if (res.status == 200) {
          commit("SET_ALL_SUBTYPE", res.data.data);
        }
      } catch (error) {
        // console.log(error);
      }
    },
  },
};
export default microsite;
