import axios from "axios";
import Swal from "sweetalert2";
import { Toast } from "../../mixins/swal";
import { getToken } from "@core/utils/auth";
import EventBus from "../../event-bus";
const token = getToken();

const classified = {
  namespaced: true,
  state: {
    categories: [],
    sell_posts: [],
    classified_item: {},
    my_orders: [],
    orders: [],
    order_details: [],
    classified_orders: [],
    category_product: [],
    order_response: {},
  },
  getters: {
    getclassifiescategories: (state) => state.categories,
    getsellpost: (state) => state.sell_posts,
    getpostdetails: (state) => state.classified_item,
    getmyorders: (state) => state.my_orders,
    getorders: (state) => state.orders,
    getorderdetails: (state) => state.order_details,
    getclassifiedorders: (state) => state.classified_orders,
    getcategoryproduct: (state) => state.category_product,
    getorder_response: (state) => state.order_response,
  },
  mutations: {
    SET_CATEGORIES: (state, data = {}) => {
      state.categories = data;
    },
    SET_SELL_POSTS: (state, data = {}) => {
      state.sell_posts = data;
    },
    SET_CLASSIFIED_ITEM_DETAILS: (state, data = {}) => {
      state.classified_item = data;
    },
    SET_MY_ORDERS: (state, data = {}) => {
      state.my_orders = data;
    },
    SET_ALL_ORDERS: (state, data = {}) => {
      state.orders = data;
    },
    SET_ORDERS_DETAILS: (state, data = {}) => {
      state.order_details = data;
    },
    SET_CLASSIFIED_ORDERS: (state, data = {}) => {
      state.classified_orders = data;
    },
    SET_CATEGORY_POSTS: (state, data = {}) => {
      state.category_product = data;
    },
    SET_ORDER_RESPONSE: (state, data = {}) => {
      state.order_response = data;
    },
  },
  actions: {
    async accept_bid({ commit, dispatch }, { uuid, data }) {
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: "/api/classified/bids/" + uuid,

        data: data,
      };
      try {
        const res = await axios(config);
        if (res.status == 200) {
          dispatch("get_product_details", item_uuid);
          Toast.fire({
            icon: "success",
            title: "Bid accepted",
          });
          EventBus.$emit("bid-acceped");
        }
      } catch (error) {
        EventBus.$emit("bid-acceped");
      }
    },
    async delete_bid({ commit, dispatch }, { uuid, item_uuid }) {
      let config = {
        method: "delete",
        maxBodyLength: Infinity,
        url: "/api/classified/bids/" + uuid,
       
      };
      try {
        const res = await axios(config);
        if (res.status == 200) {
          dispatch("get_product_details", item_uuid);
          Toast.fire({
            icon: "error",
            title: "Bid deleted",
          });
          EventBus.$emit("bid-deleted");
        }
      } catch (error) {
        EventBus.$emit("bid-deleted");
      }
    },
    async get_product_details({ commit }, uuid) {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: "/api/classified/item/" + uuid,
      
      };
      try {
        const res = await axios(config);
        if (res.status == 200) {
          commit("SET_CLASSIFIED_ITEM_DETAILS", res.data);
          EventBus.$emit("got-details");
        }
      } catch (error) {
        EventBus.$emit("got-details");
      }
    },
    async create_bid({ commit, dispatch }, { data, classified_uuid }) {
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: "/api/classified/bids",

        data: data,
      };
      try {
        const res = await axios(config);
        if (res.status == 200) {
          dispatch("get_product_details", classified_uuid);
          Toast.fire({
            icon: "success",
            title: "Bid pleced",
          });
          EventBus.$emit("bid-placed");
        }
      } catch (error) {
        EventBus.$emit("bid-placed");
      }
    },
    async get_classified_specific_orders({ commit }, id) {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: "/api/classified/Order?classified_id=" + id,
      };
      try {
        const res = await axios(config);
        if (res.status == 200) {
          commit("SET_CLASSIFIED_ORDERS", res.data.data);
          EventBus.$emit("classified-orders");
        }
      } catch (error) {
        EventBus.$emit("classified-orders");
      }
    },
    async get_classified_order_details({ commit }, uuid) {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: "/api/classified/Order/" + uuid,
      };
      try {
        const res = await axios(config);
        if (res.status == 200) {
          commit("SET_ORDERS_DETAILS", res.data);
          EventBus.$emit("order-details");
        }
      } catch (error) {
        EventBus.$emit("order-details");
      }
    },
    async get_classified_orders({ commit, dispatch }) {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: "/api/classified/Order",
      };
      try {
        const res = await axios(config);
        if (res.status == 200) {
          commit("SET_ALL_ORDERS", res.data.data);
          EventBus.$emit("order-received");
        }
      } catch (error) {
        EventBus.$emit("order-received");
      }
    },
    async get_classified_my_orders({ commit, dispatch }) {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: "/api/classified/myorder",
      };
      try {
        const res = await axios(config);
        if (res.status == 200) {
          commit("SET_MY_ORDERS", res.data.data);
          EventBus.$emit("myorder-received");
        }
      } catch (error) {
        EventBus.$emit("myorder-received");
      }
    },
    async create_order({ commit, dispatch }, data) {
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: "/api/classified/Order",

        data: data,
      };
      try {
        const res = await axios(config);
        if (res.status == 200) {
          commit("SET_ORDER_RESPONSE", res);
          Toast.fire({
            icon: "success",
            title: "Order Created",
          });
          EventBus.$emit("order-placed");
        }
      } catch (error) {
        EventBus.$emit("order-placed");
      }
    },
    async update_order({ commit, dispatch }, { data, uuid }) {
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: "/api/classified/Order/" + uuid,

        data: data,
      };
      try {
        const res = await axios(config);
        if (res.status == 200) {
          commit("SET_ORDER_RESPONSE", res);
          // Toast.fire({
          //   icon: "success",
          //   title: "Order Created",
          // });
          EventBus.$emit("order-placed");
        }
      } catch (error) {
        EventBus.$emit("order-placed");
      }
    },
    async update_bid({ commit, dispatch }, { data, uuid, classified_uuid }) {
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: "/api/classified/bids/" + uuid,

        data: data,
      };
      try {
        const res = await axios(config);
        if (res.status == 200) {
          dispatch("get_product_details", classified_uuid);
          Toast.fire({
            icon: "success",
            title: "Bid Updated",
          });
          EventBus.$emit("bid-placed-updated");
        }
      } catch (error) {
        EventBus.$emit("bid-placed-updated");
      }
    },
    async delete_classified_item({ commit, dispatch }, uuid) {
      let config = {
        method: "delete",
        maxBodyLength: Infinity,
        url: "/api/classified/item/" + uuid,
       
      };
      try {
        const res = await axios(config);
        if (res.status == 200) {
          Toast.fire({
            icon: "error",
            title: "Post deleted",
          });
          EventBus.$emit("classified-item-deleted");
          dispatch("get_sell_post", {country: ''});
        }
      } catch (error) {
        EventBus.$emit("classified-item-deleted");
      }
    },
    async get_sell_post({ commit }, { country = '' }) {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: "/api/classified/item?country_code=" + country,
      };
      try {
        const res = await axios(config);
        if (res.status == 200) {
          commit("SET_SELL_POSTS", res.data.data);
          EventBus.$emit("sell-post-received");
        }
      } catch (error) {}
    },
    async findClassifiedProduct({ commit }, id) {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: "/api/classified/item?category=" + id,
       
      };
      try {
        const res = await axios(config);
        if (res.status == 200) {
          commit("SET_CATEGORY_POSTS", res.data.data);
          EventBus.$emit("category-post-received");
        }
      } catch (error) {
        EventBus.$emit("category-post-received");
      }
    },
    async search_sell_post({ commit }, { query }) {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: "/api/classified/item?keyword=" + query,
       
      };
      try {
        const res = await axios(config);
        if (res.status == 200) {
          commit("SET_SELL_POSTS", res.data.data);
          EventBus.$emit("sell-post-received");
        }
      } catch (error) {}
    },
    async create_sell_post({ commit, dispatch }, data) {
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: "/api/classified/item",

        data: data,
      };
      try {
        const res = await axios(config);
        if (res.status == 200) {
          Toast.fire({
            icon: "success",
            title: "Post Created",
          });
          dispatch("get_sell_post", {country: ''});
          EventBus.$emit("sell-post-created");
        }
      } catch (error) {
        EventBus.$emit("sell-post-created");
      }
    },
    async update_sell_post({ commit, dispatch }, { data, uuid }) {
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: "/api/classified/item/" + uuid,

        data: data,
      };
      try {
        const res = await axios(config);
        if (res.status == 200) {
          Toast.fire({
            icon: "success",
            title: "Post Updated",
          });
          dispatch("get_sell_post", {country: ''});
          EventBus.$emit("sell-post-updated");
        }
      } catch (error) {
        EventBus.$emit("sell-post-updated");
      }
    },
    async delete_classified_category({ commit, dispatch }, uuid) {
      let config = {
        method: "delete",
        maxBodyLength: Infinity,
        url: "/api/classified/categories/" + uuid,
      };
      try {
        const res = await axios(config);
        if (res.status == 200) {
          Toast.fire({
            icon: "error",
            title: "Category deleted",
          });
          dispatch("get_calssified_categories");
        }
        dispatch("");
      } catch (error) {}
    },
    async get_calssified_categories({ commit }) {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: "/api/classified/categories",
      };
      try {
        const res = await axios(config);
        if (res.status == 200) {
          commit("SET_CATEGORIES", res.data.data);
        }
      } catch (error) {}
    },
    async create_classified_category({ commit, dispatch }, data) {
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: "/api/classified/categories",

        data: data,
      };

      try {
        const res = await axios(config);
        dispatch("get_calssified_categories");
        if (res.status == 200) {
          Toast.fire({
            icon: "success",
            title: "Category created",
          });
        }
        EventBus.$emit("category-created");
      } catch (error) {}
    },
    async update_classified_category({ commit, dispatch }, { data, uuid }) {
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: "/api/classified/categories/" + uuid,

        data: data,
      };

      try {
        const res = await axios(config);
        dispatch("get_calssified_categories");
        if (res.status == 200) {
          Toast.fire({
            icon: "success",
            title: "Category Updated",
          });
        }
        EventBus.$emit("category-updated");
      } catch (error) {}
    },
  },
};
export default classified;
