var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.hideIfEmpty ||
    (_vm.hideIfEmpty && _vm.value !== null && _vm.value !== "")
    ? _c(
        "div",
        { staticClass: "base-view view-single" },
        [
          _c(
            "div",
            {
              staticClass: "view-group",
              class: [
                { "has-label": _vm.label || _vm.$slots.label },
                { "is-entity": _vm.isEntity },
                { "not-empty": _vm.value || _vm.value === 0 },
                _vm.viewGroupClasses,
              ],
            },
            [
              _vm._t("label", function () {
                return [
                  _vm.label
                    ? _c("label", { class: _vm.labelClasses }, [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.label) +
                            "\n            "
                        ),
                      ])
                    : _vm._e(),
                ]
              }),
              _vm._v(" "),
              _vm._t("main", function () {
                return [
                  _c(
                    "div",
                    { staticClass: "view-data-wrapper" },
                    [
                      _vm.value || _vm.value === 0
                        ? [
                            _vm.link
                              ? _c(
                                  "router-link",
                                  {
                                    class: [
                                      "view-data has-link",
                                      { focusable: _vm.focusable },
                                      _vm.dataClasses,
                                    ],
                                    attrs: { to: _vm.link, tag: _vm.linkTag },
                                  },
                                  [
                                    _vm.showIcon && _vm.icon
                                      ? _c(
                                          "div",
                                          {
                                            class: [
                                              "icon-wrapper",
                                              _vm.iconClasses,
                                            ],
                                          },
                                          [_c("i", { class: _vm.icon })]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      [
                                        _vm.prefix
                                          ? _c(
                                              "span",
                                              { class: _vm.prefixClasses },
                                              [_vm._v(_vm._s(_vm.prefix))]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.html
                                          ? _c("div", {
                                              domProps: {
                                                innerHTML: _vm._s(_vm.value),
                                              },
                                            })
                                          : _vm.showIfExists
                                          ? [
                                              _vm._v(
                                                "\n                                " +
                                                  _vm._s(
                                                    _vm.value[_vm.showIfExists]
                                                  ) +
                                                  "\n                            "
                                              ),
                                            ]
                                          : [_vm._v(_vm._s(_vm.value))],
                                        _vm._v(" "),
                                        _vm.suffix
                                          ? _c(
                                              "span",
                                              { class: _vm.suffixClasses },
                                              [_vm._v(_vm._s(_vm.suffix))]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.description
                                          ? _c(
                                              "div",
                                              {
                                                class: [
                                                  "description",
                                                  _vm.descriptionClasses,
                                                ],
                                              },
                                              [_vm._v(_vm._s(_vm.description))]
                                            )
                                          : _vm._e(),
                                      ],
                                      2
                                    ),
                                  ]
                                )
                              : _c(
                                  _vm.tag,
                                  {
                                    tag: "component",
                                    class: [
                                      "view-data",
                                      { focusable: _vm.focusable },
                                      _vm.dataClasses,
                                    ],
                                  },
                                  [
                                    _vm.showIcon && _vm.icon
                                      ? _c(
                                          "div",
                                          { staticClass: "icon-wrapper" },
                                          [_c("i", { class: _vm.icon })]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      [
                                        _vm.prefix
                                          ? _c(
                                              "span",
                                              { class: _vm.prefixClasses },
                                              [_vm._v(_vm._s(_vm.prefix))]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.html
                                          ? _c("div", {
                                              domProps: {
                                                innerHTML: _vm._s(_vm.value),
                                              },
                                            })
                                          : _vm.showIfExists
                                          ? [
                                              _vm._v(
                                                "\n                                " +
                                                  _vm._s(
                                                    _vm.value[_vm.showIfExists]
                                                  ) +
                                                  "\n                            "
                                              ),
                                            ]
                                          : [_vm._v(_vm._s(_vm.value))],
                                        _vm._v(" "),
                                        _vm.suffix
                                          ? _c(
                                              "span",
                                              { class: _vm.suffixClasses },
                                              [_vm._v(_vm._s(_vm.suffix))]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.description
                                          ? _c(
                                              "div",
                                              {
                                                class: [
                                                  "description",
                                                  _vm.descriptionClasses,
                                                ],
                                              },
                                              [_vm._v(_vm._s(_vm.description))]
                                            )
                                          : _vm._e(),
                                      ],
                                      2
                                    ),
                                  ]
                                ),
                          ]
                        : _c("span", [_vm._v("-")]),
                    ],
                    2
                  ),
                ]
              }),
            ],
            2
          ),
          _vm._v(" "),
          _vm._t("errorBlock"),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }