var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", {
    staticClass: "loader loader--square-bounce-rotate",
    style: _vm.styles,
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }