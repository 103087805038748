import axios from "axios";
import Swal from "sweetalert2";
import WorkerPost from "worker-loader!../../web-worker/lounge/post"
import WorkerGP from "worker-loader!../../web-worker/lounge/get_post"

import { Toast } from "../../mixins/swal";
import EventBus from "../../event-bus";

const lounge = {
  namespaced: true,
  state: {
    imojis: [],
    locations: [],
    friends: [],
    feeds: [],
    user_feed: [],
    friends_professional: [],
    comments: [],
    feed_current_page: 0,
    upload_progress: '',
    post_reaction: []
  },
  getters: {
    getfriends: (state) => state.friends,
    getprofessionalfriends: (state) => state.friends_professional,
    getmyfeeds: (state) => state.feeds,
    getuserfeeds: (state) => state.user_feed,
    getallimoji: (state) => state.imojis,
    getlocations: (state) => state.locations,
    getcomments: (state) => state.comments,
    getfeedcurrentpage: state => state.feed_current_page,
    getupload_progress: state => state.upload_progress,
    post_reaction: state => state.post_reaction
  },
  mutations: {
    SET_MY_SOCIAL_FRIEND: (state, data = {}) => {
      state.friends = data;
    },
    SET_MY_PROFESSIONAL_FRIEND: (state, data = {}) => {
      state.friends_professional = data;
    },
    SET_IMOJIS: (state, data = {}) => {
      state.imojis = data;
    },
    SET_LOCATIONS: (state, data = {}) => {
      state.locations = data;
    },
    SET_MY_FEED: (state, data = {}) => {
      state.feeds.push(...data);
    },
    SET_MY_FEED_PAGEONE: (state, data = {}) => {
      state.feeds = data
    },
    SET_USER_FEED: (state, data = {}) => {
      state.user_feed = data;
      // state.user_feed.push(...data);
    },
    SET_COMMENTS: (state, data) => {
      state.comments = data;
    },
    SET_FEED_CURRENT_PAGE: (state, data) => {
      state.feed_current_page = data;
    },
    SET_POST_REACTION: (state, data) => {
      state.post_reaction = data;
    },
    SET_UPLOAD_PROGRESS: (state, data) => {
      state.upload_progress = data
    }
  },
  actions: {
    async update_social_post({ commit, dispatch }, payload) {
      var config = {
        method: "post",
        maxBodyLength: Infinity,
        url: "/api/community/feeds/" + payload.uuid,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: payload.data,
      };

      try {
        const res = await axios(config);
        if (res.status == 200) {
          EventBus.$emit("postsuccess");
          dispatch("get_social_post", {current_page: 1, per_page: 30});
        }
      } catch (error) { 
        EventBus.$emit("postsuccess");
      }
    },
    async add_social_post({ commit, dispatch, rootGetters }, data) {
      // const token = rootGetters['user/getT']
      // const csrfToken = this.$cookies.get('XSRF-TOKEN')
      // commit('SET_UPLOAD_PROGRESS', 0)
      // EventBus.$emit("postupdated");
      // const worker_post = new WorkerPost();
      // worker_post.postMessage(data);
      // worker_post.addEventListener('message', (event) => {
			// 		const responseData = event.data;
      //     console.log(responseData.status)
			// 		if (typeof(responseData) == 'number') {
      //       commit('SET_UPLOAD_PROGRESS', responseData)
			// 		} else {
      //         console.log("post added: "+responseData)
      //         commit('SET_UPLOAD_PROGRESS', '')
			// 				dispatch("get_social_post", {current_page: 1, per_page: 30})
			// 		}
			// });
      commit('SET_UPLOAD_PROGRESS', 0)
      var config = {
        method: "post",
        maxBodyLength: Infinity,
        url: "/api/community/feeds",
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: data,
        onUploadProgress: function({loaded, total, type}) {
          const percentComplete = (loaded / total) * 100;
          const percentage = Math.ceil(percentComplete)
        }
        
      };
      let professional = data.get("professional")
      let user_id = rootGetters['profile/user_id']

      try {
        const res = await axios(config);
        if (res.status == 200) {
          EventBus.$emit("postupdated");
          dispatch("get_social_post", {current_page: 1, per_page: 30});
          dispatch("getUserFeeds", { user_id, professional })
        }
      } catch (error) { 
        EventBus.$emit("postupdated");
      }
    },
    async delete_post({ commit, dispatch }, uuid) {
      var config = {
        method: "delete",
        maxBodyLength: Infinity,
        url: "/api/community/feeds/" + uuid,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      try {
        const res = await axios(config);
        if (res.status == 200) {
          dispatch("get_social_post", {current_page: 1, per_page: 30});
          EventBus.$emit("my-post-deleted")
        }
      } catch (error) {
      }
    },
    async get_social_post({ commit, rootGetters }, {current_page, per_page}) {
      // const token = rootGetters['user/getT']
      // const worker_gp = new WorkerGP()
			// worker_gp.postMessage({token, current_page:data.current_page, per_page:data.per_page});
			// worker_gp.addEventListener('message', ({data}) => {
      //     commit("SET_MY_FEED", data.data);
      //     commit('SET_FEED_CURRENT_PAGE', data.meta.current_page)
      //     console.log("get post: -:"+data)
      //     EventBus.$emit("postsuccess");
			// });
      var config = {
        method: "get",
        maxBodyLength: Infinity,
        url: "/api/community/feeds?professional=2&per_page="+per_page+"&current_page="+current_page,
      };

      try {
        const res = await axios(config);
        if (res.status == 200) { 
          commit('SET_FEED_CURRENT_PAGE', res.data.meta.current_page)
          if (current_page == 1) {
            commit("SET_MY_FEED_PAGEONE", res.data.data); 
          } else {
            commit("SET_MY_FEED", res.data.data); 
          }
          EventBus.$emit("postsuccess");
        }
      } catch (error) { 
        EventBus.$emit("postsuccess");
      }
    },
    async getUserFeeds({ commit }, { user_id, professional, current_page = 1, per_page = 15 }) {
      var config = {
        method: "get",
        maxBodyLength: Infinity,
        url:
          "/api/community/feeds?&per_page=100&professional=" +
          professional +
          "&user_id=" +
          user_id,
        // url:
        //   "/api/community/feeds?current_page="+current_page+"&per_page="+per_page+"&professional=" +
        //   professional +
        //   "&user_id=" +
        //   user_id,
          headers: {
            "Content-Type": "multipart/form-data",
          },
      };

      try {
        const res = await axios(config);
        if (res.status == 200) {
          // commit('SET_FEED_CURRENT_PAGE', res.data.meta.current_page)
          // if (current_page == 1) {
            commit("SET_MY_FEED_PAGEONE", res.data.data); 
          // } else {
            commit("SET_USER_FEED", res.data.data);
          // }
        
          EventBus.$emit("postsuccess");
        }
      } catch (error) {
        
        EventBus.$emit("postsuccess");
      }
    },
    async get_imoji({ commit }, data) {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      try {
        const res = await fetch(
          "https://emoji-api.com/emojis?access_key=223726ae671995d250fd7d2b6d64b2fff2e2beb3",
          requestOptions
        );
        const result = await res.text();
        const data = await JSON.parse(result);
        commit("SET_IMOJIS", data.slice(0, 100));
      } catch (error) {
        
      } 
    },
    async search_impji({ commit }, data) {
      var myHeaders = new Headers();
      myHeaders.append("Cookie", "PHPSESSID=4iqfhhh9gju64bd398v7rm53bp");

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };
      try {
        const res = fetch(
          "https://emoji-api.com/emojis?search=" +
            data +
            "&access_key=223726ae671995d250fd7d2b6d64b2fff2e2beb3",
          requestOptions
        );
        if (res.status == 200) {
          const result = await res.text();
          const data = await JSON.parse(result);
          commit("SET_IMOJIS", data);
          EventBus.$emit("loading-close");
        }
      } catch (error) {
        EventBus.$emit("loading-close");
      } 
    },

    get_locations({ commit }, data) {
      var config = {
        method: "get",
        url: "https://maps.googleapis.com/maps/api/place/autocomplete/json?input=amoeba&types=establishment&location=37.76999%2C-122.44696&radius=500&key=AIzaSyD4mdf6nDkpbLo6ToEFm3Tx3aAIjEWwjIQ",
        headers: {},
      };

      axios(config)
        .then(function (response) {
          
        })
        .catch(function (error) {
          
        });
    },
    async getSocialFriends({ commit }, {user_id}) {
      var config = {
        method: "get",
        maxBodyLength: Infinity,
        url: "/api/webcardlibrary?type=social&user_id="+user_id,
        headers: {
          "Content-Type": "application/json",
        },
      };

      try {
        const res = await axios(config);
        if (res.status == 200) {
          commit("SET_MY_SOCIAL_FRIEND", res.data);
        }
      } catch (error) {
        
      }
    },
    async getProfessionalFriends({ commit }) {
      var config = {
        method: "get",
        maxBodyLength: Infinity,
        url: "/api/webcardlibrary?type=professional",
        headers: {
          "Content-Type": "application/json",
        },
      };

      try {
        const res = await axios(config);
        if (res.status == 200) {
          commit("SET_MY_PROFESSIONAL_FRIEND", res.data);
        }
      } catch (error) {
        
      }
    },
    async searchSocialFriends({ commit }, { query }) {
      var config = {
        method: "get",
        maxBodyLength: Infinity,
        url: "/api/webcardlibrary?type=social&keyword=" + query,
        headers: {
          "Content-Type": "application/json",
        },
      };

      try {
        const res = await axios(config);
        if (res.status == 200) {
          commit("SET_MY_SOCIAL_FRIEND", res.data);
        }
      } catch (error) {
        
      }
    },
    async searchProfessionalFriends({ commit }, { query }) {
      var config = {
        method: "get",
        maxBodyLength: Infinity,
        url: "/api/webcardlibrary?type=professional&keyword=" + query,
        headers: {
          "Content-Type": "application/json",
        },
      };
      try {
        const res = await axios(config);
        if (res.status == 200) {
          commit("SET_MY_PROFESSIONAL_FRIEND", res.data);
        }
      } catch (error) {
        
      }
    },
    async get_comments({ commit, state }, post_id) {
      state.cocomments = [];
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: "/api/community/comments?post_id=" + post_id,
        headers: {
          "Content-Type": "application/json",
        },
      };

      try {
        const res = await axios(config);
        if (res.status == 200) {
          commit("SET_COMMENTS", res.data.data);
        }
      } catch (error) {
        
      }
    },
    async update_comment({ comment, dispatch }, { data, uuid, post_id }) {
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: "/api/community/comments/" + uuid,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      try {
        const res = await axios(config);
        if (res.status == 200) {
          dispatch("get_comments", post_id);
          // dispatch("get_social_post", {current_page: 1, per_page: 30});
          EventBus.$emit("comment-updated");
        }
      } catch (error) {
        EventBus.$emit("comment-updated");
        
      }
    },
    async create_comment({ comment, dispatch }, { data, post_id }) {
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: "/api/community/comments",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      try {
        const res = await axios(config);
        if (res.status == 200) {
          dispatch("get_comments", post_id);
          dispatch("get_social_post", {current_page: 1, per_page: 30});
          EventBus.$emit("commented");
        }
      } catch (error) {
        
      }
    },
    // get post reaction
    async get_post_reaction({ commit }, post_id) {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: "/api/community/postreaction?post_id=" + post_id,
        headers: {},
      };
      try {
        const res = await axios(config);
        if (res.status == 200) {
          commit("SET_POST_REACTION", res.data.data)
          EventBus.$emit("get-reactions")
        }
      } catch (error) {
        
      }
    },
    async create_post_reaction({ commit }, data) {
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: "/api/community/postreaction",
        data: data,
      };
      try {
        await axios(config);
      
      } catch (error) {
        
      }
    },
    async update_post_reaction({ commit }, {uuid, data}) {
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: "/api/community/postreaction/"+uuid,
       
        data: data,
      };
      try {
        await axios(config);

      } catch (error) {
        console.log(error);
      }
    },
 
    async delete_post_reaction({ commit }, uuid) {
      let config = {
        method: "delete",
        maxBodyLength: Infinity,
        url: "/api/community/postreaction/" + uuid,
        headers: {},
      };
      try {
        const res = axios(config);
        if (res.status == 200) {
        }
      } catch (error) {
        
      }
    },
    async get_comment_reaction({ commit }, uuid) {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: "/api/community/commentsreaction?comment_id=" + uuid,
        headers: {},
      };
      try {
        const res = axios(config);
        if (res.status == 200) {
        }
      } catch (error) {
        
      }
    },
    async post_comment_reaction({ commit }, data) {
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: "/api/community/commentsreaction",
        headers: {},
        data: data,
      };

      try {
        const res = axios(config);
        if ((await res).status == 200) {
        }
      } catch (error) {
        
      }
    },
    async update_comment_reaction({ commit }, data) {
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: "/api/community/commentsreaction",
        headers: {},
        data: data,
      };
      try {
        await axios(config);
      } catch (error) {}
    },
  },
};
export default lounge;
