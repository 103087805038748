var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "button",
    {
      directives: [
        {
          name: "b-tooltip",
          rawName: "v-b-tooltip.hover",
          value: !!_vm.title,
          expression: "!!title",
          modifiers: { hover: true },
        },
      ],
      ref: "smCloseButton",
      class: [
        "close-button",
        { "small-corner-padding": _vm.smallCornerPadding },
        { "no-corner-padding": _vm.noCornerPadding },
      ],
      attrs: { type: "button", title: _vm.title },
      on: { click: _vm.handleClick },
    },
    [
      _vm._t("default", function () {
        return [_c("i", { staticClass: "fas fa-times" })]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }