export default [
  {
    path: "cwebcard",
    name: "commonWebcard",
    redirect: { name: "commonWebcardBrowse" },
    meta: {
      title: "common.webcard",
      description: "",
    },
    // component: () => import('@views/app/common/FirstCard')
    component: {
      template: "<router-view></router-view>",
    },
    children: [
      // {
      //   path: "webcard-dashboard",
      //   name: "commonWebcardDashboard",
      //   meta: [],
      //   component: () => import("@views/app/common/webcard/dashboard/index"),
      // },
      {
        path: "email-user/:id",
        name: "commonEmailUser",
        meta: [],
        component: () =>
          import("@views/app/common/webcard/dashboard/email/index"),
      },
      {
        path: "webcard-browse",
        name: "commonWebcardBrowse",
        meta: [],
        component: () =>
          import("@views/app/common/webcard/browsewebcard/index"),
      },
      {
				path: 'health-webcard',
				name: 'commonHealthWebcard',
				meta: [],
				component: () => import('@views/app/common/webcard/health/index')
			},
			{
				path: 'webcard-orders',
				name: 'commonWebcardsOrders',
				meta: [],
				component: () => import('@views/app/common/webcard/order/index')
			},
			{
				path: 'webcard-order/:uuid',
				name: 'commonWebcardOrders',
				meta: [],
				component: () => import('@views/app/common/webcard/order/Order')
			},
      {
        path: "webcard-library",
        name: "commonWebcardLibrary",
        meta: [],
        component: () => import("@views/app/common/webcard/library/index"),
      },
      
      {
        path: "my-webcard",
        name: "commonWebcardMy",
        meta: [],
        component: () => import("@views/app/common/webcard/mywebcard/index"),
      },
      {
        path: "promotional-webcards",
        name: "commonPromotionalWebcards",
        meta: [],
        component: () => import("@views/app/common/webcard/promotional/index"),
      },
      {
        path: "my-memberships",
        name: "commonMyMemberships",
        meta: [],
        component: () => import("@views/app/common/webcard/memberships/index"),
      },
      {
        path: "webcard-holder",
        name: "commonWebcardHolder",
        meta: [],
        component: () =>
          import("@views/app/common/webcard/webcardholder/index"),
      },
      {
        path: "webcard-timeline",
        name: "commonWebcardTimeline",
        meta: [],
        component: () => import("@views/app/common/webcard/timeline/index"),
      },
      {
        path: "get-printed-webcard",
        name: "commongetPrintedWebcard",
        meta: [],
        component: () => import("@views/app/common/webcard/printed/index"),
      },
      {
        path: "get-plastic-webcard",
        name: "commongetPlasticWebcard",
        meta: [],
        component: () => import("@views/app/common/webcard/plastic/index"),
      },
      {
        path: "get-metalic-webcard",
        name: "commongetMetalicWebcard",
        meta: [],
        component: () => import("@views/app/common/webcard/metalic/index"),
      },
      {
        path: "webcard-initial-settings",
        name: "commongetWebcardInitialSettings",
        meta: [],
        component: () => import("@views/app/common/webcard/initial/index"),
      },
      {
        path: "webcard-settings",
        name: "commongetWebcardSettings",
        meta: [],
        component: () => import("@views/app/common/webcard/settings/index"),
      },
      {
        path: "purchase-webcard",
        name: "commongetWebcardPurchase",
        meta: [],
        component: () => import("@views/app/common/webcard/initial/PurchaseNow"),
      },
      {
        path: "kanban",
        name: "commonKanban",
        meta: [],
        component: () => import("@views/app/common/webcard/Kanban/index"),
      },
      {
        path: "my-webcard-details/:uuid",
        name: "commonWebcardDetails",

        component: () =>
          import("@views/app/common/webcard/webcarddetails/index"),
      },
      {
        path: "webcard-packages",
        name: "commongetWebcardPackages",
        meta: [],
        component: () => import("@views/app/common/webcard/packages/index"),
      },
      {
        path: "webcard-packages-add",
        name: "commongetWebcardPackagesAdd",
        meta: [],
        component: () => import("@views/app/common/webcard/packages/Add"),
      },
      {
        path: "webcard-packages-edit/:uuid",
        name: "commongetWebcardPackagesEdit",
        meta: [],
        component: () => import("@views/app/common/webcard/packages/Edit"),
      },
      {
				path: 'discount-partners',
				name: 'commonWebcardDiscountPartners',
				component: () => import('@views/app/common/webcard/dp/index')
			},
			{
				path: 'discount-partner',
				name: 'commonWebcardDiscountPartner',
				component: () => import('@views/app/common/webcard/dp/DiscountPertners')
			},
			{
				path: 'discount-webcard-partners',
				name: 'commonWebcardDiscountPartnersWebcard',
				component: () => import('@views/app/common/webcard/dp/WebcardPartners')
			},
			
			{
				path: 'discount-partners-create',
				name: 'commonWebcardDiscountPartnersCreate',
				component: () => import('@views/app/common/webcard/dp/create')
			},
			{
				path: 'discount-partner-details/:uuid',
				name: 'commonWebcardDiscountPartnersDetails',
				component: () => import('@views/app/common/webcard/dp/details')
			},
			{
				path: 'discount-partner-edit/:uuid',
				name: 'commonWebcardDiscountPartnersEdit',
				component: () => import('@views/app/common/webcard/dp/edit')
			},
    ],
  },
];
