var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "button",
    {
      staticClass: "navbar-toggler",
      attrs: {
        type: "button",
        "data-toggle": "collapse",
        "data-target": _vm.target,
        "aria-controls": _vm.target,
        "aria-expanded": _vm.toggled,
        "aria-label": "Toggle navigation",
      },
    },
    [_c("span", { staticClass: "navbar-toggler-icon" })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }