export default [
	{
		path: 'cframes',
		name: 'commonFrames',
		redirect: { name: 'commonFramesDashboard'},
		meta: {
			title: 'common.frames',
			description: '',
		},
		component: {
			template: '<router-view></router-view>'
		},
		children: [
			{
				path: 'frames',
				name: 'commonFramesDashboard',
				meta: [],
				component: () => import('@views/app/common/frames/frame/index')
			},
			{
				path: 'my-frames',
				name: 'commonMyFrame',
				meta: [],
				component: () => import('@views/app/common/frames/myframes/index')
			},
			{
				path: 'frames-holder',
				name: 'commonFramesHolder',
				meta: [],
				component: () => import('@views/app/common/frames/holder/index')
			},
			{
				path: 'frames-history',
				name: 'commonHistoryDashboard',
				meta: [],
				component: () => import('@views/app/common/frames/history/index')
			},
			{
				path: 'frames-earning',
				name: 'commonFramesEarnings',
				meta: [],
				component: () => import('@views/app/common/frames/earning/index')
			},
			{
				path: 'frames-videos',
				name: 'commonFramesVideos',
				meta: [],
				component: () => import('@views/app/common/frames/framesvideos/index')
			},
			{
				path: 'my-videos',
				name: 'commonMyVideos',
				meta: [],
				component: () => import('@views/app/common/frames/myvideos/index')
			},
			{
				path: 'video-holder',
				name: 'commonVideoHolder',
				meta: [],
				component: () => import('@views/app/common/frames/videoholder/index')
			},
			{
				path: 'video-history',
				name: 'commonVideoHistory',
				meta: [],
				component: () => import('@views/app/common/frames/videohistory/index')
			},
			{
				path: 'video-earnings',
				name: 'commonVideoEarnings',
				meta: [],
				component: () => import('@views/app/common/frames/videoearnings/index')
			},
			{
				path: 'podcasts',
				name: 'commonPodcasts',
				meta: [],
				component: () => import('@views/app/common/frames/podcasts/index')
			},
			{
				path: 'my-podcasts',
				name: 'commonMyPodcasts',
				meta: [],
				component: () => import('@views/app/common/frames/mypodcasts/index')
			},
			{
				path: 'podcast-holder',
				name: 'commonMyPodcastHolder',
				meta: [],
				component: () => import('@views/app/common/frames/podcastholder/index')
			},
			{
				path: 'podcasts-history',
				name: 'commonMyPodcastHistory',
				meta: [],
				component: () => import('@views/app/common/frames/podcasthistory/index')
			},
			{
				path: 'podcasts-earnings',
				name: 'commonMyPodcastEarnings',
				meta: [],
				component: () => import('@views/app/common/frames/podcastearnings/index')
			},
			{
				path: 'live',
				name: 'commonMyLive',
				meta: [],
				component: () => import('@views/app/common/frames/live/index')
			},


			
		]

	},
]