import axios from "axios";
import Swal from "sweetalert2";
import { Toast } from "../../mixins/swal";
import EventBus from "../../event-bus";
import WorkerMC from "worker-loader!../../web-worker/media/worker_mc"

const media = {
  namespaced: true,
  state: {
    media: [],
  },
  getters: {
    getmedia: (state) => state.media,
    getmediafiles: (state) => state.media.files,
    uniquemedia: (state) => {
      const uniqueSet = new Set();
      return state.media.files.filter((obj) => {
        const value = obj["uuid"];
        if (uniqueSet.has(value)) {
          return false;
        }
        uniqueSet.add(value);
        return true;
      });
    },
  },
  mutations: {
    SET_MEDIA: (state, data = {}) => {
      state.media = data;
    },
  },
  actions: {
    async send_via_email({ commit }, { data, uuid }) {
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: "/api/auth/user/emailmedia/" + uuid,

        data,
      };
      try {
        const res = await axios(config);
        if (res.status == 200) {
          EventBus.$emit("media-emailed");
          Toast.fire({
            icon: "success",
            title: "Media sharing successfull",
          });
        }
      } catch (error) {
        EventBus.$emit("media-emailed");
      }
    },
    async delete_media({ commit, dispatch }, uuid) {
      let config = {
        method: "delete",
        maxBodyLength: Infinity,
        url: "/api/auth/user/media/" + uuid,
      };
      try {
        const res = await axios(config);
        if (res.status == 200) {
          dispatch("get_media");
          EventBus.$emit("media-deleted");
        }
      } catch (error) {
        EventBus.$emit("media-deleted");
      }
    },
    async get_media({ commit }) {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: "/api/auth/user/allmedia",
      };
      try {
        const res = await axios(config);
        if (res.status == 200) {
          EventBus.$emit("got-media");
          commit("SET_MEDIA", res.data);
        }
      } catch (error) {
        EventBus.$emit("got-media");
      }
    },
    async create_folder({commit, dispatch}, data) {
      const worker_cm = new WorkerMC();
      await  worker_cm.postMessage(data);
          worker_cm.addEventListener('message', (event) => {
            const responseData = event.data;
            if (typeof(responseData) == 'number') {
              EventBus.$emit("processing", responseData)
            } else {
               const res = JSON.parse(responseData)
               if (res.status == 201) {
                dispatch("get_media")
               }
            }
          });  
      },
    
    // async create_folder({ commit, dispatch }, data) {
    //   let config = {
    //     method: "post",
    //     maxBodyLength: Infinity,
    //     url: "/api/auth/user/media",

    //     data,
    //   };

    //   try {
    //     const res = await axios(config);
    //     if (res.status == 201 || res.status == 201) {
    //       EventBus.$emit("created-media");
    //       Toast.fire({
    //         icon: "success",
    //         title: "Folder Created",
    //       });
    //       dispatch("get_media");
    //     }
    //   } catch (error) {
    //     EventBus.$emit("created-media");
    //   }
    // },
  },
};
export default media;
