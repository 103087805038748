export default [
	{
		path: 'cstories',
		name: 'commonStories',
		redirect: { name: 'commonPremiumBooks' },
		meta: {
			title: 'common.tools',
			description: '',
		},
		// component: () => import('@views/app/common/FirstCard')
		component: {
			template: '<router-view></router-view>'
		},
		children: [
			{
				path: 'premium-books',
				name: 'commonPremiumBooks',
				meta: [],
				component: () => import('@views/app/common/stories/premiumbooks/index')
			},
			{
				path: 'premium-stories',
				name: 'commonPremiumstories',
				meta: [],
				component: () => import('@views/app/common/stories/premiumstories/index')
			},
			{
				path: 'browse-books',
				name: 'commonBrowseBooks',
				meta: [],
				component: () => import('@views/app/common/stories/browsebooks/index')
			},
			{
				path: 'my-stories',
				name: 'commonMyStories',
				meta: [],
				component: () => import('@views/app/common/stories/mystories/index')
			},
			{
				path: 'my-library',
				name: 'commonMyLibrary',
				meta: [],
				component: () => import('@views/app/common/stories/mylibrary/index')
			},
			{
				path: 'top-authors',
				name: 'commonTopAuthors',
				meta: [],
				component: () => import('@views/app/common/stories/topauthors/index')
			},
			{
				path: 'best-sellers',
				name: 'commonBestSellers',
				meta: [],
				component: () => import('@views/app/common/stories/bestsellers/index')
			},
			{
				path: 'to-read',
				name: 'commonToRead',
				meta: [],
				component: () => import('@views/app/common/stories/toread/index')
			},
			{
				path: 'already-read',
				name: 'commonAlreadyRead',
				meta: [],
				component: () => import('@views/app/common/stories/alreadyread/index')
			},
			{
				path: 'book-fair',
				name: 'commonBookFair',
				meta: [],
				component: () => import('@views/app/common/stories/bookfair/index')
			},
			
		]
	},
]