
import axios from 'axios';
import Swal from 'sweetalert2'
import { Toast } from '../../mixins/swal'
import { getToken } from '@core/utils/auth'
const token = getToken()

const socialmedia = {
	namespaced: true,
	state: {
		microsite: {},

	},
	getters: {
		getprofile: state => state.profile,



	},
	mutations: {

		SET_PUBLIC_PROFILE: (state, data = {}) => {

		},

	},
	actions: {
		updateProfile({ commit }, data) {
			var config = {
				method: 'post',
				url: 'https://icircles.app/api/profile',
				headers: {
					'Content-Type': 'multipart/form-data',
					'Authorization': 'Bearer 131|SHw98RHCciFVF2oFuDXdtnnuv7IOO94ZlQXc8LcP',
				},
				data: data
			};

			axios(config)
				.then(function (response) {

					Toast.fire({
						icon: 'success',
						title: 'Microsite created  successfully'
					})
				})
				.catch(function (error) {
					Toast.fire({
						icon: 'success',
						title: error
					})
					// console.log(error);
				});
		},

	}
}
export default socialmedia

